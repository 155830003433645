import { color } from "theme";

export type ButtomScheme = 'primary' | 'secondary' | 'error' | 'warning' | 'success';

export type ButtonVariant = 'solid' | 'outline' | 'ghost' | 'link';

function appearance(
  variant: ButtonVariant = "solid",
  scheme: ButtomScheme = "primary",
  loading?: boolean
) {
  if (variant === "solid") {
    return `
      background-color: ${schemes[scheme].background};
      color: ${schemes[scheme].color};
      ${!loading ?
        `
        &:disabled, form:invalid & {
          background-color: transparent;
          border: 1px solid ${schemes[scheme].background};
          color: ${schemes[scheme].background};
          opacity: .6;
          cursor: not-allowed;
        }
      `: ''}
    `;
  }
  if (variant === "outline") {
    return `
      background-color: transparent;
      color: ${schemes[scheme].background};
      border: 1px solid currentcolor;
      &:not(:disabled):hover {
        background-color: ${schemes[scheme].background + "20"};
      }
      &:disabled {
        color: #e9e9e9;
        border: 1px solid #e9e9e9;
      }
    `;
  }
  if (variant === "ghost") {
    return `
      color: ${schemes[scheme].background};
      background-color: ${schemes[scheme].background + "20"};
    `;
  }
  if (variant === "link") {
    return `
      border: none;
      background-color: transparent;
      color: ${schemes[scheme].background};
      &:not(:disabled):hover {
        text-decoration:underline;
      }
      ${!loading && `
        &:disabled {
          color: #e9e9e9;
        }
      `}
    `;
  }
}

const schemes = {
  primary: {
    background: color.primary,
    color: color.text_primary_contrast,
  },
  secondary: {
    background: color.secondary,
    color: color.text_primary_contrast,
  },
  error: {
    background: color.error,
    color: color.text_primary_contrast,
  },
  warning: {
    background: color.warning,
    color: color.text_primary_contrast,
  },
  success: {
    background: color.success,
    color: color.text_primary_contrast,
  },
};

export default appearance;
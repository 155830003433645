import createReducer from "./createReducer";
import { Account } from "types/account";
import { Order } from "types/order";
import { User } from "types/user";

export type AccountState = {
  authenticated: boolean;
  user?: User;
  account_index: number;
  account?: Account;
  accounts: Array<Account>;
  account_number: number;
  orders?: Array<Order>;
  stats?: any;
}

type AccountActionsType = {
  setAuthenticated: (state: AccountState['authenticated']) => any,
  setAccount: (state: AccountState['account']) => any,
  setAccounts: (state: AccountState['accounts']) => any,
  setAccountIndex: (index: number) => any,
  setAccountNumber: (number: number) => any,
  setUser: (state: AccountState['user']) => any,
  setOrders: (state: AccountState['orders']) => any,
  setStats: (state: AccountState['stats']) => any,
  reset: () => any
}

const initialState: AccountState = {
  authenticated: false,
  account_index: 0,
  account: undefined,
  accounts: [],
  account_number: 0,
  user: undefined,
  orders: undefined,
  stats: undefined
}

const [accountReducer, actions] = createReducer('ACCOUNT', initialState);

export const accountActions: AccountActionsType = actions;

export default accountReducer;
import { AxiosError } from "axios";

export default function showError(alert: (props: any) => void, error: any) {
  const { response } = error as AxiosError<{ code?: string, message?: string }>;

  if (!response) {
    return alert({
      title: 'Erro',
      message: 'Tivemos um problema ao processar sua solicitação'
    })
  }

  let message = 'Erro';
  let title = 'Erro';

  if (response.status === 500) {
    return alert({
      title: 'Internal Server Error',
      message: 'Erro no servidor'
    })
  }

  if (response.status === 429) {
    return alert({
      title: 'Muitas tentivas seguidas',
      message: 'Tente novamente em alguns instantes'
    })
  }

  if (response.data.code && response.data.code !== 'BILL_PAYMENT_AUTHORIZE_ERROR') {
    title = response.data?.code;
  }

  if (response.data.message) {
    message = response.data?.message;
  }
  
  return alert({ title, message });
}
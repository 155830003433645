import BaseModule from "./BaseModule";

class Customer extends BaseModule {
  sendVerificationCode(via: string, type: string) {
    return this.post("/ib/customer/send_verification_code", {
      via,
      type,
    });
  }

  listFinancialInstitutions(product: string): Promise<[]> {
    return this.get("/ib/financial_institutions", { params: { product } });
  }
}

export default Customer;

import React from "react";
import {
  Button,
  Grid, Card
} from "components";

import { logoMkPay } from "assets";
import QRCode from "react-qr-code";
import Spinner from "components/Spinner";
import { screenApp } from "assets";
import styled from "styled-components";
const QRCodeAuth: React.FC<any> = ({
  onClick,
  value,
  loading
}) => {

  return (
    <Grid gap={25}
      templateColumns="33% 33% 33%"
      maxWidth={'98%'}
    >
      <Card height={'auto'} width={'auto'}>
        <img src={screenApp} />
      </Card>
      <Card height={'auto'} width={'auto'}>
          <List>
            <Number>01</Number>Abra o app MK Pay.
          </List>
          <List>
          <Number>02</Number>Clique no botão "Ler QR Code".
          </List>
          <List>
          <Number>03</Number>Faça a leitura do "QR Code" para finalizar.
          </List>
          <List>
          <Number>04</Number>Mais de um dispositivo pode ser cadastrado com o mesmo QR Code.
          </List>
      </Card>
      <Card height={'auto'} width={'auto'}>
        <Container>
          {loading ? <Spinner color={'currentcolor'} /> :
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "200px", width: "200px", marginTop: 10 }}
              value={value}
              viewBox={`0 0 256 256`}
            />
          }
          <Button
            variant="outline"
            margin={'25px 0px 0px 0px'}
            label="Fechar QRCode"
            size="lg"
            onClick={onClick}
            width={200}
          />
        </Container>

      </Card>
    </Grid>

  )
  
}
const List = styled.li<{ $primary?: boolean; }>`
background: ${props => props.$primary ? "#BF4F74" : "white"};
color:  "#6A6D71";
font-size: 16px;
height: 77px;
margin: 10px;
display:flex;
align-items: center;
padding: 0.25em 1em;
border: 1px solid rgba(0, 0, 0, 0.05);
border-radius: 3px;
`;

const Number = styled.div<{ $primary?: boolean; }>`
height: 50px;
color: #263F63;
font-size: 26px;
font-style: normal;
font-weight: 600;
padding-right: 15px;
display:flex;
align-items: center;
border-right: 1px solid rgba(0, 0, 0, 0.05);
margin:10px;
margin-right: 25px;
`;
const Container = styled.div<{ $primary?: boolean; }>`

margin:auto;
border-radius: 5px;
border: 1px solid #E5EAF0;
padding:30px;

`;


export default QRCodeAuth;
import React, { useState } from "react";
import styled from "styled-components";
import Text from "./Text";
import Flex, { Row } from "./layout/Flex";

interface CheckboxProps {
  name?: string;
  label?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const [isChecked, setIsChecked] = useState(props.checked || false);

  const handleChange = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    props.onChange?.(newChecked)
  };

  return (
    <Flex align="flex-start">
      <Row cursor="pointer" onClick={handleChange}>
        <CheckboxInput
          name={props.name}
          type="checkbox"
          checked={isChecked}
        />
        <Text>
          {props.label}
        </Text>
      </Row>
    </Flex>
  );
};

const CheckboxInput = styled.input`
  appearance: none;
  margin-right: 5px;
  width: 28px;
  height: 28px;
  border: 1px solid #999;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  &:checked {
    background-color: #ffffff;
  }
  &:checked::before {
    content: "✔️";
    margin-left: 4px;
    font-size:20px;
    position: relative;
    top: 0px;
  };
  &:not(:checked)::after {
    content: "";
    margin-left: 4px;
  }
`;

export default Checkbox;

import Code, { CodeProps } from "components/overlay/Code";
import React, { ReactNode, useState } from "react";
 
type StateProps = CodeProps & {keepState?: boolean }

type Props = {
  state?: StateProps| undefined;
  setState: (props?: StateProps) => void;
  
};

const CodeContext = React.createContext<Props>({
  state: undefined,
  setState: () => { }, 
});

export const CodeProvider = (props: { children: ReactNode }) => {
  const [codeState, setCodeState] = useState<StateProps | undefined>();

  return (
    <CodeContext.Provider
      value={{
        state: codeState,
        setState: (props?: StateProps) => {
          if (!props) {
            return setCodeState(undefined)
          }
          setCodeState({
            ...props,
            visible: true,
            onRequesClose: () => setCodeState(undefined),
            onConfirm: async (code: string) => {
              props.onConfirm?.(code)
              if (!props.keepState) {
                setCodeState(undefined);
              }
            },
          });
        }
      }}
    >
      {codeState?.visible &&
        <Code {...codeState} visible />
      }
      {props.children}
    </CodeContext.Provider>
  );
};

export default CodeContext;

import styled from "styled-components";
import { attributeCSS } from "helpers/text";
import { color, size } from "theme";
import * as rules from "../../helpers/rules";

interface BaseCss {
  /**
     * position = absolute;
     */
  absolute?: boolean;
  /**
   * background-color
   */
  bgColor?: string;
  /**
   * border
   * 
   * if true = 1px solid {color.border}
   */
  border?: boolean | string;
  borderBottom?: boolean | string;
  borderColor?: string;
  borderLeft?: boolean | string;
  borderRight?: boolean | string;
  borderTop?: boolean | string;
  /**
   * border-radius
   * 
   * if true = size.border_radius
   */
  borderRadius?: string | number | boolean;
  bottom?: string | number;
  color?: string;
  css?: string;
  cursor?: 'pointer' | 'progress' | 'wait' | 'not-allowed' | 'none';
  display?: string;
  height?: string | number;
  left?: string | number;
  margin?: string | number;
  marginBottom?: string | number;
  marginLeft?: string | number;
  marginTop?: string | number;
  marginRight?: string | number;
  /**
   * if true = 100vh
   */
  maxHeight?: string | number | boolean;
  /**
   * if true = 100vw
   */
  maxWidth?: string | number | boolean;
  /**
   * if true = 100vh
   */
  minHeight?: string | number | boolean;
  /**
  * if true = 100vw
  */
  minWidth?: string | number;
  overflow?: string;
  overflowX?: string;
  overflowY?: string;
  padding?: string | number;
  paddingBlock?: string | number;
  paddingBottom?: string | number;
  paddingInline?: string | number;
  paddingRight?: string | number;
  paddingLeft?: string | number;
  paddingTop?: string | number;
  position?: string;
  right?: string | number;
  scrollY?: boolean;
  /**
   * box-shadow
   * 
   * if true = 0px 5px 15px {color.border}
   */
  shadow?: string | boolean;
  /**
   * height and width
   */
  size?: string | number;
  top?: string | number;
  width?: string | number;
}

interface BaseRules {
  desktop?: BaseProps;
  laptop?: BaseProps;
  mobile?: BaseProps;
}

interface PseudoClass {
  hover?: BaseCss
}

export type BaseProps = BaseCss & BaseRules & PseudoClass;

const border = `1px solid ${color.border}`;

const baseCss = (props: BaseProps) => `
  ${attributeCSS('background-color', props.bgColor)}
  ${attributeCSS('border', props.border, border)}
  ${attributeCSS('border-bottom', props.borderBottom, border)}
  ${attributeCSS('border-color', props.borderColor)}
  ${attributeCSS('border-left', props.borderLeft, border)}
  ${attributeCSS('border-right', props.borderRight, border)}
  ${attributeCSS('border-top', props.borderTop, border)}
  ${attributeCSS('border-radius', props.borderRadius, size.border_radius)}
  ${attributeCSS('bottom', props.bottom)}
  ${attributeCSS('box-shadow', props.shadow, `0px 5px 15px ${color.border}`)}
  ${attributeCSS('color', props.color)}
  ${attributeCSS('cursor', props.cursor)}
  ${attributeCSS('display', props.display)}
  ${attributeCSS('height', props.height ?? props.size)}
  ${attributeCSS('left', props.left)}
  ${attributeCSS('margin', props.margin)}
  ${attributeCSS('margin-bottom', props.marginBottom)}
  ${attributeCSS('margin-left', props.marginLeft)}
  ${attributeCSS('margin-top', props.marginTop)}
  ${attributeCSS('margin-right', props.marginRight)}
  ${attributeCSS('max-height', props.maxHeight, '100vh')}
  ${attributeCSS('max-width', props.maxWidth, '100vw')}
  ${attributeCSS('min-height', props.minHeight, '100vh')}
  ${attributeCSS('min-width', props.minWidth, '100vw')}
  ${attributeCSS('overflow', props.overflow)}
  ${attributeCSS('overflow-x', props.overflowX)}
  ${attributeCSS('overflow-y', props.overflowY)}
  ${attributeCSS('padding', props.padding)}
  ${attributeCSS('padding-block', props.paddingBlock)}
  ${attributeCSS('padding-bottom', props.paddingBottom)}
  ${attributeCSS('padding-inline', props.paddingInline)}
  ${attributeCSS('padding-left', props.paddingLeft)}
  ${attributeCSS('padding-right', props.paddingRight)}
  ${attributeCSS('padding-top', props.paddingTop)}
  ${attributeCSS('position', props.absolute ? 'absolute' : props.position)}
  ${attributeCSS('right', props.right)}
  ${attributeCSS('overflow-y', props.scrollY, 'scroll')}
  ${attributeCSS('top', props.top)}
  ${attributeCSS('width', props.width ?? props.size)}
  ${attributeCSS('width', props.width ?? props.size)}
`
const pseudoClass = (props: PseudoClass) => `
  ${!!props.hover ? `
      &:hover {
        ${baseCss(props.hover)}
      }
    ` : ''}
`

export const attributes = (props: BaseProps): string => `
  ${baseCss(props)}
  ${pseudoClass(props)}
  ${props.desktop ? rules.desktop(attributes(props.desktop)) : ''}
  ${props.laptop ? rules.laptop(attributes(props.laptop)) : ''}
  ${props.mobile ? rules.mobile(attributes(props.mobile)) : ''}
  ${props.css}
`

const base = styled.div<BaseProps>`
  ${(props) => `
    ${attributes(props)}
  `}
`

export default base
import { size } from "../theme";

export const mobile = (inner: string) => `
  @media only screen and (max-width: ${size.mobile}) {
    ${inner};
  }
`;

export const tablet= (inner: string) => `
  @media only screen and (max-width: ${size.tablet}) {
    ${inner};
  }
`;

export const desktop= (inner: string) => `
  @media only screen and (max-width: ${size.desktop}) {
    ${inner};
  }
`;

export const laptop= (inner: string) => `
  @media only screen and (max-width: ${size.laptop}) {
    ${inner};
  }
`;
import { color, size } from "theme";
import { Row } from "./layout/Flex";
import Text from "./Text";

interface BadgeTypes {
  item: any;
  callback?: () => void;
}
const StatusBadge = ({ item, callback }: BadgeTypes) => {
  const badge = item.status == "DONE" ?
    { color: color.success, text: "Concluído" }
    : item.status == "PENDING" ?
      { color: color.warning, text: "Processando" }
      : { color: color.error, text: "Cancelado" };

  return (
    <Row
      onClick={callback}
      bgColor={badge.color}
      borderRadius={size.rounded}
      padding={'4px 12px'}
    >
      <Text size={'caption'} color="white">
        {badge.text}
      </Text>
    </Row>
  );
};

export default StatusBadge;

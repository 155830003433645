import { color } from "theme";
import {
  Cell,
  Pie,
  PieChart as RePieChart,
  ResponsiveContainer
} from "recharts";

interface ChartProps {
  colors: string[];
  label: string;
  value: string;
  data: Array<{
    name: string;
    value: number;
  }>;
  loading?: boolean;
}

const PieChart: React.FC<ChartProps> = (props) => {
  return (
    <ResponsiveContainer>
      <RePieChart>
        <Pie
          data={props.data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={"70%"}
          outerRadius={"100%"}
          fill="#8884d8"
        >
          {props.data.map((item, i) =>
            <Cell
              key={item.name}
              style={{ outline: "none" }}
              fill={props.colors[i]}
              strokeLinecap="round"
            />
          )}
        </Pie>
        <text
          x="50%"
          y="40%"
          dy={12}
          textAnchor="middle"
          fontSize={10}
        >
          {props.label}
        </text>
        <text
          x="50%"
          y="50%"
          dy={+12}
          fontSize={20}
          style={{ fontSize: 20, fontWeight: "bold", fill: color.info }}
          textAnchor="middle"
        >
          {props.loading ? '--' : props.value}
        </text>
      </RePieChart>
    </ResponsiveContainer>
  )
}

export default PieChart;
import { useState } from "react";
import Button from "components/Button";
import Input from "components/Input";
import Select from "components/Select";
import { Row } from "components/layout/Flex";

interface HeaderFilterProps {
  loading?: boolean;
  onSearch?: (end_to_end_id: string) => void;
  onInitialDateChange?: (date: string) => void;
  onFinalDateChange?: (date: string) => void;
  onSelectPeriod?: (value: number) => void;
  hideSearch?: boolean
}

const HeaderFilter: React.FC<HeaderFilterProps> = (props) => {
  const [selection, setSelection] = useState(0);
  const [search, setSearch] = useState<string>();

  return (
    <Row justify="flex-start" gap wrap>
      {props.hideSearch ? null : <Row flex align="flex-end" gap={5} maxWidth="max(30vw, 500px)">
        <Input
          onKeyUp={({ target, key }: any) => {
            if (key === 'Enter') {
              props.onSearch?.(target.value)
            }
          }}
          label="Identificador da transação"
          placeholder="Ex: E35624319..."
          onChange={({ target }) => setSearch(target.value)}
        />
        <Button
          // disabled={!searchTerm}
          label="Pesquisar"
          loading={props.loading}
          variant="outline"
          width="8rem"
          onClick={() => props.onSearch?.(search || '')}
        />
      </Row>}

      <Input
        label="Data inicial"
        containerStyle={{ width: '12rem' }}
        onChange={({ target }) =>
          props.onInitialDateChange?.(target.value)
        }
        type="date"
      />
      <Input
        label="Data final"
        containerStyle={{ width: '12rem' }}
        onChange={({ target }) =>
          props.onFinalDateChange?.(target.value)
        }
        type="date"
      />
      <Select
        title="Período"
        selected={selection}
        options={[
          { label: 'Últimos 7 dias', value: 7 },
          { label: 'Últimos 15 dias', value: 15 },
          { label: 'Últimos 30 dias', value: 30 },
          { label: 'Últimos 90 dias', value: 90 }
        ]}
        onSelect={(value, index) => {
          setSelection(index);
          props.onSelectPeriod?.(value);
        }}
      />
    </Row>
  )
}

export default HeaderFilter;
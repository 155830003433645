import React from 'react';
import styled from 'styled-components';

interface ImageProps {
  width: string;
  height: string;
  url: string;
  alt?: string;
  title?: string;
}

const StyledContainer = styled.div`
  background: transparent;
`;

const StyledImage = styled.img<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const ImageContainer: React.FC<ImageProps> = ({ width, height, url, alt, title }) => {
  return (
    <StyledContainer>
      <StyledImage width={width} height={height} src={url} alt={alt} title={title} />
    </StyledContainer>
  );
};

export default ImageContainer;

export function decimal(value: number | string) {
  const v =parseFloat(String(value || 0).replace(/\D+/g, ""))
  let n = isNaN(v) ? 0: v / 100;
  return Number(n.toFixed(2) || 0);
}

export function currency(value: number, options?: { digits?: number; prefix?: string }) {
  const formated = Intl.NumberFormat("pt-BR", {
    maximumFractionDigits: options?.digits ?? 2,
    minimumFractionDigits: options?.digits ?? 2,
  }).format(value ?? 0);

  return options?.prefix ? options.prefix + formated : formated;
}

export function onlyNumbers(str: string) {
  return (str || "").replace(/\D/g, "");
}

export const validateNumber = (value: string) => {
  return value.replace(/\D/g, "");
};

export const convertRealToFloat = (realValue: any) => {
  const value = String(realValue);
  const numericString = value.replace(/[^\d,]/g, "");
  const floatValue = numericString.replace(",", ".");
  const result = parseFloat(floatValue);
  return result;
};

import { IconType } from "react-icons";

export const PixSvg: IconType = (props) => (
  <svg height={props.size} width={props.size} fill="none" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.895 13.092a2.633 2.633 0 0 1-.996-.192 2.588 2.588 0 0 1-.843-.552L9.403 9.75a.51.51 0 0 0-.698 0l-2.663 2.606a2.59 2.59 0 0 1-.843.552 2.633 2.633 0 0 1-.996.192H3.68l3.362 3.288c.25.244.545.438.872.57a2.74 2.74 0 0 0 2.057 0c.326-.132.623-.326.872-.57l3.37-3.297-.318.001ZM4.204 3.91c.342 0 .68.065.995.193.316.128.602.315.843.552L8.706 7.26a.5.5 0 0 0 .698 0l2.653-2.597c.241-.237.528-.424.843-.552.316-.128.654-.193.995-.192h.316L10.844.626a2.692 2.692 0 0 0-.872-.57 2.738 2.738 0 0 0-2.93.57L3.682 3.91h.522Z"
      fill={props.color}
    />
    <path
      d="m17.002 6.648-2.038-1.993a.395.395 0 0 1-.144.028h-.925a1.851 1.851 0 0 0-1.287.521L9.955 7.801a1.29 1.29 0 0 1-.9.364 1.29 1.29 0 0 1-.901-.364L5.49 5.195a1.851 1.851 0 0 0-1.286-.52H3.066a.393.393 0 0 1-.137-.028l-2.043 2c-.25.245-.448.535-.583.854a2.578 2.578 0 0 0 .583 2.865l2.046 2a.394.394 0 0 1 .136-.026h1.136a1.852 1.852 0 0 0 1.286-.521l2.664-2.606a1.32 1.32 0 0 1 .9-.353c.336 0 .658.127.901.353l2.653 2.596c.342.332.804.52 1.287.52h.926c.05.001.099.01.145.03l2.036-1.993c.25-.244.448-.534.583-.853A2.578 2.578 0 0 0 17 6.648Z"
      fill={props.color}
    />
  </svg>
)
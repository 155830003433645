import banking from "api";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

interface StatsProps {
  date: string;
  in: number;
  out: number;
}

interface ChargesProps {
  total: number;
  done: number;
  conversion: string;
  date: string;
  chart?: Array<{ name: string; value: number; color: string }>;
}

const fetchStats = async () => {
  let listStats = [];

  const statsz = await banking.account.stats();

  if (statsz) {
    if (statsz instanceof Array) {
      listStats = statsz;
    } else {
      listStats = [statsz];
    }

    let movements: StatsProps[] = [];
    let transactions: StatsProps[] = [];
    let charges: ChargesProps[] = [];
    for (const item of listStats) {
      const cashIn = item.movements.cash_in[Object.keys(item.movements.cash_in)[0]];
      const cashOut = item.movements.cash_out[Object.keys(item.movements.cash_out)[0]];
      let date = Object.keys(item.movements.cash_in)[0] || "";
      if (date) date = format(new Date(date), "dd-MMM", { locale: ptBR });

      if (cashIn && cashOut) {
        const mov: StatsProps = {
          date: date,
          in: cashIn,
          out: cashOut,
        };
        movements.push(mov);
      }
      //---
      const cashInTransactions =
        item.transactions.cash_in[Object.keys(item.transactions.cash_in)[0]];
      const cashOutTransactions =
        item.transactions.cash_out[Object.keys(item.transactions.cash_out)[0]];
      let dateTransactions = Object.keys(item.transactions.cash_in)[0] || "";
      if (dateTransactions)
        dateTransactions = format(new Date(dateTransactions), "dd-MMM", { locale: ptBR });

      if (cashInTransactions && cashOutTransactions) {
        const trans: StatsProps = {
          date: dateTransactions,
          in: cashInTransactions,
          out: cashOutTransactions,
        };
        transactions.push(trans);
      }
      //--
      const totalCharges = item.charges.total[Object.keys(item.charges.total)[0]];
      const doneCharges = item.charges.done[Object.keys(item.charges.done)[0]];
      const conversionCharges = item.charges.conversion[Object.keys(item.charges.conversion)[0]];
      let dateCharges = Object.keys(item.charges.conversion)[0] || "";
      if (dateCharges) dateCharges = format(new Date(dateCharges), "dd-MMM", { locale: ptBR });

      // const chartsCharges =

      if (totalCharges && doneCharges) {
        const chrg: ChargesProps = {
          total: totalCharges,
          done: doneCharges,
          conversion: conversionCharges,
          date: dateCharges,
          chart: [
            {
              name: "created",
              value: totalCharges,
              color: "#5375FA40",
            },
            {
              name: "converted",
              value: totalCharges - doneCharges,
              color: "#99999940",
            },
          ],
        };
        charges.push(chrg);
      }
    }

    console.log("MOVEMENTS DATA =>", movements);

    console.log("TRANSACTIONS DATA =>", transactions);

    console.log("CHARGES DATA =>", charges);

    return [movements, transactions, charges];
    return [
      {
        movements: { cash_in: { "2023-07-04": 10 }, cash_out: { "2023-07-04": 5 } },
        transactions: { cash_in: { "2023-07-04": 0 }, cash_out: { "2023-07-04": 0 } },
        charges: {
          total: { "2023-07-04": 1 },
          done: { "2023-07-04": 1 },
          conversion: { "2023-07-04": "00.00" },
        },
      },
      {
        movements: { cash_in: { "2023-07-05": 120 }, cash_out: { "2023-07-05": 48 } },
        transactions: { cash_in: { "2023-07-05": 23 }, cash_out: { "2023-07-05": 12 } },
        charges: {
          total: { "2023-07-05": 399 },
          done: { "2023-07-05": 99 },
          conversion: { "2023-07-05": "30.00" },
        },
      },
      {
        movements: { cash_in: { "2023-07-06": 100 }, cash_out: { "2023-07-06": 40 } },
        transactions: { cash_in: { "2023-07-06": 1 }, cash_out: { "2023-07-06": 1 } },
        charges: {
          total: { "2023-07-06": 300 },
          done: { "2023-07-06": 198 },
          conversion: { "2023-07-06": "73.00" },
        },
      },
      {
        movements: { cash_in: { "2023-07-07": 60 }, cash_out: { "2023-07-07": 30 } },
        transactions: { cash_in: { "2023-07-07": 3 }, cash_out: { "2023-07-07": 1 } },
        charges: {
          total: { "2023-07-07": 200 },
          done: { "2023-07-07": 50 },
          conversion: { "2023-07-07": "65.00" },
        },
      },
    ]
  }
};

const StatsService = {
  fetchStats,
};
export default StatsService;

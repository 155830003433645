import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Flex,
  IconButton,
  Row,
  Spinner,
  Text
} from "components";
import { color } from "theme";
import { UserPixKey } from "types/pix";
import { useAlert, useCode } from "hooks";
import { useNavigate } from "react-router-dom";
import { translatedPixKeyType } from "helpers/text";
import { routesPath } from "routes";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import CopyToClipboard from "helpers/CopyToClipBoard";
import showError from "helpers/showError";
import banking from "api";

const PixKeysPage: React.FC = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const modalCode = useCode();
  const { user } = useSelector((state: StoreState) => state.account);

  const [listKeys, setListKeys] = useState<UserPixKey[]>();
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    setFetching(true);
    try {
      const response = await banking.pix.listKeys();
      setListKeys(response);
    } catch (error) { }
    setFetching(false);
  }

  function request2FA(id: number) {
    modalCode({
      onConfirm: (verification_code) => handleConfirm(id, verification_code),
      operation: 'DELETE_PIX_KEY',
      type: user?.preferred_2fa_channel || undefined
    })
  }

  async function handleConfirm(id: number, code: string) {
    setLoading(true);
    try {
      await banking.pix.deleteKey(id, code);
      alert({
        title: 'Sucesso',
        message: 'Chave excluída'
      })
      loadData();
    } catch (error) {
      showError(alert, error);
    }
    setLoading(false);
  }

  return (
   
    
      <Card
        actionLoading={loading} 
        title="Minhas chaves"
      >
        {fetching ?
          <Flex center>
            <Spinner color={color.primary} />
          </Flex>
          :
          listKeys && listKeys.length > 0 ?
            <Flex>
              {listKeys.map((item: any) => (
                <Row
                  key={item.id}
                  borderBottom
                  justify={"space-between"}
                >
                  <Flex padding={10}>
                    <Text size="caption">
                      {translatedPixKeyType(item.type)}:
                    </Text>
                    <Text>
                      {item.value}
                    </Text>
                  </Flex>

                  <Row>
                    <IconButton
                      icon="delete"
                      iconColor={color.error}
                      onClick={() =>
                        alert({
                          title: "Excluir chave",
                          message: `Tem certeza que deseja excluir a chave abaixo?`,
                          onConfirmClick: () => request2FA(item.id),
                          confirmText: "Excluir",
                          cancelable: true
                        })
                      }
                    />

                    <IconButton
                      icon="content-copy"
                      iconColor={color.primary}
                      onClick={() => CopyToClipboard(item.value)}
                    />
                  </Row>
                </Row>
              ))}
            </Flex>
            :
            <Text>
              Sem chaves no momento.
            </Text>
        }
        <Row >
          <Button
            label="Criar nova chave"
            onClick={() => navigate(routesPath.pix_add_key, { replace: true })}
          />
        </Row>
      </Card>
      
  );
};

export default PixKeysPage;

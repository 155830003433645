import { useState } from "react";
import {
  Card,
  Flex,
  Grid,

} from "components";
import { screenMk, screenApp } from "assets";
import { useAlert, useCode } from "hooks";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import banking from 'api'
import showError from "helpers/showError";
import Configure from "./Configure";
import Download from "./Download";
import QRCodeAuth from "./QRCodeAuth";

const MKPayPage = () => {
  const [view, setView] = useState<number>(1) // visualizações de tela são do tipo 1, 2, 3
  const [loading, setLoading] = useState<boolean>(false)
  const [qrcode, setQrcode] = useState<string>('')
  const modalCode = useCode();
  const alert = useAlert();
  const { user } = useSelector((state: StoreState) => state.account)

  //request 2FA to continue
  function request2FA() {
    modalCode({
      keepState: true,
      onConfirm: (code) => handleConfirm(code),
      operation: 'AUTH_MKPAY',
      type: user?.preferred_2fa_channel || undefined
    })
  }
  //create QRCode to login on MK Pay App
  async function handleConfirm(code: string) {
    setLoading(true)
    modalCode({ loading: true })
    try {
      let res = await banking.mkpay.createQrCode(code)
      setQrcode(res.encoded_credential)
      setLoading(false)
      setView(3)
      modalCode(undefined)
    }
    catch (err) {
      setLoading(false)
      showError(alert, err);
    }

  }
  return (

    <Flex flex>
      <Grid
        alignSelf="left"
        gap={5}
        minWidth={'85%'}>
        {view === 3 ? <QRCodeAuth loading={loading} onClick={() => setView(1)} value={qrcode} /> :
          <Card padding={100}>
            <Grid gap={2}
              templateColumns="50% 50%"
              maxWidth={'100%'}
            >
              {/* Tela inicial */}
              {view === 1 && <Configure onClick={() => setView(view + 1)} />}
              {/* Tela com informação para download */}
              {view === 2 && <Download onClick={() => request2FA()} />}
              {/* Tela com QRCode para autenticação */}

              <img id="hd-lg-sh" src={screenMk} alt="logo" width={250} style={{ marginLeft: '25%' }} />
            </Grid>
          </Card>}
      </Grid>
    </Flex>
  )
}

export default MKPayPage;
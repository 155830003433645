import {
  useEffect,
  useState
} from "react";
import {
  Button,
  Card,
  CardHeader,
  Input,
  Modal,
  Row
} from "components";
import {
  compareAsc,
  format,
  isValid,
  parse
} from "date-fns";

interface ModalFilterProps {
  loading?: boolean;
  onConfirm?: (inital_date: string, end_date: string) => void;
  onClose?: () => void;
  visible?: boolean;
}

const ModalFilter: React.FC<ModalFilterProps> = (props) => {
  const [initialDate, setInitialDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [errorInitialDate, setErrorInitialDate] = useState<string | undefined>();
  const [errorEndDate, setErrorEndDate] = useState<string | undefined>();

  useEffect(() => {
    if (initialDate) {
      validate(initialDate, 'initial');
    }
  }, [initialDate]);

  useEffect(() => {
    if (endDate) {
      validate(endDate, 'end');
    }
  }, [endDate]);

  function validate(value: string, type: 'initial' | 'end') {
    const today = new Date();
    const _date = parse(value, "yyyy-MM-dd", new Date());

    const onError = (type: 'initial' | 'end', message?: string) => {
      if (type === 'initial') {
        setErrorInitialDate(message);
      }
      if (type === 'end') {
        setErrorEndDate(message);
      }
    }

    if (!isValid(_date)) {
      return onError(type, 'Data inválida');
    }

    if (compareAsc(_date, today) > 0) {
      return onError(type, 'Data inválida');
    }

    if (
      type === 'initial' &&
      !!endDate &&
      compareAsc(_date, parse(endDate, "yyyy-MM-dd", new Date())) > 0
    ) {
      return onError(type, 'Data inicial inválida');
    }

    if (
      type === 'end' &&
      !!initialDate &&
      compareAsc(parse(initialDate, "yyyy-MM-dd", new Date()), _date) > 0
    ) {
      return onError(type, 'Data final inválida');
    }

    setErrorInitialDate(undefined);
    setErrorEndDate(undefined);
  }

  return (
    <Modal visible={props.visible}>
      <Card center gap={20}>
        <CardHeader
          title="Solicitar Relatório"
          onClose={() => {
            setInitialDate(undefined);
            setEndDate(undefined);
            props.onClose?.();
          }}
        />
        <Row gap={20} align="flex-start">
          <Input
            label="Data inicial"
            containerStyle={{ marginBottom: 0 }}
            error={!!errorInitialDate}
            helperText={errorInitialDate}
            onChange={({ target }) => setInitialDate(target.value)}
            placeholder={format(new Date(), "dd/MM/yyyy")}
            type="date"
          />
          <Input
            label="Data final"
            containerStyle={{ marginBottom: 0 }}
            error={!!errorEndDate}
            helperText={errorEndDate}
            onChange={({ target }) => setEndDate(target.value)}
            placeholder={format(new Date(), "dd/MM/yyyy")}
            type="date"
          />
        </Row>

        <Button
          disabled={!initialDate || !endDate || !!errorInitialDate || !!errorEndDate}
          label="Confirmar"
          loading={props.loading}
          size="md"
          onClick={() => props.onConfirm?.(initialDate!, endDate!)}
          width={200}
        />
      </Card>
    </Modal>
  )
}

export default ModalFilter;
import { AxiosInstance, AxiosRequestConfig } from "axios";

class BaseModule {
  axios: AxiosInstance;

  constructor({ axios }: any) {
    this.axios = axios;
  }

  get(endpoint: string, config?: AxiosRequestConfig) {
    return this.request('get', endpoint, config);
  }

  post(endpoint: string, data?: {}, config?: AxiosRequestConfig) {
    return this.request('post', endpoint, data, config);
  }

  put(endpoint: string, data?: {}, config?: AxiosRequestConfig) {
    return this.request('put', endpoint, data, config);
  }

  delete(endpoint: string, config?: AxiosRequestConfig) {
    return this.request('delete', endpoint, config);
  }

  async request(method: 'get' | 'post' | 'put' | 'delete', endpoint: string, data?: {}, config?: AxiosRequestConfig) {
    let response = await this.axios[method](endpoint, data, config);
    return response.data;
  }
}

export default BaseModule;

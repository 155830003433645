export function parseAttribute(attr: string, value: any, default_value?: string | { default_value: string }) {
  if (typeof value === 'boolean' && typeof default_value === 'string') {
    return `${attr}: ${default_value};`
  }
  if (typeof value === 'boolean' && typeof default_value === 'object') {
    return `${attr}: ${default_value.default_value};`
  }
  if (typeof value === 'number') {
    return `${attr}: ${value}px;`
  }
  return `${attr}: ${value};`;
}

export function attributeCSS(
  attr: string | Array<string>,
  value?: string | number | boolean | Array<string>,
  defaultValue?: string
) {
  if (typeof value === 'undefined' || typeof value === 'boolean' && value === false) {
    return "";
  }
  if (typeof attr === "object") {
    let str = "";
    attr.forEach((item, i) => {
      str += parseAttribute(item, value, defaultValue);
    });
    return str;
  }
  return parseAttribute(attr, value, defaultValue)
}

export function formatCnpjCpf(value: string) {
  let x = value.replace(/\D/g, "").match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/) ?? [];
  value = !x[2]
    ? x[1]
    : x[1] +
    "." +
    x[2] +
    (x[3] ? "." : "") +
    x[3] +
    (x[4] ? "/" : x[4]) +
    x[4] +
    (x[5] ? "-" + x[5] : "");
  if (value.length < 15) {
    x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/) ?? [];
    value = !x[2] ? x[1] : x[1] + "." + x[2] + (x[3] ? "." : "") + x[3] + (x[4] ? "-" + x[4] : "");
  }
  return value;
}

export function capitalize(str: string) {
  return str
    .replaceAll("_", " ")
    .replaceAll(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export const translatedPixNameKey = (type: string) => {
  if (type == "PHONE") {
    return "Celular";
  }
  if (type == "EMAIL") {
    return "E-mail";
  }
  if (type == "EVP") {
    return "Aleatória";
  }

  return type;
};

export const translatedStatus = (status: string): string => {
  switch (status) {
    case "DONE":
      return "Efetivado";
    case "LOCKED":
      return "Aguardando aprovação";
    case "CREATED":
    case "PROCESSING":
      return "Processando";
    case "ERROR":
    case "UNDONE":
      return "Não efetivado";
    case "SCHEDULED":
      return "Agendado";
    case "CANCELED":
      return "Cancelado";
    default:
      return status;
  }
};

export const TypesWebhook = [
  { id: 1, label: "Pix recebidos", value: "PixPaymentReceivement" },
  { id: 2, label: "Pix enviados", value: "PixPayment" },
  { id: 3, label: "Envio de transferência interna", value: "InternalTransfer" },
  { id: 4, label: "Recebimento de transferência interna", value: "InternalTransferReceivement" },
  { id: 5, label: "Tarifas", value: "Fee" },
  { id: 6, label: "Pix devolvidos", value: "PixRefund" },
  { id: 7, label: "Recebimento de devoluções", value: "PixRefundReceivement" },
];

export const splitTypes = [
  { id: 1, label: "Porcentagem", value: "PERCENTAGE" },
  { id: 2, label: "Fixo", value: "FIXED" },
];

export const downloadFileBlob = (response: any, filename = "nome_do_arquivo", fileExt: "csv") => {
  const url = window.URL.createObjectURL(response);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}.${fileExt}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function filter<T extends { [key: string]: any }>(
  term: string,
  list: T[],
  key: keyof T = 'name'
): T[] {
  if (term.length === 0) {
    return list;
  }
  return list.filter((item) => {
    if (term.indexOf(' ') > 0) {
      const terms = term.split(' ');
      let founded = true;
      terms.forEach((_term) =>
        (founded = founded && item[key].toLowerCase().includes(_term.toLowerCase()))
      );
      return founded;
    }
    return item[key].toLowerCase().includes(term.toLowerCase());
  });
}

export function translateOwnerType(owner_type: string) {
  if (owner_type.endsWith('Fee')) {
    return 'Tarifa';
  }
  if (owner_type.endsWith('InternalTransfer')) {
    return 'Transferência interna';
  }
  if (owner_type.endsWith('InternalTransferReceivement')) {
    return 'Transferência interna recebida';
  }
  if (owner_type.endsWith('PixPayment')) {
    return 'Pagamento Pix';
  }
  if (owner_type.endsWith('PixPaymentReceivement')) {
    return 'Recebimento Pix';
  }
  if (owner_type.endsWith('PixRefund')) {
    return 'Devolução Pix enviada';
  }
  if (owner_type.endsWith('PixRefundReceivement')) {
    return 'Devolução Pix recebida';
  }
  if (owner_type.endsWith('ManualCashIn')) {
    return 'Lançamento a crédito';
  }
  if (owner_type.endsWith('ManualCashOut')) {
    return 'Lançamento a débito';
  }
  return ''
}

export function translateFeeRegime(regime: string) {
  if (regime === 'PER_TRANSACTION') {
    return 'Por transação';
  }
  if (regime === 'MONTHLY') {
    return 'Mensal';
  }
  if (regime === 'PERCENTAGE') {
    return 'Porcentagem';
  }
  if (regime === 'FIXED') {
    return 'Fixa';
  }
  return ""
}

export function translatedPixKeyType(type: string) {
  if (type === "PHONE") {
    return "Celular";
  }
  if (type === "EMAIL") {
    return "E-mail";
  }
  if (type === "EVP") {
    return "Aleatória";
  }
  return type;
};

import {
  Flex,
  Row,
  Text
} from "components";
import Icon, { Icons } from "components/Icon";
import { color } from "theme";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { desktop, laptop } from "helpers/rules";

interface NavItemProps {
  active?: boolean;
  label: string;
  icon: Icons;
  onClick?: () => void;
  options?: Array<{
    label: string;
    path: string;
  }>;
  path?: string;
  show?: boolean;
  visible?: boolean;
}

const NavItem: React.FC<NavItemProps> = (props) => {
  const text_color = props.active ? color.text_primary_contrast : color.text_primary;
  const is_list = props.options && props.options.length > 0;

  const Item = ({ list }: { list?: boolean }) => (
    <Row
      flex
      gap={20}
      padding={12}
    >
      <Icon
        color={text_color}
        name={props.icon}
        size={22}
      />
      <Text color={text_color} width="100%" laptop={{ display: 'none' }}>
        {props.label}
      </Text>
      {(list && !props.visible) &&
        <Flex laptop={{ display: 'none !important' }}>
          <Icon
            color={text_color}
            name={props.show ? "chevron-up" : "chevron-down"}
          />
        </Flex>
      }
    </Row>
  )

  return (
    <Flex>
      <Container
        active={props.active}
        borderRadius
        bgColor={props.active ? color.primary : 'transparent'}
        cursor="pointer"
        laptop={{
          width: 48
        }}
      >
        {(is_list || !props.path) ?
          <span onClick={props.onClick} style={{ width: '100%' }}>
            <Item list={!!props.options} />
          </span>
          :
          <Link
            to={props.path || "/#"}
            style={{
              boxSizing: 'border-box',
              color: 'currentcolor',
              textDecoration: 'none'
            }}
          >
            <Item />
          </Link>
        }
      </Container>

      {(props.options && props.options?.length > 0) &&
        <List show={props.show} visible={props.visible}>
          {props.options.map((item, i) =>
            <li key={i}>
              <Link to={item.path}>
                {item.label}
              </Link>
            </li>
          )}
        </List>
      }
    </Flex>
  )
}

const Container = styled(Flex) <{ active?: boolean }>`
  ${({ active }) => !active && `
    :hover {
      background-color: ${color.primary + 20};
    }
  `}
`

const List = styled.ul<{ show?: boolean; visible?: boolean }>`
  ${props => `
    ${desktop(`display: ${props.show || props.visible ? "flex" : "none"}`)}
    ${laptop(`display: ${props.show ? "flex" : "none"}`)}
  `}
  flex-direction: column;
  padding-inline-start: 2rem;
  list-style-position: inside;
  margin: 0;
  width: calc(100% - 50px);
  & li {
    color: ${color.text_secondary};
    border-bottom: 1px solid ${color.text_primary + "20"};
    font-size: 14px;
    padding: 0.75rem;
  }
  & li a {
    color: currentcolor;
    text-decoration: none;
  }
  & li a:hover {
    border-bottom: 1px solid ${color.text_secondary};
  }
  ${laptop(`
    z-index: 100;
    position: absolute;
    background-color: ${color.surface};
    border: 1px solid ${color.text_primary + "20"};
    padding-right: 25px;
    width: auto;
    left: 70px;
    & li a:hover {
      border-bottom: 1px solid ${color.text_secondary};
    }
  `)}
`

export default NavItem;
import React from "react";
import styled from "styled-components";
import { parseAttribute } from "../../helpers/text";
import {
  BaseProps,
  attributes
} from "../layout/base";
import { size } from "../../theme";
import { Icon } from "components";
import { Icons } from "components/Icon";
import Spinner from "../Spinner";
import appearance, {
  ButtomScheme,
  ButtonVariant
} from "./appearance";

interface IconProps {
  name?: Icons;
  color?: string;
  size?: number;
  position?: "left" | "right";
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, BaseProps {
  label?: string | any;
  loading?: boolean;
  scheme?: ButtomScheme;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  variant?: ButtonVariant;
  icon?: IconProps;
}

function Button({
  loading,
  scheme,
  variant,
  icon,
  ...props
}: ButtonProps) {
  return (
    <Container
      borderRadius
      cursor="pointer"
      disabled={props.disabled || loading}
      height={size[props.size as keyof typeof size || 'lg']}
      overflow="hidden"
      paddingInline={12}
      size={props.size ?? "lg"}
      width="fit-content"
      appearance={`${appearance(variant, scheme, loading)}`}
      {...props}
    >
      {loading ?
        <Spinner color={'currentcolor'} />
        :
        <>
          {icon && icon?.position === "left" && (
            <Icon
              name={icon?.name}
              color={icon?.color}
              size={icon?.size}
            />
          )}
          {props.label}
          {icon && icon?.position === "right" && (
            <Icon
              name={icon?.name}
              color={icon?.color}
              size={icon?.size}
            />
          )}
        </>
      }
    </Container>
  );
}

const sizeMap: Record<string, number> = {
  xs: 10,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 18,
};

const Container = styled.button<ButtonProps & { appearance?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  gap: 4px;
  white-space: nowrap;
  position: relative;
  ${(props) => `
    ${parseAttribute('font-size', sizeMap[props.size || 'md'])}
    ${props.appearance}
    ${attributes(props)}
  `};
`;

export default Button;

import { Order } from "types/order";
import BaseModule from "./BaseModule";

class Invoices extends BaseModule {
  async list() {
    return this.get("/ib/invoices");
  }

  scheduled() {
    return this.get("/ib/invoices/scheduled");
  }

  details(order: string) {
    return this.get(`/ib/invoices/${order}/details`);
  }

  cancel(order: string) {
    return this.post(`/ib/invoices/${order}/cancel`);
  }

  receipt(order: string) {
    return this.get(`/ib/invoices/${order}/receipt`);
  }

  statement() {
    return this.get("/ib/invoices/statement/resume");
  }

  export() {
    return this.get("/ib/invoices/statement/csv");
  }
}

export default Invoices;
import React, { useState } from "react";
import {
  Button,
  Card,
  CurrencyInput,
  Flex,
  Grid,
  Input,
  Row,
  SchedulerPayment,
  Text
} from "components";
import {
  format,
  parseISO
} from "date-fns";
import {
  BillPaymentType,
  BillResponse
} from "types/bill";
import {
  useAlert,
  useCode
} from "hooks";
import { color } from "theme";
import { StoreState } from "store";
import { currency } from "helpers/number";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import banking from "api";
import showError from "helpers/showError";

const BillPage = () => {
  const alert = useAlert();
  const modalCode = useCode();
  const navigate = useNavigate();
  const { user } = useSelector((state: StoreState) => state.account);
  const [authorizing, setAuthorizing] = useState(false);
  const [performing, setPerforming] = useState(false);
  const [barCode, setBarcode] = useState<string>();
  const [billData, setBillData] = useState<BillResponse>();

  async function handleSearch() {
    setAuthorizing(true);
    try {
      const response = await banking.bill.authorize({
        barcode: '',
        digitable: barCode
      });
      setBillData(response);
    } catch (error) {
      showError(alert, error);
    }
    setAuthorizing(false);
  }

  function request2FA(data: Omit<BillPaymentType, 'verification_code'>) {
    modalCode({
      onConfirm: (verification_code) => handleConfirm({ ...data, verification_code }),
      operation: 'PIX_PAYMENT',
      type: user?.preferred_2fa_channel || undefined
    })
  }

  async function handleConfirm(bill: BillPaymentType) {
    setPerforming(true);
    try {
      const response = await banking.bill.confirmPayment(bill);
      navigate(`/comprovante/${response.id}`);
    } catch (error) {
      showError(alert, error)
    }
    setPerforming(false);
  }

  return (
    <Card
      title="Pagar conta"
      titleAlign="flex-start"
    >
      <Input
        autoFocus
        label="Pagamento com código de barras"
        placeholder="Cole ou informe a linha digitável aqui"
        onChange={({ target }) => setBarcode(target.value)}
        onKeyDown={({ key }) => {
          if (key === 'Enter' && barCode && barCode?.length > 43) {
            handleSearch();
          }
        }}
      />
      <Row gap>
        <Button
          label="Limpar"
          onClick={() => setBarcode(undefined)}
          size="md"
          variant="ghost"
          minWidth="8rem"
        />
        <Button
          disabled={!(barCode && barCode?.length > 43)}
          label="Buscar"
          loading={authorizing}
          onClick={() => handleSearch()}
          size="md"
          minWidth="8rem"
        />
      </Row>
      {billData &&
        <BankSlip
          data={billData}
          loading={performing}
          onCancel={() => setBillData(undefined)}
          onConfirm={(data) => request2FA(data)}
        />
      }
    </Card>
  );
};

const BillItem: React.FC<{ label: string, value: string }> = (props) => (
  <Flex>
    <Text color={color.text_secondary} size="caption">
      {props.label}
    </Text>
    <Text>{props.value}</Text>
  </Flex>
)

const BankSlip: React.FC<{
  data: BillResponse;
  loading: boolean;
  onCancel: () => void;
  onConfirm: (data: Omit<BillPaymentType, 'verification_code'>) => void;
}> = (props) => {
  const [amount, setAmount] = useState<number>(0);
  const [notes, setNotes] = useState<string>();
  const [selectedDate, setSelectedDate] = useState<string>();
  const [schedule, setSchedule] = useState(false);
  const [validDate, setValidDate] = useState(false);

  return (
    <Card
      modal
      headerText="Pagamento"
      onClose={props.onCancel}
      actionLoading={props.loading}
    >
      <Flex borderBottom borderTop gap={10} scrollY>
        <Flex gap paddingTop={10}>
          <BillItem
            label="Beneficiário"
            value={props.data.registerData.recipient}
          />
          <BillItem
            label={props.data.registerData.documentRecipient.length > 14 ? 'CNPJ' : 'CPF'}
            value={props.data.registerData.documentRecipient}
          />
          <BillItem
            label="Banco"
            value={props.data.assignor}
          />

          <Grid templateColumns="repeat(2, 1fr)">
            <BillItem
              label="Valor"
              value={`R$ ${currency(props.data.registerData.originalValue)}`}
            />
            <BillItem
              label="Desconto"
              value={`R$ ${currency(props.data.registerData.discountValue)}`}
            />
          </Grid>

          <Grid templateColumns="repeat(2, 1fr)">
            <BillItem
              label="Juros"
              value={`R$ ${currency(props.data.registerData.interestValueCalculated)}`}
            />
            <BillItem
              label="Multa"
              value={`R$ ${currency(props.data.registerData.totalWithDiscount)}`}
            />
          </Grid>

          <Grid templateColumns="repeat(2, 1fr)">
            <BillItem
              label="Total a pagar"
              value={`R$ ${currency(props.data.registerData.totalUpdated)}`}
            />
            <BillItem
              label="Vencimento"
              value={format(parseISO(props.data.dueDate), 'dd/MM/yyyy')}
            />
          </Grid>

          <BillItem
            label="Código de barras"
            value={props.data.digitable}
          />
        </Flex>

        <Flex gap borderTop paddingBlock={20}>
          <Input
            placeholder="Descrição (opcional)"
            onChange={({ target }) => setNotes(target.value)}
          />
          <SchedulerPayment
            onChange={(value) => setSchedule(value)}
            onDateChange={(date, is_future) => {
              setSelectedDate(date);
              setValidDate(is_future);
            }}
            value={selectedDate}
          />
          <CurrencyInput
            autoFocus={props.data.registerData.allowChangeValue}
            marginTop={10}
            disabled={!props.data.registerData.allowChangeValue}
            label="Valor a pagar"
            onChange={(value) => setAmount(value)}
            value={props.data.value}
          />
        </Flex>
      </Flex>

      <Row justify="space-between">
        <Button
          label="Cancelar"
          onClick={props.onCancel}
          size="md"
          variant="ghost"
          minWidth="8rem"
        />
        <Button
          disabled={(schedule && !validDate)}
          label="Pagar"
          loading={props.loading}
          onClick={() => props.onConfirm({
            amount: amount || props.data.registerData.totalUpdated,
            due_date: props.data.dueDate,
            scheduling_date: selectedDate || format(new Date(), "yyyy-MM-dd"),
            validation_code: props.data.validation_code,
            notes: notes
          })}
          size="md"
          minWidth="8rem"
        />
      </Row>
    </Card>
  )
}

export default BillPage;

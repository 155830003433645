import BaseModule from "./BaseModule";
import {
  SplitType
} from "types/split";

class Pix extends BaseModule {

  listSplits(): Promise<SplitType[]> {
    return this.get("/ib/split_rules");
  }

  createSplits(data: {
    recipient_account_branch: number;
    recipient_account_number: number;
    amount_type: 'PERCENTAGE' | 'FIXED';
    amount: number;
    max_amount_limit: number;
    expiration_date: Date;
    verification_code: string;
  }): Promise<SplitType[]> {
    return this.post(`/ib/split_rules`, data);
  }

  revokeSplits(id: number, verification_code: string) {
    return this.post(`/ib/split_rules/${id}`, {
      verification_code,
    });
  }
}

export default Pix;

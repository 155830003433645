import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled, { css, keyframes } from "styled-components";

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  align?: string;
  animated?: boolean;
  backdropClickDisabled?: boolean;
  backdropColor?: string;
  justify?: string;
  onBackdropClick?: () => void;
  onCloseAnimationEnd?: () => void;
  onShowAnimationEnd?: () => void;
  visible?: boolean;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { align = 'center', justify = 'center' } = props;
  const portal = document.getElementById("comp-manager-modal");

  const [close, setClose] = useState(false);
  const [isShowAnimationComplete, setIsShowAnimationComplete] = useState(false);
  const [visible, setVisible] = useState(props.visible);

  useEffect(() => {
    if (!props.animated) {
      setVisible(props.visible);
    }
    if (!props.visible && props.animated && isShowAnimationComplete) {
      setClose(true);
    }
  }, [props.visible]);

  function handleAnimationEnd() {
    if (close && isShowAnimationComplete) {
      props.onCloseAnimationEnd?.();
      return setVisible(false);
    }
    setIsShowAnimationComplete(true);
    props.onShowAnimationEnd?.();
  }

  return (portal) ? ReactDOM.createPortal(
    <Container
      align={align}
      justify={justify}
      visible={visible}
    >
      <ModalContent
        animated={props.animated}
        closing={close}
        onAnimationEnd={handleAnimationEnd}
      >
        {props.children}
      </ModalContent>

      <Backdrop
        closing={close}
        backgroundColor={props.backdropColor}
        onClick={() => !props.backdropClickDisabled && props.onBackdropClick?.()}
      />
    </Container>,
    portal
  ) : null;
};

const animation = keyframes`
  from {
    background-color: rgba(232, 232, 232, 0);
  }
  to {
    background-color: rgba(232, 232, 232, .74);
  }
`

const Container = styled.div<{
  visible?: boolean;
  justify: string;
  align: string;
}>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const Backdrop = styled.div<{ backgroundColor?: string; closing?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${(props) => props.backgroundColor ?
    `background-color: ${props.backgroundColor};`
    :
    css`
      animation: ${animation} .25s forwards ${props.closing && 'reverse'};
      backdrop-filter: blur(4px) opacity(1);
      -webkit-backdrop-filter: blur(4px) opacity(1);
    `
  }
`;

const fadein = keyframes`
  from { 
    transform: scaleY(.5) translateY(-30vh);
  }
  to { 
    transform: scaleY(1) translateY(0px);
  }
`;

const fadeout = keyframes`
  from { 
    transform: scaleY(1) translateY(0px);
  }
  to { 
    transform: scaleY(0) translateY(0px);
  }
`;

const ModalContent = styled.div<{ animated?: boolean, closing?: boolean }>`
  background-color: transparent;
  position: relative;
  z-index: 1;
  ${props => props.animated ?
    props.closing ?
      css`
        animation: ${fadeout} .25s forwards;
      `
      :
      css`
        animation: ${fadein} .25s forwards;
      `
    : ''
  }
`;

export default Modal;

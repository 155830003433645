import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Flex,
  Grid,
  Icon,
  Text
} from "components";
import {
  Outlet,
  useLocation,
  useNavigate
} from "react-router-dom";
import {
  RouteType,
  routes
} from "routes";
import {
  color,
  size
} from "theme";
import { Icons } from "components/Icon";

const PixPage = () => {
  const navigate = useNavigate();
  const [list, setList] = useState<string[]>();

  useEffect(() => {
    setList(routes.find(({ id }) => id === 'pix')?.children_id as string[])
  }, [])
 

  return (

    <Flex flex>
      <Grid
        alignSelf="left"
        gap={5}
        templateColumns="repeat(auto-fit, minmax(210px, calc(6vw + 40px)))"
        maxWidth={'calc(calc(15vw + 40px + 20px) * 5)'}
      >
        {list && list.map((item) => {
          const route = routes.find(({ id }) => item === id) as RouteType;

          return (
            <PixOption
              key={route?.id}
              icon={route?.icon || 'shield-key'}
              label={route?.label || ''}
              route={route?.path}
              active={route?.path === window.location.pathname}
              onClick={() => {
                navigate(route.path)
              }}
            />
          )
        })}

      </Grid>
      <Grid
        alignSelf="left"
        marginTop={30}
        width={"100%"}
      >
        <Outlet />
      </Grid>
    </Flex>
  )
}

const PixOption: React.FC<{
  icon: Icons;
  label: string;
  route: string;
  active: boolean;
  onClick?: () => void;
}> = (props) => {

  return (
    <Card
      center
      active={props.active}
      minHeight={40}
      height={'4vw'}
      align={'center'}
      minWidth={'calc(90% - 40px)'}
      width={'calc(90% - 40px)'}
      onClick={props.onClick}
    >
      <Icon
        name={props.icon}
        size={size.sm_number}
        color={color.primary}
      />
      <Text>{props.label}</Text>
    </Card>
  )
}

export default PixPage;
import styled from "styled-components"
import Icon, { Icons } from "./index"

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: Icons;
  iconColor?: string;
  size?: number;
}

const IconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <Button {...props}>
      <Icon
        name={props.icon}
        color={props.iconColor}
        size={props.size}
      />
    </Button>
  )
}

const Button = styled.button`
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 4px;
`

export default IconButton;
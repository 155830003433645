import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Flex, { Row } from "./layout/Flex";
import Text from "./Text";
import Input from "./Input";
import Spinner from "./Spinner";
import { color } from "theme";
import { filter } from "helpers/text";
import Icon from "./Icon";

type OptionType = {
  [key: string]: any;
}

interface SelectProps {
  label?: string;
  labelKey?: string;
  loading?: boolean;
  options?: OptionType[];
  /**
   * index of selected option
   */
  onSelect?: (value: any, index: number) => void;
  selected?: number;
  seach?: boolean;
  title?: string;
  valueKey?: string;
  width?: string | number;
}

const Select: React.FC<SelectProps> = (props) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const selectRef = React.useRef<HTMLDivElement>(null);
  const [label, setLabel] = useState<string>();
  const [visible, setVisible] = useState(false);
  const [options, setOptions] = useState(props.options);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    if (typeof props.selected === 'number' && props.options) {
      setLabel(props.options[props.selected][props.labelKey || 'label'])
    }
  }, [props.selected])

  useEffect(() => {
    document.addEventListener("mousedown", handleClose);
    document.addEventListener("keydown", handleClose);

    return () => {
      document.removeEventListener("mousedown", handleClose);
      document.removeEventListener("keydown", handleClose);
    };
  }, []);

  const handleClose = (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      return setVisible(false);
    }
    if (event.key === "Escape") {
      return setVisible(false);
    }
  };

  function handleSelect(selection: OptionType, index: number) {
    setLabel(selection[props.labelKey || 'label']);
    setVisible(false);
    props.onSelect?.(selection[props.valueKey || 'value'], index);
  }

  function handleSearch(term: string) {
    const filtered = filter(term, props.options || [], props.labelKey || 'label');
    setOptions(filtered);
  }

  return (
    <Flex
      ref={selectRef}
      position="relative"
    >
      <Flex ref={inputRef}>
        {props.title &&
          <Text color={color.text_secondary} padding={"2px 8px"} size={"caption"}>
            {props.title}
          </Text>
        }
        <Row
          bgColor={color.shadow}
          borderRadius
          cursor="pointer"
          justify="space-between"
          padding={"0px 8px"}
          minWidth={'10rem'}
          height={48}
          onClick={() => setVisible(!visible)}
        >
          <Text color={label ? color.text_primary : color.text_secondary}>
            {label || props.label}
          </Text>
          <Icon name={visible ? 'chevron-up' : 'chevron-down'} />
        </Row>
      </Flex>
      {visible &&
        <Options
          bgColor={color.shadow}
          border
          borderRadius
          top={(inputRef?.current?.clientHeight || 48) + 5}
          left={0}
          position="absolute"
          width={"calc(100% - 2px)"}
          maxHeight="44vh"
          overflow="scroll"
        >
          {props.loading ?
            <Row center padding={10} gap={5}>
              <Spinner color={color.primary} size="xxs" />
              <Text>Carregando...</Text>
            </Row>
            :
            (props.options && props.options?.length > 0) &&
            <>
              {props.seach &&
                <Flex position="sticky" top={0}>
                  <Input
                    autoFocus
                    addon={{
                      addonIcon: 'search',
                      addonPosition: 'left'
                    }}
                    containerStyle={{ height: 42 }}
                    containerContentStyle={{
                      borderRadius: 0,
                      padding: 0,
                      borderBottom: true,
                    }}
                    onChange={({ target }) => handleSearch(target.value)}
                    placeholder="Pesquisar"
                  />
                </Flex>
              }
              {options?.map((item, i) =>
                <Flex
                  onClick={() => handleSelect(item, i)}
                  padding={10}
                  cursor="pointer"
                >
                  <Text size="caption">
                    {item[props.labelKey || 'label']}
                  </Text>
                </Flex>
              )}
            </>
          }
        </Options>
      }
    </Flex>
  )
}

const Options = styled(Flex)`
  z-index: 100;
  > div:hover {
    background-color: ${color.border};
  }
`

export default Select;
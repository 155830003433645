import React, { useEffect, useState } from "react";
import { Flex, Row } from "components";
import { color } from "theme";
import Movements, { ChartProps } from "./Movements";
import CardData from "./CardData";
import banking from "api";

type ChargesType = {
  [key: string]: {
    total: number;
    done: number;
    pending: number;
    conversion: string;
  }
}

type TransactionsType = {
  [key: string]: {
    total: number;
    cash_in: number;
    cash_out: number;
  }
}

const HomePage: React.FC = () => {
  const [intervalId, setIntervalId] = useState<any>();
  const [shouldUpdate, setShouldUpdate] = useState(true);

  const [charges, setCharges] = useState<ChargesType>();
  const [chartData, setChartData] = useState<ChartProps[]>();
  const [transactions, setTransactions] = useState<TransactionsType>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (shouldUpdate) {
      loadData();
    }
    return () => {
      clearInterval(intervalId);
    }
  }, [shouldUpdate]);

  function createInterval() {
    setIntervalId((id: number) => {
      if (!!id) return id;
      return setInterval(() => {
        setShouldUpdate(() => true);
      }, 15000);
    });
  }

  async function loadData() {
    try {
      const response = await banking.account.stats();
      setShouldUpdate(false);

      if (!response.charges) {
        return createInterval();
      }

      if (intervalId) {
        clearInterval(intervalId);
      }

      if (typeof response.charges === 'object') {
        const _charges: ChargesType = {};

        Object.keys(response.charges).map((date: string) => {
          const item = response.charges[date];
          _charges[date] = {
            ...item,
            pending: item.total - item.done
          }
        });

        setCharges(_charges);
      }

      if (typeof response.movements === 'object') {
        const _chart: ChartProps[] = [];
        const _movements: TransactionsType = {};

        Object.keys(response.movements).map((date: string) => {
          const item = response.movements[date];
          _movements[date] = {
            cash_in: (item?.CASH_IN?.total_count || 0),
            cash_out: (item?.CASH_OUT?.total_count || 0),
            total: (item?.CASH_IN?.total_count || 0) + (item?.CASH_OUT?.total_count || 0)
          }

          _chart.push({
            date: date,
            in: Math.abs(item?.CASH_IN?.total_amount || 0),
            out: Math.abs(item?.CASH_OUT?.total_amount || 0),
          })
        });

        setChartData(_chart.reverse())
        setTransactions(_movements);
      }

      setLoading(false);
    } catch (error) {}
  }

  return (
    <Flex gap={20}>
      <Movements
        data={chartData}
      />
      <Row gap={20} wrap="wrap">
        <CardData
          loading={loading}
          title="Transações"
          data={transactions}
          labels={[
            {
              id: 'cash_in',
              color: color.success,
              text: "Entradas",
              textColor: color.text_primary_contrast,
            },
            {
              id: 'cash_out',
              color: color.error,
              text: "Saídas",
              textColor: color.text_primary_contrast,
            }
          ]}
        />
        <CardData
          loading={loading}
          title="Cobranças"
          chart={{
            keys: ['done', 'pending'],
            colors: ["#5375FA40", "#99999940"],
            text: "Taxa de conversão",
            value_key: 'conversion'
          }}
          data={charges}
          labels={[
            {
              id: 'done',
              color: color.success,
              text: "Concluídas",
              textColor: color.text_primary_contrast,
            },
            {
              id: 'pending',
              color: color.error,
              text: "Pendentes",
              textColor: color.text_primary_contrast,
            }
          ]}
        />
      </Row>
    </Flex>
  );
};

export default HomePage;

import { Icons } from "components/Icon";
import {
  BillPage,
  FeesPage,
  HelpPage,
  HomePage,
  ListCredentialsPage,
  ListLimitsPage,
  ListScheduledsPage,
  LoginPage,
  NotFoundPage,
  PixAddKeyPage,
  PixCopyPastePage,
  PixKeysPage,
  PixManualPage,
  PixPage,
  PixPayPage,
  SplitPage,
  ReceiptPage,
  ReportTransactionsPage,
  ReportUsagePage,
  StatementPage,
  MKPayPage,
  WebhooksPage
} from "pages";
import { User } from "types/user";

export type RouteId =
  | 'bill'
  | 'credential'
  | 'fees'
  | 'help'
  | 'home'
  | 'integration'
  | 'limits'
  | 'login'
  | 'not_found'
  | 'pix'
  | 'pix_pay'
  | 'pix_keys'
  | 'pix_add_key'
  | 'pix_copy_paste'
  | 'pix_manual'
  | 'pix_split_rules'
  | 'receipt'
  | 'receipt_container'
  | 'report'
  | 'report_transactions'
  | 'report_usage'
  | 'scheduled'
  | 'split'
  | 'statement'
  | 'mkpay'
  | 'mkpay_new_device'
  | 'webhook';

export interface RouteType {
  id: RouteId;
  authenticated?: boolean;
  component: Function;
  children_id?: RouteId[];
  disabled?: boolean;
  hidden?: boolean;
  label?: string;
  icon?: Icons;
  path: string;
  modal_path?: boolean;
  nav_id?: RouteId;
  restrict?: keyof User;
  restrict_pf?: boolean;
  restrict_pj?: boolean;
  title?: string;
}

export const routesPath: Record<RouteId, string> = {
  bill: '/pagar-conta',
  credential: '/credenciais',
  fees: '/tarifas',
  help: '/suporte',
  home: '/',
  integration: '/integracao',
  limits: '/limites',
  login: '/login',
  not_found: '*',
  pix: '/pix',
  pix_add_key: '/pix/nova-chave',
  pix_copy_paste: '/pix/copia-cola',
  pix_keys: '/pix/minhas-chaves',
  pix_manual: '/pix/agencia-e-conta',
  pix_pay: '/pix/pagar',
  pix_split_rules: 'pix/regras-split',
  receipt: ':id',
  receipt_container: '/comprovante',
  report: 'reports',
  report_transactions: '/relatorios/transacoes',
  report_usage: '/relatorios/consumo',
  scheduled: '/agendamentos',
  split: '/split',
  statement: '/extrato',
  webhook: '/webhooks',
  mkpay: '/mkpay',
  mkpay_new_device: '/mkpay/new-device'
}

export const routes: RouteType[] = [
  {
    id: 'bill',
    authenticated: true,
    component: BillPage,
    icon: 'cash',
    label: 'Pagar conta',
    path: routesPath.bill
  },
  {
    id: 'credential',
    authenticated: true,
    component: ListCredentialsPage,
    restrict: 'allow_create_api_credentials',
    label: 'Credenciais',
    nav_id: 'integration',
    path: routesPath.credential
  },
  {
    id: 'fees',
    authenticated: true,
    component: FeesPage,
    icon: 'fee',
    label: 'Tarifas',
    path: routesPath.fees,
    children_id: ['receipt']
  },
  {
    id: 'help',
    authenticated: true,
    component: HelpPage,
    label: 'Suporte',
    path: routesPath.help
  },
  {
    id: 'home',
    authenticated: true,
    component: HomePage,
    label: 'Home',
    icon: 'home',
    path: routesPath.home
  },
  {
    id: 'limits',
    authenticated: true,
    component: ListLimitsPage,
    icon: 'text-search',
    label: 'Limites e tarifas',
    path: routesPath.limits
  },
  {
    id: 'login',
    component: LoginPage,
    label: 'Login',
    path: routesPath.login
  },
  {
    id: 'not_found',
    component: NotFoundPage,
    label: 'Erro',
    path: routesPath.not_found
  },
  {
    id: 'pix',
    authenticated: true,
    children_id: [
      'pix_pay',
      'pix_copy_paste',
      'pix_manual',
      'pix_keys',
      'pix_add_key',
    ],
    component: PixPage,
    icon: 'pix',
    label: 'Pix',
    path: routesPath.pix
  },
  {
    id: 'pix_add_key',
    component: PixAddKeyPage,
    icon: 'key-plus',
    label: 'Cadastrar chave',
    nav_id: 'pix',
    path: routesPath.pix_add_key,
    title: 'Cadastro chave Pix'
  },
  {
    id: 'pix_copy_paste',
    authenticated: true,
    component: PixCopyPastePage,
    icon: 'content-copy',
    label: 'Copia e cola',
    path: routesPath.pix_copy_paste,
    nav_id: 'pix',
    title: 'Pix copia e cola'
  },
  {
    id: 'pix_keys',
    authenticated: true,
    component: PixKeysPage,
    icon: 'key-chain',
    label: 'Minhas chaves',
    path: routesPath.pix_keys,
    nav_id: 'pix',
    title: 'Minhas chaves Pix'
  },
  {
    id: 'pix_manual',
    authenticated: true,
    component: PixManualPage,
    icon: 'bank',
    label: 'Agencia e conta',
    path: routesPath.pix_manual,
    nav_id: 'pix',
    title: 'Pix agencia e conta'
  },
  {
    id: 'pix_pay',
    authenticated: true,
    component: PixPayPage,
    icon: 'key-outline',
    label: 'Pagar com chave',
    path: routesPath.pix_pay,
    nav_id: 'pix',
    title: 'Enviar Pix'
  },
  {
    id: 'split',
    authenticated: true,
    component: SplitPage,
    label: 'Split de pagamento',
    path: routesPath.split,
    nav_id: 'split'
  },
  {
    id: 'receipt',
    authenticated: true,
    component: ReceiptPage,
    label: 'Comprovante',
    path: routesPath.receipt,
  },
  {
    id: 'receipt_container',
    authenticated: true,
    children_id: ['receipt'],
    component: ReceiptPage,
    label: 'Comprovante',
    path: routesPath.receipt_container,
  },
  {
    id: 'report_transactions',
    authenticated: true,
    component: ReportTransactionsPage,
    label: 'Transações',
    nav_id: 'report',
    path: routesPath.report_transactions
  },
  {
    id: 'report_usage',
    authenticated: true,
    component: ReportUsagePage,
    label: 'Consumo',
    nav_id: 'report',
    path: routesPath.report_usage
  },
  {
    id: 'scheduled',
    authenticated: true,
    component: ListScheduledsPage,
    icon: 'calendar',
    label: 'Agendamentos',
    path: routesPath.scheduled
  },
  {
    id: 'statement',
    authenticated: true,
    component: StatementPage,
    icon: 'receipt',
    label: 'Extrato',
    path: routesPath.statement,
    children_id: ['receipt']
  },
  {
    id: 'webhook',
    authenticated: true,
    component: WebhooksPage,
    restrict: 'allow_create_api_credentials',
    label: 'Webhooks',
    nav_id: 'integration',
    path: routesPath.webhook
  },
  {
    id: 'mkpay_new_device',
    authenticated: true,
    component: MKPayPage,
    label: 'Cadastrar aparelho',
    path: routesPath.mkpay_new_device,
    nav_id: 'mkpay',
  },
];

export interface NavBarType {
  id: RouteId;
  label?: string;
  icon?: Icons;
  sub_paths?: Array<{
    id: RouteId;
    label?: string;
  }>;
}

export const navButtons: NavBarType[] = [
  {
    id: 'home',
  },
  {
    id: 'statement',
  },
  {
    id: 'fees',
  },
  {
    id: 'pix',
  },
  {
    id: 'split',
  },
  {
    id: 'bill',
  },
  {
    id: 'scheduled',
  },
  {
    id: 'limits',
  },
  {
    id: 'report',
    label: 'Relatórios',
    icon: 'clipboard-clock',
    sub_paths: [
      {
        id: 'report_transactions',
      },
      {
        id: 'report_usage',
      }
    ]
  },
  {
    id: 'integration',
    label: 'Integração',
    icon: 'dots-hexagon',
    sub_paths: [
      {
        id: 'credential',
      },
      {
        id: 'webhook',
      }
    ]
  },
  {
    id: 'mkpay',
    label: 'MK Pay',
    icon: 'qrcode-scan',
    sub_paths: [
      {
        id: 'mkpay_new_device',
      },
    ]
  }
];

import styled, { css, keyframes } from "styled-components";
import Button from "../Button";
import Modal from "./Modal";
import Text from "../Text";
import Flex, { Row } from "components/layout/Flex";
import Card from "components/layout/Card";

export interface AlertProps {
  message?: string;
  title?: string;
  cancelable?: boolean;
  cancelText?: string;
  confirmText?: string;
  onCancelClick?: () => void;
  onConfirmClick?: () => void;
  onBackdropClick?: () => void;
  position?: "center" | "top";
  loading?: boolean;
  visible?: boolean;
  useLoading?: boolean;
  helperText?: string;
}

const Alert: React.FC<AlertProps> = ({ visible = true, ...props }) => {
  return (
    <Modal
      visible={visible}
      onBackdropClick={props.onBackdropClick}
      backdropClickDisabled={!!props.onConfirmClick || !!props.onCancelClick}
    >
      <Content minWidth={400} gap={20}>
        <Flex>
          <Text size={"subtitle"} weight={700}>
            {props.title}
          </Text>
        </Flex>

        <Text>
          {props.message}
        </Text>

        {props.helperText &&
          <Text >
            {props.helperText}
          </Text>
        }

        <Row gap={10} justify="flex-end">
          {(props.cancelText || props.cancelable) && (
            <Button
              disabled={props.loading}
              height={40}
              variant="ghost"
              minWidth={'4rem'}
              label={props.cancelText || "Cancelar"}
              onClick={props.onCancelClick}
            />
          )}
          <Button
            loading={props.loading}
            onClick={props.onConfirmClick}
            label={props.confirmText || "Ok"}
            minWidth={'4rem'}
            height={40}
          />
        </Row>
      </Content>
    </Modal >
  );
};

const animation = keyframes`
  0% {
    transform: translate(0, -20rem);
  }
  100% {
    transform: translate(0, 0);
  }
`;

const fadein = keyframes`
  from { 
    transform: scale(.8, .8);
    opacity: 0;
  }
  to { 
    transform: scale(1, 1);
    opacity: 1; 
  }
`;

const Content = styled(Card) <{ position?: string }>`
  ${(props) =>
    props.position === "center"
      ? css`
          animation: ${animation} 0.5s;
        `
      : css`
          animation: ${fadein} 0.5s forwards;
        `}
`;

export default Alert;

import styled from "styled-components";
import {
  Button,
  Card,
  Flex,
  Icon,
  Row,
  Text
} from "components";
import { Icons } from "../components/Icon";
import { color } from "../theme";
import { useNavigationProps } from "navigation";
import { useEffect } from "react";

const HelpPage = () => {
  const { setNavigationProps } = useNavigationProps();

  useEffect(() => {
    setNavigationProps({ showAmounts: false })
  }, []);

  return (
    <Flex flex center>
      <Text size={"headline_2"} marginBottom={20}>
        Suporte
      </Text>
      <Row center gap={20} wrap>
        <HelperCard
          title={"Chat MK Bank"}
          color={color.secondary}
          icon={"chat-processing"}
          description="Como podemos te ajudar?"
          children={
            <Link href="https://wa.me/551135090960" target="_blank">
              <Button variant="ghost" size="md" label="Iniciar chat" />
            </Link>
          }
        />
        <HelperCard
          title={"Tire suas dúvidas"}
          color={color.success}
          icon={"progress-question"}
          description="Acesse nosso FAQ e tire todas as suas dúvidas"
          children={
            <Link href="https://mkbank.com.br/mk-pt/faq.html" target="_blank">
              <Button variant="ghost" size="md" label="Acessar FAQ" />
            </Link>
          }
        />
        <HelperCard
          title={"Taxas e Tarifas"}
          color={color.error}
          icon={"chart-box-outline"}
          description="Fique por dentro de todos os valores dos nossos serviços"
          children={
            <Link href="https://www.mkbank.com.br/mk-pt/precos-tarifas.html" target="_blank">
              <Button variant="ghost" size="md" label="Taxas e tarifas" />
            </Link>
          }
        />
        <HelperCard
          title={"Redes Sociais"}
          color={color.error}
          icon={"thumb-up"}
          description="Siga @Mkbank_digital nas redes sociais"
          children={
            <Row gap={10}>
              <Link href="https://www.instagram.com/mkbank_digital/" target="_blank">
                <Icon color={color.primary} name="instagram" />
              </Link>
              <Link href="https://www.facebook.com/mkbankdigital" target="_blank">
                <Icon color={color.primary} name="facebook" />
              </Link>
              <Link href="https://www.linkedin.com/company/mk-bank/" target="_blank">
                <Icon color={color.primary} name="linkedin" />
              </Link>
            </Row>
          }
        />
        <HelperCard
          title={"Suporte"}
          color={color.error}
          icon={"list-box-outline"}
          description="Segunda a Sexta-feira das 9h às 18h"
          children={
            <Flex marginTop={10}>
              <Link href="https://o143211-c16.octadesk.com/custom-form/cfc09e22-b56a-43c0-b173-50aa34cf5894" target="_blank">
                <Button variant="ghost" size="md" label="Acessar suporte" />
              </Link>
            </Flex>
          }
        />
        <HelperCard
          title={"Central de relacionamento"}
          color={color.error}
          icon={"chat-outline"}
          description="Disponível de segunda a sexta-feira das 9h às 18h, exceto feriados."
          children={
            <Flex marginTop={10}>
              <Text>
                Fale conosco: <Link href="tel:1140038422">11 4003 8422</Link>
              </Text>
              <Text>
                Ouvidoria: <Link href="tel:08008789054">0800 878 9054</Link>
              </Text>
            </Flex>
          }
        />
      </Row>
    </Flex>
  );
};

const HelperCard = (props: {
  title: string;
  icon: Icons;
  description: string;
  color: string;
  children?: any;
}) => (
  <Card justify="center" height={160} minWidth={300} width={'30vw'}>
    <Row>
      <Flex center width={'20%'}>
        <Icon
          color={color.primary}
          name={props.icon}
          size={60}
        />
      </Flex>

      <Flex flex padding={"0 20px"}>
        <Text color={color.text_secondary} weight={"bold"} size="subtitle">
          {props.title}
        </Text>
        <Text color={color.text_secondary} size="footnote">
          {props.description}
        </Text>
        {props.children}
      </Flex>
    </Row>
  </Card>
);

const Link = styled.a`
  color: ${color.primary};
  text-decoration: none;
  padding-top: 10px;
  &:hover{
    color: ${color.text_secondary};
  }
`;

export default HelpPage;

import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, Flex, Row, Spinner, Text } from "components";
import { lastDays } from "helpers/date";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import PieChart from "./PieChart";
import DateSelection from "./DateSelection";
import { currency } from "helpers/number";
import { color } from "theme";

interface CardInfoProps {
  title: string;
  labels: Omit<LabelProps, 'value'>[];
  chart?: {
    colors: string[];
    keys: string[];
    text: string;
    value_key: string;
  };
  data?: {
    [key: string]: DataType;
  };
  loading?: boolean;
}

interface LabelProps {
  id: string;
  color: string;
  textColor?: string;
  text: string;
  value: number | string;
}

type DataType = {
  [key: string]: number | string;
  total: number;
}

const CardInfo: React.FC<CardInfoProps> = (props) => {
  const daysArray = useRef(lastDays(7, (date) => format(date, "yyyy-MM-dd", { locale: ptBR })));
  const [index, setIndex] = useState(0);
  const [data, setData] = useState<DataType | null>();

  useEffect(() => {
    if (
      typeof props.data === 'object' &&
      props.data.hasOwnProperty(daysArray.current[index])
    ) {
      setData(props.data[daysArray.current[index]]);
    } else {
      setData(null);
    }
  }, [index || props.data]);

  return (
    <Card flex={props.chart ? "1.4" : "1"}>
      <CardHeader
        title={props.title}
        justify="flex-start"
      />

      <Row gap={20}>
        <Flex flex="2" gap={20}>
          <Row gap={20} height={140}>
            <Flex flex border borderRadius center height={'calc(100% - 20px)'} padding={10}>
              {props.loading ?
                <Spinner
                  color={color.primary}
                />
                :
                <Text size={"headline_2"}>
                  {currency(data?.total || 0, { digits: 0 })}
                </Text>
              }
              <Text align="center">
                Total de {props.title.toLowerCase()}
              </Text>
            </Flex>

            <Flex flex height={'100%'} gap={props.labels.length > 2 ? 10 : 20}>
              {props.labels.map((item, i) =>
                <Label
                  key={i}
                  loading={props.loading}
                  {...item}
                  value={currency(data?.[item.id] as number || 0, { digits: 0 })}
                />
              )}
            </Flex>
          </Row>

          <DateSelection
            index={index}
            data={daysArray.current}
            onSelect={(i) => setIndex(i)}
          />
        </Flex>

        {!!props.chart &&
          <Flex flex height={130 + 60} minWidth={180}>
            <PieChart
              loading={props.loading}
              colors={props.chart.colors}
              data={(() => {
                let previous_value = 1;
                return props.chart.keys.map((name, i) => {
                  let value = previous_value > 0 ? 0 : 1;
                  if (!!data?.[name] && data?.[name] as number > 0) {
                    value = data?.[name] as number;
                  }
                  previous_value = value;
                  return { name, value }
                });
              })()}
              label={props.chart.text}
              value={`${data?.[props.chart.value_key] || '0.00'}%`}
            />
          </Flex>
        }
      </Row>
    </Card>
  )
}

const Label: React.FC<LabelProps & { loading?: boolean }> = (props) => {
  return (
    <Row flex center borderRadius bgColor={props.color} wrap="wrap" padding={10}>
      <Text color={props.textColor} size={"caption"} css="text-overflow: ellipsis;" marginRight={5}>
        {props.text}:
      </Text>
      {props.loading ?
        <Spinner
          size="xxs"
          color={color.text_primary_contrast}
        />
        :
        <Text color={props.textColor} size={"headline"}>
          {props.value}
        </Text>
      }
    </Row>
  )
}

export default CardInfo;
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Icon,
  Row,
  Text,
  Spinner
} from "components";
import {
  useAlert,
  useCode
} from "hooks";
import {
  color,
  size
} from "theme";
import {
  CredentialCreateType,
  CredentialResponseType,
  CredentialCreateResponseType
} from "types/credential";
import { StoreState } from "store";
import { Icons } from "components/Icon";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import CredentialForm from "./CredentialForm";
import showError from "helpers/showError";
import banking from "api";
import CredentialSuccess from "./CredentialSuccess";

const ListCredentialsPage: React.FC = () => {
  const alert = useAlert();
  const modalCode = useCode();
  const { user } = useSelector((state: StoreState) => state.account);

  const [list, setList] = useState<CredentialResponseType[]>();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [visible, setVisible] = useState(false);
  const [credential, setCredential] = useState<CredentialResponseType>();
  const [success, setSuccess] = useState(false);
  const [dataSuccess, setDataSuccess] = useState<CredentialCreateResponseType>();
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setVisible(!!credential);
  }, [credential])

  async function loadData() {
    setLoading(true);
    try {
      const response = await banking.credentials.list();
      setList(response);
    } catch (error) {
      showError(alert, error);
    }
    setLoading(false);
  }

  function request2FA(data: CredentialCreateType) {
    modalCode({
      onConfirm: (code) => {
        if (credential) {
          return handleEdit(credential.id, data)
        }
        handleSave(code, data)
      },
      operation: 'API_CREDENTIAL',
      type: user?.preferred_2fa_channel || undefined
    })
  }

  async function handleSave(verification_code: string, data: CredentialCreateType) {
    setSaving(true);
    try {
      const res = await banking.credentials.create({
        ...data,
        verification_code
      });
      setVisible(false)
      setDataSuccess(res)
      setSuccess(true)
      loadData()
    } catch (error) {
      showError(alert, error);
    }
    setSaving(false);
  }

  async function handleEdit(id: number, data: CredentialCreateType) {
    setSaving(true);
    try {
      await banking.credentials.update(id, data);
      setVisible(false)
      setCredential(undefined)
      loadData()
    } catch (error) {
      showError(alert, error);
    }
    setSaving(false);
  }

  async function handleRevoke(id: number) {
    setLoading(true);
    try {
      await banking.credentials.revoke(id);
    } catch (error) {
      showError(alert, error);
    }
    setLoading(false);
    loadData()
  }
  function close(){
    setCredential(undefined)
    setVisible(false)
  }
  return (
    <Card
      actionLoading={loading}
      headerText="Lista de credenciais"
      rightButton={
        <Button
          label="Criar nova credencial"
          size="md"
          onClick={() => setVisible(true)}
        />
      }
    >
      <DataTable
        columns={[
          {
            selector: (item: CredentialResponseType) => item.name,
            name: <HeaderIcon name="account" text="Nome" />,
            sortable: true,
          },
          {
            selector: (item: CredentialResponseType) => item.key,
            name: <HeaderIcon name="shield-key" text="Key" />,
            wrap: true,
          },
          {
            selector: (item: CredentialResponseType) => item.has_write_permission,
            name: <HeaderIcon name="cash-minus" text="Cash Outs" />,
            center: true,
            cell: (item: CredentialResponseType) =>
              <Icon
                color={item.has_write_permission ? color.success : color.error}
                name={item.has_write_permission ? 'check' : 'close'}
                size={size.sm_number}
              />,
          },
          {
            selector: (item: any) => item.allowed_ips,
            name: <HeaderIcon name="server" text="Ips" />,
            wrap: true,
          },
          {
            selector: (item: CredentialResponseType) => item.revoked,
            cell: (item: CredentialResponseType) =>
              <Text color={item.revoked ? color.error : color.success}>
                {item.revoked ? 'Revogada' : 'Ativa'}
              </Text>,
            name: <HeaderIcon name="information-box-outline" text="Situação" />,
            center: true,
          },
          {
            cell: (item: CredentialResponseType) => item.revoked ? null :
              <Row gap={10}>
                <Button
                  size="sm"
                  scheme="secondary"
                  onClick={() => setCredential(item)}
                  icon={{
                    name: "file-edit-outline",
                    position: "left",
                    size: size.xs_number
                  }}
                  minWidth={'3rem'}
                />
                <Button
                  disabled={!!item.revoked_at}
                  icon={{
                    name: "close",
                    position: "left",
                    size: size.xs_number
                  }}
                  size="sm"
                  scheme="error"
                  onClick={() =>
                    alert({
                      title: "Revogar credencial",
                      message: "Tem certeza que deseja revogar o acesso desta credencial?",
                      confirmText: "Revogar",
                      onConfirmClick: () => handleRevoke(item.id),
                      cancelText: "Cancelar",
                    })
                  }
                  minWidth={'3rem'}
                />
              </Row>,
            right: true,
          },
        ]}
        data={list || []}
        progressPending={loading}
        progressComponent={<Spinner size="md" color={color.primary} />}
        noDataComponent="Sem credenciais no momento!"
      />

      <CredentialForm
        data={credential}
        loading={saving}
        onClose={() => close()}
        onSubmit={(data) => request2FA(data)}
        visible={visible}

      />
      <CredentialSuccess
        data={dataSuccess}
        onClose={() => setSuccess(false)}
        visible={success}

      />
    </Card>
  );
};

const HeaderIcon = ({ name, text }: { name: Icons; text: string }) => {
  return (
    <Row gap={10}>
      <Icon name={name} />
      <Text>{text}</Text>
    </Row>
  );
};

export default ListCredentialsPage;

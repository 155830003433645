import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Icon,
  Row,
  Text,
  HeaderFilter,
  Spinner
} from "components";
import { format } from "date-fns";
import { Icons } from "components/Icon";
import { currency } from "helpers/number";
import { color } from "theme";
import { Order } from "types/order";
import { useAlert } from "hooks";
import { OrderParams } from "api/Orders";
import { translateOwnerType } from "helpers/text";
import { Outlet, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import showError from "helpers/showError";
import banking from "api";

const ReceiptPage: React.FC = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const [orders, setOrders] = useState<Order[]>();
  const [loading, setLoading] = useState(true);

  const [period, setPeriod] = useState(7);
  const [term, setTerm] = useState<string>();
  const [end_to_end_id, setEndtoEndId] = useState<string>();
  const [fromDate, setFromDate] = useState<string>();
  const [untilDate, setUntilDate] = useState<string>();

  useEffect(() => {
    loadData({
      days: period,
      from: fromDate,
      until: untilDate,
      term: term,
      end_to_end_id: end_to_end_id,
    });
  }, [term, period, fromDate, untilDate, end_to_end_id]);

  async function loadData(params?: OrderParams) {
    console.log("parametros", params)
    setLoading(true);
    try {
      const response = await banking.orders.list(params);
      setOrders(response);
    } catch (error) {
      showError(alert, error);
    }
    setLoading(false);
  }

  return (
    <Card id="card-st-pg" position="sticky">
      <CardHeader justify="flex-start" title="Extrato" />
      <HeaderFilter
        hideSearch={false}
        onInitialDateChange={(date) => setFromDate(date)}
        onFinalDateChange={(date) => setUntilDate(date)}
        onSelectPeriod={(value) => setPeriod(value)}
        onSearch={(text) => setEndtoEndId(text)}
      />
      <DataTable
        columns={[
          {
            // center: true,
            selector: (item: any) => item.performed_at,
            name: <HeaderIcon name="calendar" text="Data" />,
            cell: (item: any) => format(new Date(item?.performed_at), "dd/MM/yyyy"),
          },
          {
            // center: true,
            selector: (item: any) => <TransactionType type={item.type} />,
            name: <HeaderIcon name="swap" text="Movimento" />,
          },
          {
            // center: true,
            selector: (item: any) => translateOwnerType(item.owner_type),
            name: <HeaderIcon name="cached" text="Transação" />,
          },
          {
            // center: true,
            selector: (item: any) => item?.complement,
            name: <HeaderIcon name="account" text="Complemento" />,
          },
          {
            // center: true,
            right: true,
            selector: (item: any) => currency(item?.amount, { prefix: 'R$ ' }),
            name: <HeaderIcon name="currency" text="Valor" />,
          }
        ]}
        onRowClicked={(item) => navigate(`/extrato/${item.id}`)}
        pointerOnHover
        data={orders || []}
        highlightOnHover
        progressComponent={<Spinner color={color.primary} />}
        progressPending={loading}
        noDataComponent="Sem movimentações no período!"
        responsive={true}
      />
      <Outlet />
    </Card>
  );
};

const HeaderIcon = ({ name, text }: { name: Icons; text: string }) => {
  return (
    <Row gap={5}>
      <Icon name={name} />
      <Text>{text}</Text>
    </Row>
  );
};

const TransactionType = ({ type }: { type: string }) => {
  return (
    <Row>
      <Icon size={10} name="circle" color={type == "CASH_OUT" ? color.error : color.success} />
      <Text padding={"0px 10px"} size={"caption"}>
        {type == "CASH_IN" ? "Entrada" : "Saída"}
      </Text>
    </Row>
  );
};

export default ReceiptPage;

import { WeekhookType } from "types/webhook";
import BaseModule from "./BaseModule";

class Webhooks extends BaseModule {
  list(): Promise<WeekhookType[]> {
    return this.get("/ib/webhooks");
  }

  create(data: {
    type: string;
    url: string;
    verification_code: string;
    secret: string;
  }) {
    return this.post(`/ib/webhooks`, data);
  }

  remove(id: number, verification_code: string) {
    return this.post(`/ib/webhooks/${id}`, {
      verification_code,
    });
  }
}

export default Webhooks;

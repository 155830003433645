import { attributeCSS } from "helpers/text"
import styled from "styled-components"
import base from "./base"

export interface FlexProps {
  /** 
   * align-items = center
   * 
   * justify-content = center
   */
  center?: boolean;
  /** 
   * flex-direction
   */
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  /** 
   * flex
   * 
   * if true = 1
   */
  flex?: string | boolean;
  /** 
   * align-items
   * 
   * if true = center
   */
  align?: string | boolean;
  /** 
   * justify-content
   * 
   * if true = center
   */
  justify?: string | boolean;
  /** 
   * flex-grow
   * 
   * if true = 1
   */
  grow?: string | boolean;
  /**
   * flex-wrap
   * 
   * if true = wrap
   */
  wrap?: boolean | string;
  /**
   * gap
   * 
   * if true = inherit
   */
  gap?: boolean | number | string;
}

export function flexAttributes(props: FlexProps) {
  return `
    display: flex;
    ${attributeCSS('flex', props.flex, '1')}
    ${attributeCSS('flex-direction', props.direction || 'column')}
    ${attributeCSS('align-items', props.align, 'center')}
    ${attributeCSS('justify-content', props.justify, 'center')}
    ${attributeCSS('flex-grow', props.grow, '1')}
    ${attributeCSS('flex-wrap', props.wrap, 'wrap')}
    ${attributeCSS('gap', props.gap, 'inherit')}
    ${props.center ? attributeCSS(['align-items', 'justify-content'], 'center') : ''}
  `
}

const Flex = styled(base) <FlexProps>`
  ${(props) => `
    ${flexAttributes(props)}
  `}
`

export const Row = styled(Flex)`
  flex-direction: row;
  ${(props) => attributeCSS('align-items', props.align || 'center')}
`

export default Flex;
import axios, { AxiosInstance, AxiosResponse } from "axios";
import Account from "./Account";
import Auth from "./Auth";
import Bill from "./Bill";
import Credentials from "./Credentials";
import Customer from "./Customer";
import Orders from "./Orders";
import Invoices from "./Invoices";
import Pix from "./Pix";
import Split from "./Split";
import Transfer from "./Transfer";
import Reports from "./Reports";
import Webhooks from "./Webhooks";
import MKPay from "./MKPay";
class Banking {
  axios: AxiosInstance;
  account_number?: number;
  token: string;
  onUnauthenticated?: (response: AxiosResponse) => void;
  account;
  auth;
  bill;
  credentials;
  customer;
  orders;
  invoices;
  pix;
  split;
  transfer;
  reports;
  webhooks;
  mkpay;

  constructor(props: { baseURL: string }) {
    this.axios = axios.create(props);
    this.token = '';
    this.account = new Account(this);
    this.auth = new Auth(this);
    this.bill = new Bill(this);
    this.credentials = new Credentials(this);
    this.customer = new Customer(this);
    this.orders = new Orders(this);
    this.invoices = new Invoices(this);
    this.pix = new Pix(this);
    this.split = new Split(this);
    this.transfer = new Transfer(this);
    this.reports = new Reports(this);
    this.webhooks = new Webhooks(this);
    this.mkpay = new MKPay(this);

    this.axios.interceptors.request.use(
      (config) => {
        config.headers["X-Castle-Request-Token"] = this.token;
        config.headers['Account-Number'] = this.account_number;
        return config
      },
      (error) => Promise.reject(error)
    );

    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.onUnauthenticated?.(error.response);
        }
        return Promise.reject(error);
      }
    );
  }
}

export default Banking;

import { useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Input,
  Row,
  Flex,
  IconButton,
  Text,
  StatusBadge,
} from "components";
import CopyToClipboard from "helpers/CopyToClipBoard";
import { color, size } from "theme";
import {
  CredentialCreateResponseType
} from "types/credential";


interface CredentialSuccessProps {
  onClose?: () => void;
  data?: CredentialCreateResponseType;
  loading?: boolean;
  visible?: boolean;
}

const CredentialSuccess: React.FC<CredentialSuccessProps> = (props) => {


  return props.visible ? (
    <Card
      modal
      headerText="Nova Credencial"
      onClose={props.onClose}
    >
      <Row
        bgColor={color.success}
        borderRadius={size.rounded}
        padding={'12px 12px'}
      >
        <Text size={'caption'} color="white">
          Credenciais criadas com sucesso! Armazene as credenciais abaixo em um local seguro.
        </Text>
      </Row>
      <Flex>
        <Row
          key={props.data?.id}
          border
          justify={"space-between"}
          marginTop={15}
        >
          <Flex padding={10}>
            <Text size="caption" weight={700}>
              Key:
            </Text>
            <Text>
              {props.data?.key}
            </Text>
          </Flex>

          <Row>
            <IconButton
              icon="content-copy"
              iconColor={color.primary}
              onClick={() => CopyToClipboard(props.data?.key ? props.data.key : '')}
            />

          </Row>
        </Row>

        <Row
          key={props.data?.id}
          border
          justify={"space-between"}
          marginTop={15}
        >
          <Flex padding={10}>
            <Text size="caption" weight={700}>
              Secret:
            </Text>
            <Text>
              {props.data?.visible_secret}
            </Text>
          </Flex>

          <Row>
            <IconButton
              icon="content-copy"
              iconColor={color.primary}
              onClick={() => CopyToClipboard(props.data?.visible_secret ? props.data.visible_secret : '')}
            />

          </Row>
        </Row>

      </Flex>

      <Row center marginTop={15}>
        <Button
          label="Fechar"
          minWidth="8rem"
          onClick={props.onClose}
        />
      </Row>

    </Card>
  ) : null;
};

export default CredentialSuccess;

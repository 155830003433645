import {
  useEffect,
  useState
} from "react";
import {
  logo,
  logo_horizontal
} from "../assets";
import {
  RouteId,
  RouteType,
  navButtons,
  routes
} from "routes";
import {
  Flex,
  Row
} from "components";
import { useAlert } from "hooks";
import { color } from "../theme";
import { StoreState } from "store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isRouteDisabled } from "helpers/route";
import NavItem from "./NavItem";
import banking from "api";
import styled from "styled-components";
import { laptop } from "helpers/rules";

const NavBar: React.FC<{ pathname: string }> = ({ pathname }) => {
  const alert = useAlert();
  const navigate = useNavigate();

  async function loggout() {
    await banking.auth.logout();
    navigate("/login", { state: null, replace: true });
  }

  return (
    <Nav
      bgColor={color.background}
      borderRight
      padding={10}
      gap={10}
      minWidth={240}
      width="15%"
      scrollY
      laptop={{
        width: 'auto',
        minWidth: 48
      }}
    >
      <header>
        <img id="hd-lg-sh" src={logo} alt="logo" height={30} />
        <img id="hd-lg-lg" src={logo_horizontal} alt="logo" height={40} />
      </header>

      <Buttons
        routes={routes}
        pathname={pathname}
      />

      <Row flex minHeight={10} />

      <NavItem
        path="/suporte"
        active={pathname === "/suporte"}
        label="Suporte"
        icon="chat"
      />

      <NavItem
        label="Sair"
        icon="logout"
        onClick={() => {
          alert({
            title: "Logout",
            message: "Deseja realmente efetuar o logout?",
            onConfirmClick: loggout,
            cancelText: "Voltar",
            position: "center",
          });
        }}
      />
    </Nav>
  );
};

const Buttons: React.FC<{
  pathname: string;
  onClick?: (id: RouteId, path: string) => void;
  routes: RouteType[];
}> = (props) => {
  const { user } = useSelector((state: StoreState) => state.account);
  const [currentRoute, setCurrentRoute] = useState<RouteType>();
  const [visibleId, setVisibleId] = useState<string>();

  useEffect(() => {
    getRoute(props.pathname);
  }, [props.pathname]);

  function getRoute(pathname: string) {
    const route = props.routes.find(({ path }) => pathname.endsWith(path));

    let routeNav = navButtons.find((item) => route?.nav_id === item.id || route?.id === item.id);

    return setCurrentRoute(routeNav as RouteType);
  }

  useEffect(() => {
    setVisibleId(undefined);
  }, [props.pathname])

  function toggleId(id?: string) {
    if (visibleId === id) {
      return setVisibleId(undefined);
    }
    setVisibleId(id);
  }

  return (
    <>
      {navButtons.map((item, i) => {
        let options: any[] = [];
        const route = props.routes.find(({ id }) => item.id === id);

        if (isRouteDisabled(route, user)) {
          return null;
        }

        if (item.sub_paths) {
          item.sub_paths.map((sub) => {
            const sub_route = props.routes.find(({ id }) => sub.id === id);

            if (isRouteDisabled(sub_route, user)) {
              return null;
            }

            options.push({
              ...sub_route,
              ...sub
            });
          })
        }

        if (item.sub_paths && options.length === 0) {
          return null;
        }

        return (
          <NavItem
            key={i}
            active={currentRoute?.id === item.id}
            label={item.label || route?.label || ''}
            icon={item.icon || route?.icon || 'application_processing'}
            onClick={() => toggleId(item.id)}
            options={options}
            path={route?.path}
            show={visibleId === item.id}
            visible={currentRoute?.id === item.id}
          />
        )
      })}
    </>
  )
}

const Nav = styled(Flex)`
  & > header {
    background-color: ${color.background};
    position: sticky;
    top: -11px;
    padding: 20px;
  }
  & #hd-lg-sh {
    display: none;
  }
  & #hd-lg-lg {
    display: block;
  }
  ${laptop(`
    & #hd-lg-sh {
      display: block;
    }
    & #hd-lg-lg {
      display: none;
    }
    & header {
      align-self: center;
      padding: 11px 0px;
    }
  `)}
`;

export default NavBar;

import React, {
  useEffect,
  useState
} from "react";
import {
  Button,
  ModalFilter,
  Flex,
  Card,
  CardHeader,
  Icon,
  Row,
  Spinner,
  StatusBadge,
  Text
} from "components";
import { color } from "theme";
import { useAlert } from "hooks";
import { format } from "date-fns";
import { Report } from "types/report";
import { Icons } from "components/Icon";
import { downloadFileBlob } from "helpers/text";
import DataTable from "react-data-table-component";
import banking from "api";
import "./styles.css";

const ReportUsagePage: React.FC = () => {
  const alert = useAlert();
  const [reportList, setReportList] = useState<any>();
  const [totalRows, setTotalRows] = useState(0);

  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      const reports = await banking.reports.listReports("ACCOUNT_USAGE");
      setReportList(reports);
      setTotalRows(reports.length);
    } catch (error) { }
    setLoading(false);
  }

  async function handleConfirm(from: string, until: string) {
    setRequesting(true);
    try {
      await banking.reports.requestReport("ACCOUNT_USAGE", { from, until });
      setVisible(false);
      loadData();
      alert({
        title: "Solicitação concluída",
        message: "Aguarde o processamento para realizar o download do relatório.",
        confirmText: "Ok",
        position: "center",
      });
    } catch (error) {
      alert({
        title: 'Erro',
        message: 'Não foi possível concluir sua solicitação'
      });
    }
    setRequesting(false);
  }

  async function onDownload(item: Report) {
    const fileName = `Relatorio_de_uso_${item.params.from}_-_${item.params.until}`;
    try {
      const response = banking.reports.requestUrlReportCSV(item.id, item.account.number);
      window.open(response)
      // downloadFileBlob(response, fileName, "csv");
    } catch (error) {
      alert({
        title: 'Erro',
        message: 'Não foi possível concluir sua solicitação'
      });
    }
  };

  return (
    <Card>
      <CardHeader
        title="Consumo"
        rightButton={
          <Button
            disabled={loading}
            variant="solid"
            size="md"
            icon={{ name: "receipt", position: "left" }}
            label={"Solicitar novo relatório"}
            onClick={() => setVisible(true)}
          />
        }
      />

      {loading ?
        <Flex center>
          <Spinner
            color={color.primary}
          />
        </Flex>
        :
        <DataTable
          columns={[
            {
              name: <HeaderIcon name="chart-box-plus-outline" text="Relatório" />,
              selector: (row: Report) => row.description,
              wrap: true,
            },
            {
              center: true,
              cell: (row: Report) => format(new Date(row?.params?.from), "dd/MM/yyyy"),
              name: <HeaderIcon name="calendar" text="Data inicial" />,
              selector: (row: Report) => row?.params?.from,
            },
            {
              center: true,
              cell: (row: Report) => format(new Date(row?.params?.until), "dd/MM/yyyy"),
              name: <HeaderIcon name="calendar" text="Data final" />,
              selector: (row: Report) => row?.params?.until,
            },
            {
              center: true,
              name: <HeaderIcon name="refresh" text="Transação" />,
              selector: (row: Report) => row?.type,
            },
            {
              center: true,
              cell: (row: Report) => <StatusBadge item={row} />,
              name: <HeaderIcon name="check-circle-outline" text="Status" />,
              selector: (row: Report) => row.status,
            },
            {
              center: true,
              cell: (item: Report) => (
                <Button
                  disabled={item?.status !== "DONE"}
                  size="sm"
                  scheme="warning"
                  onClick={() => onDownload(item)}
                  label={"Baixar CSV"}
                  icon={{
                    size: 15,
                    position: "left",
                    name: "file-download",
                  }}
                />
              ),
              name: <HeaderIcon name="file-download" text="Download" />,
            },
          ]}
          data={reportList.data}
          highlightOnHover
          noDataComponent={"Não há relatórios a serem mostrados!"}
          progressPending={loading}
          progressComponent={<Spinner size="md" />}
          pagination
          paginationTotalRows={totalRows}
          paginationComponentOptions={{
            noRowsPerPage: false,
            rowsPerPageText: "Por página",
            rangeSeparatorText: "de",
            selectAllRowsItem: false,
            selectAllRowsItemText: "",
          }}
        />
      }

      <ModalFilter
        loading={requesting}
        visible={visible}
        onConfirm={handleConfirm}
        onClose={() => setVisible(!visible)}
      />
    </Card>
  );
};

const HeaderIcon = (props: { name: Icons; text: string }) => {
  return (
    <Row gap={10}>
      <Icon
        color={color.primary}
        name={props.name}
      />
      <Text>
        {props.text}
      </Text>
    </Row>
  );
};

export default ReportUsagePage;

import { useState } from "react";
import {
  Button,
  Checkbox,
  CurrencyInput,
  Form,
  Input,
  Row,
  SchedulerPayment,
  Text
} from "components";
import {
  PixPaymentType,
  PixSearchKeyResponse
} from "types/pix";
import { format } from "date-fns";
import { color } from "theme";
import { maskDocument } from "helpers/mask";

interface PixKeyDataProps {
  keyData: PixSearchKeyResponse;
  loading?: boolean;
  onConfirm: (data: Omit<PixPaymentType, 'verification_code'>) => void;
  onCancel: () => void;
}

const PixKeyData: React.FC<PixKeyDataProps> = (props) => {
  const [amount, setAmount] = useState(0);
  const [notes, setNotes] = useState<string>();
  const [selectedDate, setSelectedDate] = useState<string>();
  const [saveFavored, setSaveFavored] = useState(true);
  const [schedule, setSchedule] = useState(false);
  const [validDate, setValidDate] = useState(false);

  return (
    <Form
      gap
      onReset={props.onCancel}
      onSubmit={(e) => {
        e.preventDefault();
        props.onConfirm({
          amount,
          notes,
          save_favored: saveFavored,
          scheduling_date: selectedDate || format(new Date(), "yyyy-MM-dd"),
          validation_code: props.keyData.validation_code
        });
      }}
    >
      <Text>
        {props.keyData?.name}
      </Text>

      <Row gap={4}>
        <Text color={color.text_secondary} size={"caption"}>Chave:</Text>
        <Text size={"caption"}>{props.keyData?.key}</Text>
      </Row>

      <Row gap={4}>
        <Text color={color.text_secondary} size={"caption"}>Documento:</Text>
        <Text size={"caption"}>{maskDocument(props.keyData?.document_number)}</Text>
      </Row>

      <Row gap={4}>
        <Text color={color.text_secondary}>Instituição:</Text>
        <Text size={"caption"}>{props.keyData?.participant}</Text>
      </Row>

      <SchedulerPayment
        onChange={(value) => setSchedule(value)}
        onDateChange={(date, is_future) => {
          setSelectedDate(date);
          setValidDate(is_future);
        }}
        value={selectedDate}
      />

      <Input
        placeholder="Descrição (opcional)"
        onChange={({ target }) => setNotes(target.value)}
      />

      <Checkbox
        label="Salvar contato"
        name={"save_favored"}
        checked={saveFavored}
        onChange={setSaveFavored}
      />

      <CurrencyInput
        autoFocus
        onChange={setAmount}
        value={amount}
      />

      <Row center gap>
        <Button
          type="reset"
          disabled={props.loading}
          label="Cancelar"
          variant="ghost"
          width="10rem"
        />
        <Button
          type="submit"
          disabled={!(amount > 0) || (schedule && !validDate)}
          label="Buscar"
          loading={props.loading}
          width="10rem"
        />
      </Row>
    </Form>
  )
}

export default PixKeyData;
import React, { useEffect, useState } from "react";
import { Button, Icon, Row, Text, Spinner, Card } from "components";
import { Icons } from "components/Icon";
import { color } from "theme";
import banking from "api";
import { useAlert } from "hooks";
import DataTable from "react-data-table-component";
import { format } from "date-fns";
import { currency } from "helpers/number";
import { Scheduled } from "types/order";
import showError from "helpers/showError";


const HeaderIcon = ({ name, text }: { name: Icons; text: string }) => {
  return (
    <Row gap={5}>
      <Icon name={name} />
      <Text>{text}</Text>
    </Row>
  );
};



const ListScheduledsPage: React.FC = () => {
  const [scheduledList, setScheduledList] = useState<Scheduled[]>();
  const [loading, setLoading] = useState(true);
  const alert = useAlert();


  useEffect(() => {
    fetch();
  }, []);

  async function fetch(){
    try {
      const scheduleds = await banking.orders.scheduled();
      setScheduledList(scheduleds);
      setLoading(false);
    } catch (error) {
      showError(alert, error)
    }
  };

  async function handleCancel(id: string){
    alert({
      title: "Cancelar ordem",
      message: "Tem certeza que deseja cancelar esta ordem?",
      confirmText: "Confirmar",
      onConfirmClick: () => onCancel(id),
      cancelText: "Fechar",
      useLoading: true,
    });
  };

  async function onCancel(id: string){
    try {
      const response = await banking.orders.cancel(id);
      alert({
        title: "Cancelado",
        message: "Cancelamento concluído com sucesso!",
        useLoading: false,
      });
    } catch (error) {
      showError(alert, error)
    }


  };

  const ActionList = ({ item }: { item: any }) => {
    item.redirect = "/scheduled";
    return (
      <Row>
        <Button
          disabled={!!item.revoked_at}
          size="sm"
          scheme="error"
          onClick={() => handleCancel(item?.id)}
          label={"Cancelar"}
          icon={{
            size: 15,
            position: "left",
            name: "delete",
          }}
        />
      </Row>
    );
  };

  return (
    <Card headerText="Agendamentos">
      <DataTable
        columns={[
          {
            selector: (row: any) => row.scheduling_date,
            name: <HeaderIcon name="calendar" text="Data" />,
            sortable: false,
            cell: (row: any) => format(new Date(row?.scheduling_date), "dd/MM/yyyy"),
          },
          {
            selector: (row: any) => row.description,
            name: <HeaderIcon name="cached" text="Transações" />,
            wrap: true,
          },
          {
            selector: (row: any) => row?.complement,
            name: <HeaderIcon name="account" text="Destinatário" />,
            wrap: true,
          },
          {
            selector: (row: any) => currency(row?.amount),
            name: <HeaderIcon name="currency" text="Valor" />,
            right: true,
          },
          { cell: (row: any) => <ActionList item={row} />, center: true },
        ]}
        data={scheduledList || []}
        progressPending={loading}
        progressComponent={<Spinner size="md" color={color.primary} />}
        highlightOnHover
        noDataComponent="Sem agendamentos no momento!"
      />

    </Card>
  );
};

export default ListScheduledsPage;

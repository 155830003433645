import BaseModule from "./BaseModule";
import {
  PixManualPaymentType,
  PixPaymentType,
  PixSearchKeyResponse,
  QrcPaymentType,
  QrcResponseType,
  SplitType,
  UserPixKey
} from "types/pix";

class Pix extends BaseModule {
  listKeys(): Promise<UserPixKey[]> {
    return this.get("/ib/pix_keys");
  }

  createQrCode(amount: number, pix_key_id: string, description?: string) {
    return this.post("/ib/qrc", {
      amount,
      pix_key_id,
      description,
    });
  }

  addKey(data: {
    type: 'EVP',
    code: string;
    verification_code: string;
  }) {
    return this.post("/ib/pix_keys", data);
  }

  searchKey(key: string): Promise<PixSearchKeyResponse> {
    return this.post("/ib/pix/search_key", {
      key: key,
    });
  }

  deleteKey(id: number, verification_code: string) {
    return this.post(`/ib/pix_keys/${id}`, {
      verification_code: verification_code,
    });
  }

  getQrcData(encoded_value: string): Promise<QrcResponseType> {
    return this.post("/ib/pix/qrc_data", { encoded_value });
  }

  performPayment(data: PixPaymentType) {
    return this.post("/ib/pix/perform_payment", data);
  }

  performManualPayment(data: Omit<PixManualPaymentType, 'validation_code'>) {
    return this.post("/ib/pix/perform_manual_payment", data);
  }

  performQrcPayment(data: QrcPaymentType) {
    return this.post("/ib/pix/perform_qrc_payment", data);
  }

  listSplits(): Promise<SplitType[]> {
    return this.get("/ib/split_rules");
  }

  createSplits(data: {
    recipient_account_branch: number;
    recipient_account_number: number;
    amount_type: 'PERCENTAGE' | 'FIXED';
    amount: number;
    max_amount_limit: number;
    expiration_date: Date;
    verification_code: string;
  }): Promise<SplitType[]> {
    return this.post(`/ib/split_rules`, data);
  }

  revokeSplits(id: number, verification_code: string) {
    return this.post(`/ib/split_rule/${id}`, {
      verification_code,
    });
  }
}

export default Pix;

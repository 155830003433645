import { Action, Reducer } from '@reduxjs/toolkit';
import { capitalize } from 'helpers/text';

const createReducer = (prefix: string, initial_state: { [key: string]: any }): [Reducer, { [type: string]: Action } | any] => {
  let methods: { [key: string]: any } = {};
  let actions: { [key: string]: any } = {};

  for (let attribute in initial_state) {
    let typeDecorator = `${prefix}_set_${attribute}`.toUpperCase();
    let actionDecorator = `set${capitalize(attribute)}`;

    methods[typeDecorator] = (state: any, action: { payload: any }) => {
      return { ...state, [attribute]: action.payload };
    };

    methods[`${prefix}_reset`] = () => {
      return { ...initial_state }
    }

    actions[actionDecorator] = (value: any) => {
      return { type: typeDecorator, payload: value };
    };

    actions["reset"] = (value: any) => {
      return { type: `${prefix}_reset`, payload: value };
    };
  }

  const reducer = (state = initial_state, action: { type: any }) => {
    if (typeof methods[action.type] === 'function') {
      return methods[action.type](state, action);
    }
    return state;
  };

  return [reducer, actions];
};

export default createReducer;

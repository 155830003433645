import React, { useRef, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Card,
  Flex,
  Spinner,
  Text
} from "components";
import {
  color,
  size
} from "theme";
import { currency } from "helpers/number";
import { format, parseISO } from "date-fns";
import styled from "styled-components";

export interface ChartProps {
  date: string;
  in: number;
  out: number;
}

interface Props {
  loading?: true;
  message?: string;
  data?: ChartProps[];
}

const Movements = (props: Props) => {
  const fakeData = useRef(fakeChart());

  return (
    <Card maxHeight={350} minHeight={300} css="position: relative;">
      <Text align="center">Movimentações</Text>

      {props.data && props.data.length ?
        <Flex center flex>
          <Chart data={props.data} />
        </Flex>
        :
        <>
          <Label>
            <Flex center gap={10} direction="row" bgColor={color.surface} padding={20} borderRadius shadow>
              {!props.data &&
                <Spinner color={color.primary} />
              }
              <Text>
                {props.data ?
                  'Não há movimentações a serem mostradas'
                  :
                  (props.message || 'Estamos processando suas informações')
                }
              </Text>
            </Flex>
          </Label>

          <BlurEffect center maxHeight={350} minHeight={300}>
            <Chart data={fakeData.current} />
          </BlurEffect>
        </>
      }
    </Card>
  );
};

const Chart: React.FC<{ data: ChartProps[] }> = (props) => {
  const [maxLength, setMaxLength] = useState(5);

  function formatLabel(v: number) {
    if (String(v).length > maxLength) {
      setMaxLength(String(v).length);
    }
    return currency(v, { digits: v > 999 ? 0 : 2 })
  }

  return (
    <ResponsiveContainer width="92%" height="100%">
      <BarChart data={props.data} margin={{ top: 20, right: 20, left: 0, bottom: 0 }}>
        <CartesianGrid
          stroke={color.border}
          strokeDasharray="3 3"
        />
        <XAxis
          dataKey="date"
          tickFormatter={(v: string) => {
            return format(parseISO(v), 'dd-MM')
          }}
          fontSize={'.8rem'}
        />
        <YAxis
          axisLine
          tickFormatter={(v) => currency(v, { digits: v > 999 ? 0 : 2, prefix: 'R$ ' })}
          fontSize={'.8rem'}
          width={maxLength * 14 + 10}
        />
        <Tooltip
          cursor={{ fill: color.border + 50 }}
          content={<CustomTooltip active />}
        />
        <Legend iconType="circle" />
        <Bar
          name={"Entradas"}
          dataKey="in"
          fill={color.success_terciary}
          radius={size.border_radius_number}
          minPointSize={2}
        >
          <LabelList
            dataKey="in"
            position="top"
            angle={0}
            offset={4}
            fill={color.primary}
            formatter={formatLabel}
            fontSizeAdjust={'auto'}
            fontSize={'.8rem'}
          />
        </Bar>
        <Bar
          name={"Saídas"}
          dataKey="out"
          minPointSize={2}
          fill={color.error_terciary}
          radius={size.border_radius_number}
        >
          <LabelList
            dataKey="out"
            position="top"
            angle={0}
            offset={4}
            fill={color.primary}
            formatter={formatLabel}
            fontSize={'.8rem'}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

function rand(valorMinimo = 1, valorMaximo = 10) {
  return Math.floor(Math.random() * (valorMaximo - valorMinimo + 1)) + valorMinimo;
}

function fakeChart() {
  return Array.from({ length: 15 }).map(() => ({
    date: '2023-10-04',
    in: rand(),
    out: rand(),
  }))
}

const CustomTooltip = (props: {
  payload?: any;
  label?: any;
  active?: boolean;
}) => {
  if (props.active && props.payload) {
    return (
      <Container shadow bgColor="#FFFFFF">
        <Flex padding={10}>
          <Text size={"caption"}>
            {props.label}
          </Text>
          <Text size={"caption"}>
            Entradas: {currency(props.payload[0].value, { prefix: 'R$ ' })}
          </Text>
          <Text size={"caption"}>
            Saídas: {currency(props.payload[1].value, { prefix: 'R$ ' })}
          </Text>
        </Flex>
      </Container>
    );
  }
  return null;
};

const Container = styled(Flex)`
  @media only screen and (min-width: 320px) {
    margin: 10px 10px 0 10px;
  }
  @media only screen and (min-width: 768px) {
    margin: 20px 20px 0 20px;
  }
  @media only screen and (min-width: 1024px) {
    margin: 40px 40px 0 40px;
  }
`;

const BlurEffect = styled(Flex)`
  filter: blur(4px);
`

const Label = styled(Flex)`
  position: absolute;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  z-index: 1;
`

export default Movements;

import React, { useEffect, useState } from "react";
import Card, { CardHeader } from "components/layout/Card";
import Flex from "components/layout/Flex";
import styled from "styled-components";
import Text from "../Text";
import Modal from "./Modal";
import OTPInput from "react-otp-input";
import Button from "components/Button";
import { VerificationCodeType, VerificationCodeViaType } from "types/account";
import { color } from "theme";
import banking from "api";

export interface CodeProps {
  title?: string;
  message?: string;
  length?: number;
  loading?: boolean;
  onChange?: (code: string) => void;
  onConfirm?: (code: string) => void;
  onRequesClose?: () => void;
  type?: VerificationCodeViaType | null;
  operation?: VerificationCodeType;
  visible?: boolean;

}

const Code: React.FC<CodeProps> = (props) => {
  const length = props.length || 6;
  const [code, setCode] = useState<string>('');
  const [loading, setLoading] = useState(false);

  async function requestCode() {
    setLoading(true);
    try {
      await banking.customer.sendVerificationCode(props.type!, props.operation!)
    } catch (error) { }
    setLoading(false);
  }
  
  return (
    <Modal
      onBackdropClick={props.onRequesClose}
      visible={props.visible}
      backdropColor="rgba(0,0,0,.2)"
    >
      <Card center shadow={false}>
        <CardHeader
          title={"Código de verificação"}
        />
        <Flex center gap={20} marginBottom={10}>
          <Text align="center" size="caption">
            Digite seu token para validar a transação
          </Text>

          <OTPInput
            shouldAutoFocus
            numInputs={length}
            onChange={(_code) => setCode(_code)}
            renderSeparator={<Flex width={10} />}
            renderInput={(_props) =>
              <Input
                {..._props}
                type="number"
                onKeyDown={({ key }) => {
                  if (key === 'Enter' && !(code.length < length)) {
                    props.onConfirm?.(code!)
                  }
                }}
              />
            }
            value={code}
          />

          <Button
            disabled={code.length < length}
            width={'10rem'}
            label="Confirmar"
            loading={props.loading}
            onClick={() => props.onConfirm?.(code!)}
          />
        </Flex>

        {!!props.type &&
          <Button
            loading={loading}
            label={props.type === 'mail' ?
              'Enviar código via Email' :
              'Enviar código via SMS'
            }
            onClick={() => requestCode()}
            size="md"
            variant="link"
          />
        }
      </Card>
    </Modal>
  )
}

const Input = styled.input`
  border: 1px solid transparent;
  border-radius: 5px;
  height: 50px;
  width: 50px !important;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  caret-color: ${({ theme }) => theme.primaryColor};
  background-color: ${color.shadow};
`;

export default Code;
import React from "react";
import styled from "styled-components";
import Flex from "./layout/Flex";
import { color } from "theme";

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-size: 16px;
  color: #333;
  cursor: pointer;
`;

const RadioInput = styled.input`
  appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #777;
  border-radius: 50%;
  transition: border-color 0.3s ease;
  outline: none;
  cursor: pointer;

  &:checked {
    border-color: ${color.primary};
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      background-color: ${color.primary};
      border-radius: 50%;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const RadioLabel = styled.span`
  font-weight: 500;
`;

const RadioHelpText = styled.span`
  display: block;
  font-size: 12px;
  color: #999;
  padding: 10px;
  margin-left: 30px;
`;

type RadioOptionProps = {
  name: string;
  checked: boolean;
  value?: string | number;
  onChange?: () => void;
  disabled?: boolean;
  label: string;
  helpText?: string;
};

const RadioOption: React.FC<RadioOptionProps> = ({
  name,
  checked,
  value,
  onChange,
  disabled,
  label,
  helpText,
}) => {
  const uniqueId = `radio_${name}_${value}`;

  const handleClick = () => {
    if (!disabled) {
      onChange?.();
    }
  };

  return (
    <RadioWrapper>
      <RadioContainer onClick={handleClick}>
        <RadioInput
          id={uniqueId}
          name={name}
          type="radio"
          checked={checked}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        <label htmlFor={uniqueId}>
          <RadioLabel>{label}</RadioLabel>
        </label>
      </RadioContainer>
      {helpText && <RadioHelpText>{helpText}</RadioHelpText>}
    </RadioWrapper>
  );
};

export default RadioOption;

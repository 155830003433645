import React from "react";
import {
  Text,
  Button
} from "components";

import { appleStore, googlePlay, logoMkPay } from "assets";

const Download: React.FC<any> = ({
  onClick,
}) => {

  return (
    <div>
      <img id="mkpay_logo" src={logoMkPay} alt="MK Pay" width={130} />
      <Text size={'subtitle'} padding="20px 0px 30px 0px" weight={600}>Acesse a loja de aplicativo de seu dispositivo e faça o download do MK Pay.</Text>
      <img id="applestore-logo" src={appleStore} alt="Apple Store" height={45} />
      <img id="googleplay-logo" src={googlePlay} alt="Google Play" height={45} style={{marginLeft: '10px'}} />
      <Button
        variant="outline"
        margin={'35px 0px 0px 0px'}
        label="Continuar"
        size="lg"
        onClick={onClick}
        width={200}
      />
    </div>
  )
}

export default Download;
import banking from "api";
import {
  Button,
  Card,
  Checkbox,
  CurrencyInput,
  Flex,
  Input,
  Row,
  SchedulerPayment,
  Select,
  Textarea
} from "components";
import { format } from "date-fns";
import { useAlert, useCode } from "hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StoreState } from "store";
import { PixManualPaymentType } from "types/pix";
import showError from "helpers/showError";

const PixManualPage: React.FC = () => {
  const alert = useAlert();
  const modalCode = useCode();
  const navigate = useNavigate();
  const { user } = useSelector((state: StoreState) => state.account);

  const [disabled, setDisabled] = useState(true);
  const [fethingBanks, setFetchingBanks] = useState(true);
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const [saveFavored, setSaveFavored] = useState(true);
  const [validDate, setValidDate] = useState(false);

  const [amount, setAmount] = useState(0);
  const [bankList, setBankList] = useState<any[]>();
  const [notes, setNotes] = useState<string>();
  const [recipient, setRecipient] = useState<PixManualPaymentType['recipient']>();
  const [selectedDate, setSelectedDate] = useState<string>();

  useEffect(() => {
    (async () => {
      const response = await banking.customer.listFinancialInstitutions("PIX");
      setBankList(response);
      setFetchingBanks(false)
    })();
  }, []);

  useEffect(() => {
    setDisabled(!(
      amount > 0 &&
      (!schedule || (selectedDate && validDate)) &&
      !!recipient?.account_branch &&
      !!recipient?.account_number &&
      !!recipient?.account_type &&
      !!recipient?.bank_ispb &&
      !!recipient?.document_number &&
      !!recipient?.name
    ))
  }, [amount, recipient, schedule, validDate])

  function handleRecipient(key: keyof PixManualPaymentType['recipient'], value: any) {
    setRecipient((prev = {} as PixManualPaymentType['recipient']) => ({ ...prev, [key]: value }))
  }

  function request2FA() {
    modalCode({
      onConfirm: (code) => handleConfirm(code),
      operation: 'PIX_PAYMENT',
      type: user?.preferred_2fa_channel || undefined
    })
  }

  async function handleConfirm(verification_code: string) {
    setLoading(true);
    try {
      if (!recipient) {
        return null;
      }
      const response = await banking.pix.performManualPayment({
        amount,
        recipient,
        save_favored: saveFavored,
        scheduling_date: selectedDate || format(new Date(), "yyyy-MM-dd"),
        verification_code
      });
      navigate(`/comprovante/${response.id}`, { replace: true });

    } catch (error) {
      setLoading(false);
      showError(alert, error);
    }
  }

  return (
    <Card
      static={loading}
      title="Agência e conta"
    >
      <Flex gap>
        <Select
          label="Selecione um banco"
          labelKey="short_name"
          onSelect={(v) => handleRecipient('bank_ispb', v)}
          seach
          valueKey="ispb"
          loading={fethingBanks}
          options={bankList}
        />
        <Row gap>
          <Select
            label="Tipo de conta"
            options={[
              { label: "Conta corrente", value: "CACC" },
              { label: "Conta transacional", value: "TRAN" },
              { label: "Conta poupança", value: "SVGS" },
            ]}
            onSelect={(value) =>
              handleRecipient('account_type', value)
            }
          />
          <Input
            mask={"number"}
            placeholder="Agência"
            onChange={({ target }) =>
              handleRecipient('account_branch', target.value)
            }
          />
          <Input
            mask={"number"}
            placeholder="Conta"
            onChange={({ target }) =>
              handleRecipient('account_number', target.value)
            }
          />
        </Row>
        <Row gap>
          <Input
            name="name"
            placeholder="Nome"
            onChange={({ target }) => {
              target.value.toUpperCase();
              handleRecipient('name', target.value)
            }}
          />
          <Input
            mask={"document"}
            name="document_number"
            placeholder="CPF/CNPJ"
            onChange={({ target }) =>
              handleRecipient('document_number', target.rawValue)
            }
          />
        </Row>

        <Flex gap>
          <Textarea
            limit={250}
            value={notes || ''}
            placeholder="* Descrição (Opcional)"
            onValueChange={(text) => setNotes(text)}
          />
        </Flex>

        <SchedulerPayment
          onChange={(value) => setSchedule(value)}
          onDateChange={(date, is_future) => {
            setSelectedDate(date);
            setValidDate(is_future);
          }}
        />

        <Checkbox
          label="Salvar contato como favorito"
          checked={saveFavored}
          onChange={setSaveFavored}
        />

        <Flex>
          <CurrencyInput onChange={(value) => setAmount(value)} />
        </Flex>
      </Flex>

      <Row gap>
        <Button
          disabled={loading}
          size="lg"
          width={'10rem'}
          label={"Cancelar"}
          variant="ghost"
          onClick={() => navigate(-1)}
        />
        <Button
          loading={loading}
          disabled={disabled}
          size="lg"
          width={'10rem'}
          label={"Continuar"}
          onClick={() => request2FA()}
        />
      </Row>
    </Card>
  );
};

export default PixManualPage;

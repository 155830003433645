import { useState, useEffect } from "react";
import {
  Button,
  Card,
  Checkbox,
  Input,
  Row,
} from "components";
import {
  CredentialCreateType,
  CredentialResponseType
} from "types/credential";

interface CredentialsFormProps {
  onClose?: () => void;
  data?: CredentialResponseType;
  loading?: boolean;
  onSubmit: (data: CredentialCreateType) => void;
  visible?: boolean;
}

const CredentialForm: React.FC<CredentialsFormProps> = (props) => {
  const [name, setName] = useState<string>();
  const [ips, setIps] = useState<string>();
  const [permission, setPermission] = useState(false);

  useEffect(() => {
    if (props.data) {
      setName(props.data.name)
      setIps(props.data.allowed_ips)
      setPermission(props.data.has_write_permission)
    }
  }, [props])
  return props.visible ? (
    <Card
      modal
      headerText="Nova Credencial"
      onClose={props.onClose}
    >
      <Input
        defaultValue={name}
        placeholder="Nome para identificação"
        onChange={({ target }) => setName(target.value)}
      />

      <Input
        placeholder="IPs permitidos"
        defaultValue={ips}
        helperText="Separados por vírgula. Ex: 10.0.0.0, 10.0.0.1"
        onChange={({ target }) => setIps(target.value)}
      />

      <Checkbox
        label="Permitir Cash Outs"
        checked={permission}
        onChange={() => setPermission(!permission)}
      />

      <Row center>
        <Button
          disabled={!(name && ips)}
          label="Salvar"
          loading={props.loading}
          minWidth="8rem"
          onClick={() => props.onSubmit({
            allowed_ips: ips as string,
            name: name as string,
            has_write_permission: permission
          })}
        />
      </Row>

    </Card>
  ) : null;
};

export default CredentialForm;

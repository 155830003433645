import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Icon,
  Row,
  Text,
  HeaderFilter,
  Spinner
} from "components";
import { format } from "date-fns";
import { Icons } from "components/Icon";
import { currency } from "helpers/number";
import { color } from "theme";
import { Order } from "types/order";
import { Outlet, useNavigate } from "react-router-dom";
import { OrderParams } from "api/Orders";
import { translateOwnerType } from "helpers/text";
import DataTable from "react-data-table-component";
import banking from "api";
import showError from "helpers/showError";
import { useAlert } from "hooks";

const FeesPage: React.FC = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const [orders, setOrders] = useState<Order[]>();
  const [loading, setLoading] = useState(true);

  const [period, setPeriod] = useState(7);
  const [term, setTerm] = useState<string>();
  const [fromDate, setFromDate] = useState<string>();
  const [untilDate, setUntilDate] = useState<string>();

  useEffect(() => {
    loadData({
      days: period,
      from: fromDate,
      until: untilDate,
      term: term
    });
  }, [term, period, fromDate, untilDate]);

  async function loadData(params?: OrderParams) {
    setLoading(true);
    try {
      const response = await banking.orders.list({
        ...params,
        owner_type: 'App\\Models\\Fee'
      });
      setOrders(response);
    } catch (error) {
      showError(alert, error);
    }
    setLoading(false);
  }

  return (
    <Card position="sticky" gap={20}>
      <CardHeader justify="flex-start" title="Tarifas" />
      <HeaderFilter
        hideSearch={true}
        onInitialDateChange={(date) => setFromDate(date)}
        onFinalDateChange={(date) => setUntilDate(date)}
        onSelectPeriod={(value) => setPeriod(value)}
        onSearch={(text) => setTerm(text)}
      />
      <DataTable
        columns={[
          {
            center: true,
            selector: (item: any) => item.performed_at,
            name: <HeaderIcon name="calendar" text="Data" />,
            cell: (item: any) => format(new Date(item?.performed_at), "dd/MM/yyyy"),
          },
          {
            center: true,
            selector: (item: any) => <TransactionType type={item.type} />,
            name: <HeaderIcon name="swap" text="Movimento" />,
          },
          {
            center: true,
            selector: (item: any) => translateOwnerType(item.owner_type),
            name: <HeaderIcon name="cached" text="Transação" />,
          },
          {
            center: true,
            selector: (item: any) => item?.complement,
            name: <HeaderIcon name="account" text="Complemento" />,
          },
          {
            center: true,
            selector: (item: any) => currency(item?.amount, { prefix: 'R$ ' }),
            name: <HeaderIcon name="currency" text="Valor" />,
          }
        ]}
        onRowClicked={(item) => navigate(`/tarifas/${item.id}`)}
        data={orders || []}
        highlightOnHover
        progressComponent={<Spinner color={color.primary} />}
        progressPending={loading}
        noDataComponent="Sem tarifas no período!"
        responsive={true}
      />
      <Outlet />
    </Card>
  );
};

const HeaderIcon = ({ name, text }: { name: Icons; text: string }) => {
  return (
    <Row>
      <Icon name={name} />
      <Text padding={"0px 10px"}>{text}</Text>
    </Row>
  );
};

const TransactionType = ({ type }: { type: string }) => {
  return (
    <Row>
      <Icon size={10} name="circle" color={type == "CASH_OUT" ? color.error : color.success} />
      <Text padding={"0px 10px"} size={"caption"}>
        {type == "CASH_IN" ? "Entrada" : "Saída"}
      </Text>
    </Row>
  );
};

export default FeesPage;

import React, {
  ReactNode,
  useCallback,
  useState
} from "react";
import { BaseProps } from "./base";
import { color } from "theme";
import Flex, { FlexProps, Row } from "./Flex";
import Text, { TextProps } from "../Text";
import Icon from "components/Icon";
import Modal from "components/overlay/Modal";
import Spinner from "components/Spinner";

interface CardProps extends BaseProps, FlexProps, React.HTMLAttributes<HTMLDivElement>, Omit<HeaderProps, 'onClose'> {
  animated?: boolean;
  active?: boolean;
  actionLoading?: boolean;
  static?: boolean;
  children: ReactNode;
  headerText?: string;
  modal?: boolean;
  title?: string;
  onClose?: () => void;
  onShow?: () => void;
}

const Card: React.FC<CardProps> = (props) => {
  const [visible, setVisible] = useState(props.modal);

  function handleClose() {
    if (props.static) {
      return null;
    }
    setVisible(false)
  }

  const Content = useCallback(({ children }: { children: ReactNode }) => (
    <Flex
      bgColor={color.surface}
      borderRadius
      gap={20}
      padding={20}
      shadow
      {...(props.modal && {
        minWidth: 500,
        padding: 40,
        maxHeight: '80vh',
        overflowY: 'scroll',
        shadow: false
      })}
      {...(props.onClick && {
        cursor: 'pointer',
        hover: {
          bgColor: `${color.primary}20`
        }
      })}
      {...(props.active && {
        border: `1px solid ${color.primary}`

      })}
      {...props}
    >
      {(props.headerText || props.title) &&
        <Header
          rightButton={props.rightButton}
          text={props.headerText || props.title}
          titleSize={props.titleSize}
          titleAlign={props.modal ? 'center' :
            props.rightButton ?
              'space-between' :
              'flex-start'
          }
          {...(props.modal && {
            onClose: handleClose
          })}
        />
      }
      {children}
    </Flex>
  ), [props.active]);

  return props.modal ?
    <>
      <Modal
        animated
        visible={visible}
        backdropClickDisabled={props.static}
        onBackdropClick={handleClose}
        onCloseAnimationEnd={props.onClose}
        onShowAnimationEnd={props.onShow}
      >
        <Content>
          {props.children}
        </Content>
      </Modal>
      <Loading visible={props.actionLoading} />
    </>
    :
    <Content>
      {props.children}
    </Content>
};

interface HeaderProps {
  text?: string;
  titleAlign?: string;
  titleSize?: TextProps['size'];
  rightButton?: ReactNode;
  onClose?: () => void;
}

const Header: React.FC<HeaderProps> = (props) => (
  <>
    <Row
      align
      justify={props.titleAlign}
      width={'100%'}
    >
      <Text size={props.titleSize || "subtitle"}>
        {props.text}
      </Text>
      {!!props.rightButton &&
        props.rightButton
      }
    </Row>
    {!!props.onClose &&
      <Flex
        absolute
        cursor="pointer"
        onClick={props.onClose}
        padding={'inherit'}
        right={-10}
        top={-10}
      >
        <Icon
          color={color.primary}
          name={"close-circle"}
        />
      </Flex>
    }
  </>
)

const Loading: React.FC<{ visible?: boolean }> = (props) => (
  <Modal backdropColor="#21212190" visible={props.visible}>
    <Flex bgColor={color.surface} borderRadius padding={10} >
      <Spinner color={color.primary} size="sm" />
    </Flex>
  </Modal>
)

interface CardHeaderProps extends FlexProps, BaseProps {
  title?: string;
  titleSize?: TextProps['size'];
  titleProps?: TextProps;
  onClose?: () => void;
  rightButton?: ReactNode;
}

export const CardHeader: React.FC<CardHeaderProps> = (props) => {
  return (
    <>
      <Row
        align
        justify={!!props.rightButton ? 'space-between' : 'center'}
        width={'100%'}
        {...props}
      >
        <Text {...props.titleProps} size={props.titleSize || "subtitle"}>
          {props.title}
        </Text>
        {!!props.rightButton &&
          props.rightButton
        }
      </Row>
      {!!props.onClose &&
        <Flex
          absolute
          cursor="pointer"
          onClick={props.onClose}
          padding={'inherit'}
          right={-10}
          top={-10}
        >
          <Icon
            color={color.primary}
            name={"close-circle"}
          />
        </Flex>
      }
    </>


  )
}

export default Card;
import BaseModule from "./BaseModule";


class MKPay extends BaseModule {

  createQrCode(verification_code: string) {
    return this.post("/ib/api_credentials/mk_pay", {
      verification_code
    });
  }

}

export default MKPay;

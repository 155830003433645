import React, { useEffect } from "react";
import Flex, { FlexProps, Row } from "./layout/Flex";
import { color, fontSize } from "theme";
import Text from "./Text";
import Input from "./Input";
import { currency } from "helpers/number";
import { BaseProps } from "./layout/base";

interface CurrencyInputProps extends BaseProps, FlexProps {
  autoFocus?: boolean;
  label?: string;
  disabled?: boolean;
  onChange?: (raw_value: number, value: string) => void;
  value?: number;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  autoFocus,
  disabled,
  onChange,
  value,
  ...props
}) => {
  const [_value, setValue] = React.useState<string>();

  useEffect(() => {
    if (value) {
      setValue(currency(value));
    }
  }, [value])

  return (
    <Flex {...props}>
      {props.label &&
        <Text color={color.text_secondary}>
          {props.label}
        </Text>
      }
      <Row>
        <Text size={"headline_2"} weight={'bold'}>
          R$
        </Text>
        <Input
          autoFocus={autoFocus}
          disabled={disabled}
          mask="currency"
          containerContentStyle={{
            bgColor: 'transparent',
            width: 'auto'
          }}
          onChange={({ target }) => {
            setValue(target.value);
            onChange?.(target.rawValue, target.value);
          }}
          style={{
            fontSize: fontSize.headline_2,
            fontWeight: 'bold',
            cursor: disabled ? 'not-allowed' : 'default'
          }}
          maxLength={14}
          value={_value || '0,00'}
        />
      </Row>
    </Flex>

  );
};

export default CurrencyInput;

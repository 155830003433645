import { Order, OrderDetailedType, OrderOwnerType, Scheduled } from "types/order";
import BaseModule from "./BaseModule";

export type OrderParams = {
  from?: string;
  until?: string;
  days?: number;
  status?: 'DONE' | 'PROCESSING';
  term?: string;
  owner_type?: OrderOwnerType;
  end_to_end_id?: string;
}

class Orders extends BaseModule {
  list(params?: OrderParams): Promise<Order[]> | any {
    return this.get("/ib/orders", { params });
  }

  scheduled(): Promise<Scheduled[]> | any {
    return this.get("/ib/orders/scheduled");
  }

  details(order_id: string | number): Promise<OrderDetailedType> {
    return this.get(`/ib/orders/${order_id}/details`);
  }

  cancel(id: string) {
    return this.post(`/ib/orders/${id}/cancel`);
  }

  receiptPDF(order: string) {
    return this.get(`/ib/orders/${order}/receipt`, {
      headers: {
        Accept: "application/pdf",
      },
      responseType: "blob",
    });
  }

  receipt(order: string) {
    return this.get(`/ib/orders/${order}/receipt`);
  }

  statement() {
    return this.get("/ib/orders/statement/resume");
  }

  export() {
    return this.get("/ib/orders/statement/csv");
  }
}

export default Orders;

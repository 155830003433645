import { format, parse } from "date-fns";

export const onlyDate = (date: Date) => {
  return new Date(`${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`);
};

export const compareDates = (date1: Date, date2: Date, operator?: "=" | ">=" | "<=" | "!="): boolean => {
  const _date1 = onlyDate(date1).toISOString();
  const _date2 = onlyDate(date2).toISOString();
  switch (operator) {
    case "=":
      return _date1 === _date2;
    case "!=":
      return _date1 !== _date2;
    case ">=":
      return _date1 >= _date2;
    case "<=":
      return _date1 <= _date2;
    default:
      return _date1 === _date2;
  }
};

export const lastDays = (days: number = 7, fomatter?: (date: Date) => any, reverse?: boolean) => {
  var result = [];
  for (var i = 0; i < days; i++) {
    var d = new Date();
    d.setDate(d.getDate() - i);
    result.push(fomatter ? fomatter(d) : d);
  }
  if (reverse) {
    return result.reverse();
  }
  return result;
};

export const maskDateBR = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1");
};

export const formatDateDBtoBR = (date_db: string) => {
  return format(new Date(parse(date_db, "yyyy-MM-dd", new Date())), "dd/MM/yyyy");
};

export const formatDateBRtoDB = (date_db: string) => {
  return format(new Date(parse(date_db, "dd/MM/yyyy", new Date())), "yyyy-MM-dd");
};

export const HelpersDate = {
  onlyDate,
  compareDates,
  lastDays,
  maskDateBR,
  formatDateDBtoBR,
  formatDateBRtoDB,
};



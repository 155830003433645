import { Flex, Text } from "../components";

function NotFoundPage() {
  return (
    <Flex center minHeight>
      <Text weight={"700"} size="headline_3">404</Text>
      <Text weight={"500"}>Page not found!</Text>
    </Flex>
  )
}

export default NotFoundPage;
import MdIcon from '@mdi/react';
import {
  mdiAccount,
  mdiArrowDown,
  mdiArrowLeft,
  mdiArrowRight,
  mdiArrowUp,
  mdiApplicationCog,
  mdiBell,
  mdiBank,
  mdiCached,
  mdiCalendar,
  mdiCardAccountDetailsOutline,
  mdiCash,
  mdiCashMinus,
  mdiCashPlus,
  mdiChatProcessing,
  mdiChatOutline,
  mdiChartBoxOutline,
  mdiCheck,
  mdiCheckCircleOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiCircle,
  mdiClipboardAccount,
  mdiClipboardTextClockOutline,
  mdiClockCheck,
  mdiCloseCircle,
  mdiClose,
  mdiContentCopy,
  mdiCurrencyUsd,
  mdiDelete,
  mdiDotsHexagon,
  mdiDotsHorizontal,
  mdiFileDownload,
  mdiFileEditOutline,
  mdiFilterMenuOutline,
  mdiEmailCheckOutline,
  mdiEye,
  mdiMapMarkerAccountOutline,
  mdiEyeOff,
  mdiFaceAgent,
  mdiHandCoinOutline,
  mdiHomeOutline,
  mdiInformation,
  mdiInformationBoxOutline,
  mdiLink,
  mdiLogout,
  mdiMagnify,
  mdiMenu,
  mdiPhone,
  mdiPlusBox,
  mdiProgressQuestion,
  mdiRefresh,
  mdiReceiptTextOutline,
  mdiServer,
  mdiShieldKey,
  mdiSquare,
  mdiTextBoxSearchOutline,
  mdiThumbUp,
  mdiInstagram,
  mdiFacebook,
  mdiLinkedin,
  mdiSwapVertical,
  mdiReceiptTextMinusOutline,
  mdiKeyOutline,
  mdiKeyChain,
  mdiKeyPlus,
  mdiQrcodeScan,
  mdiListBoxOutline,
  mdiChartBoxPlusOutline
} from '@mdi/js';
import { PixSvg } from './custom';
import { IconProps } from '@mdi/react/dist/IconProps';

export type Icons =
  | 'account'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'application_processing'
  | 'bank'
  | 'bell'
  | 'cached'
  | 'calendar'
  | 'card-account'
  | 'cash'
  | 'cash-minus'
  | 'cash-plus'
  | 'chart-box-outline'
  | 'chat'
  | 'chat-processing'
  | 'chat-outline'
  | 'check'
  | 'check-circle-outline'
  | 'checkbox-circle-outline'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'circle'
  | 'clipboard-account'
  | 'clipboard-clock'
  | 'clock-check'
  | 'close'
  | 'close-circle'
  | 'content-copy'
  | 'currency'
  | 'dots-hexagon'
  | 'delete'
  | 'dots-horizontal'
  | 'email-check-outline'
  | 'eye'
  | 'eye-off'
  | 'facebook'
  | 'fee'
  | 'file-download'
  | 'file-edit-outline'
  | 'filter-outline'
  | 'hand-coin'
  | 'home'
  | 'information'
  | 'information-box-outline'
  | 'instagram'
  | 'key-chain'
  | 'key-outline'
  | 'key-plus'
  | 'link'
  | 'linkedin'
  | 'logout'
  | 'map-marker-account-outline'
  | 'menu'
  | 'plus-square'
  | 'phone'
  | 'pix'
  | 'progress-question'
  | 'receipt'
  | 'refresh'
  | 'search'
  | 'server'
  | 'square'
  | 'shield-key'
  | 'swap'
  | 'text-search'
  | 'thumb-up'
  | 'qrcode-scan'
  | 'list-box-outline'
  | 'chart-box-plus-outline'

export const IconElements: Record<Icons, any> = {
  'account': mdiAccount,
  'arrow-down': mdiArrowDown,
  'arrow-left': mdiArrowLeft,
  'arrow-right': mdiArrowRight,
  'arrow-up': mdiArrowUp,
  'application_processing': mdiApplicationCog,
  'bell': mdiBell,
  'bank': mdiBank,
  'cached': mdiCached,
  'calendar': mdiCalendar,
  'card-account': mdiCardAccountDetailsOutline,
  'cash': mdiCash,
  'cash-minus': mdiCashMinus,
  'cash-plus': mdiCashPlus,
  'chat': mdiFaceAgent,
  'chat-outline': mdiChatOutline,
  'chart-box-outline': mdiChartBoxOutline,
  'chat-processing': mdiChatProcessing,
  'check': mdiCheck,
  'checkbox-circle-outline': mdiCheckboxMarkedCircleOutline,
  'check-circle-outline': mdiCheckCircleOutline,
  'chevron-down': mdiChevronDown,
  'chevron-left': mdiChevronLeft,
  'chevron-right': mdiChevronRight,
  'chevron-up': mdiChevronUp,
  'circle': mdiCircle,
  'clipboard-account': mdiClipboardAccount,
  'clipboard-clock': mdiClipboardTextClockOutline,
  'clock-check': mdiClockCheck,
  'close': mdiClose,
  'close-circle': mdiCloseCircle,
  'content-copy': mdiContentCopy,
  'currency': mdiCurrencyUsd,
  'delete': mdiDelete,
  'dots-hexagon': mdiDotsHexagon,
  'dots-horizontal': mdiDotsHorizontal,
  'email-check-outline': mdiEmailCheckOutline,
  'eye': mdiEye,
  'eye-off': mdiEyeOff,
  'facebook': mdiFacebook,
  'fee': mdiReceiptTextMinusOutline,
  'file-download': mdiFileDownload,
  'file-edit-outline': mdiFileEditOutline,
  'filter-outline': mdiFilterMenuOutline,
  'hand-coin': mdiHandCoinOutline,
  'home': mdiHomeOutline,
  'information': mdiInformation,
  'information-box-outline': mdiInformationBoxOutline,
  'instagram': mdiInstagram,
  'key-chain': mdiKeyChain,
  'key-outline': mdiKeyOutline,
  'key-plus': mdiKeyPlus,
  'link': mdiLink,
  'linkedin': mdiLinkedin,
  'logout': mdiLogout,
  'map-marker-account-outline': mdiMapMarkerAccountOutline,
  'menu': mdiMenu,
  'phone': mdiPhone,
  'plus-square': mdiPlusBox,
  'pix': PixSvg,
  'progress-question': mdiProgressQuestion,
  'receipt': mdiReceiptTextOutline,
  'refresh': mdiRefresh,
  'search': mdiMagnify,
  'swap': mdiSwapVertical,
  'server': mdiServer,
  'square': mdiSquare,
  'shield-key': mdiShieldKey,
  'text-search': mdiTextBoxSearchOutline,
  'thumb-up': mdiThumbUp,
  'qrcode-scan': mdiQrcodeScan,
  'list-box-outline': mdiListBoxOutline,
  'chart-box-plus-outline' : mdiChartBoxPlusOutline
}

export interface Props extends Omit<IconProps, 'path'> {
  name?: Icons,
  size?: number
}

const Icon = ({ name = 'home', size = 25, ...props }: Props) => {
  const path = IconElements[name];

  const style = {
    minHeight: size,
    maxHeight: size,
    minWidth: size,
    maxWidth: size,
  }

  if (name === 'pix') {
    return (
      <PixSvg
        color={props.color || "currentcolor"}
        size={size}
        style={style}
      />
    )
  }

  return (
    <MdIcon
      {...props}
      color={props.color || "currentcolor"}
      style={style}
      path={path}
    />
  )
}

export default Icon;

import React, { ReactNode, useEffect, useState } from "react";
import Alert, { AlertProps } from "../components/overlay/Alert";

type Props = {
  state: AlertProps;
  setState: (props: AlertProps) => void;
};
const AlertContext = React.createContext<Props>({ state: {}, setState: () => {} });

export const AlertProvider = (props: { children: ReactNode }) => {
  const [alertState, setAlertState] = useState<AlertProps>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [alertState]);

  function close(callback?: () => void, useLoading = false) {
    useLoading ? setLoading(true) : setAlertState({});
    callback?.();
  }

  const alert = {
    state: alertState,
    setState: (props: AlertProps) => {
      if (!props) {
        setAlertState({});
      }

      if (props) {
        setAlertState(() => ({
          ...props,
          onCancelClick: () => {
            close(props.onCancelClick);
          },
          onConfirmClick: () => {
            close(props.onConfirmClick, props.useLoading);
          },
        }));
      }
    },
  };

  return (
    <AlertContext.Provider value={alert}>
      {!!alertState.message && <Alert {...alertState} loading={loading} />}
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertContext;

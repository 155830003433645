import { combineReducers, configureStore } from '@reduxjs/toolkit';
import accountReducer, { AccountState } from './AccountReducer';

export interface StoreState {
  account: AccountState
}

const store = configureStore({
  reducer: combineReducers({
    account: accountReducer
  })
})

export default store;
import BaseModule from "./BaseModule";
import { BillPaymentReponseType, BillPaymentType, BillResponse } from "types/bill";

class Bill extends BaseModule {
  authorize(data: {
    barcode?: string;
    digitable?: string
  }): Promise<BillResponse> {
    return this.post("/ib/bill_payment/authorize", data);
  }

  confirmPayment(data: BillPaymentType): Promise<BillPaymentReponseType> {
    return this.post("/ib/bill_payment/confirm", data);
  }
}

export default Bill;

import BaseModule from "./BaseModule";

type InternalTransferType = {
  recipient_account: string,
  amount: number,
  notes: string,
  scheduling_date: Date,
  save_favored: boolean
}

class Transfer extends BaseModule {
  searchInternalAccount(account_number: string) {
    return this.post("/ib/search_internal_account", {
      account_number
    });
  }

  registerInternalTransfer(data: InternalTransferType) {
    return this.post("/ib/internal_fund_transfers", {
      ...data
    });
  }
}

export default Transfer;
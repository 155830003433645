import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  CurrencyInput,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  SchedulerPayment,
  Text,
} from "components";
import { useNavigate } from "react-router-dom";
import { QrcResponseType } from "types/pix";
import { useAlert, useCode } from "hooks";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { format } from "date-fns";
import showError from "helpers/showError";
import banking from "api";

const PixCopyPastePage: React.FC = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const modalCode = useCode();
  const { user } = useSelector((state: StoreState) => state.account)

  const [amount, setAmount] = useState(0);
  const [inputValue, setInputValue] = useState<string>();
  const [qrcData, setQrcData] = useState<QrcResponseType>();
  const [selectedDate, setSelectedDate] = useState<string>();

  const [loading, setLoading] = useState(false);
  const [saveFavored, setSaveFavored] = useState(true);
  const [schedule, setSchedule] = useState(false);
  const [validDate, setValidDate] = useState(false)

  async function handleSubmit(input_value?: string) {
    setLoading(true);
    if (!input_value) {
      return;
    }
    try {
      let encoded_value = window.btoa(input_value);
      const response = await banking.pix.getQrcData(encoded_value);
      setQrcData(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(alert, error);
    }
  }

  function request2FA() {
    modalCode({
      onConfirm: (code) => handleConfirm(code),
      operation: 'PIX_PAYMENT',
      type: user?.preferred_2fa_channel || undefined
    })
  }

  async function handleConfirm(verification_code: string) {
    setLoading(true);
    try {
      if (!qrcData) {
        return;
      }
      const response = await banking.pix.performQrcPayment({
        amount: qrcData.amount || amount,
        scheduling_date: selectedDate || format(new Date(), "yyyy-MM-dd"),
        verification_code,
        validation_code: qrcData.validation_code,
      });
      navigate(`/comprovante/${response.id}`, { replace: true });
    } catch (error) {
      setLoading(false);
      showError(alert, error);
    }
  }

  return (
    <Card
      title="Copia e cola"
    >
      <Form
        gap
        onSubmit={(e) => {
          e.preventDefault();
          if (!!qrcData) {
            return request2FA();
          }
          handleSubmit(inputValue);
        }}
      >
        {qrcData ?
          <Flex gap>
            <Flex gap={10}>
              <Text size={"subtitle"}>
                {qrcData.key.name}
              </Text>
              <Text size={"caption"}>
                Chave: {qrcData.key.key}
              </Text>
              <Text size={"caption"}>
                Documento: {qrcData.key.document_number}
              </Text>
              <Text size={"caption"}>
                {qrcData.key.participant}
              </Text>
            </Flex>

            <Row>
              <SchedulerPayment
                onChange={(value) => setSchedule(value)}
                onDateChange={(date, is_future) => {
                  setSelectedDate(date);
                  setValidDate(is_future);
                }}
                value={selectedDate}
              />
            </Row>

            <Checkbox
              label="Salvar contato"
              name={"save_favored"}
              checked={saveFavored}
              onChange={setSaveFavored}
            />

            <CurrencyInput
              disabled={!!qrcData.amount}
              onChange={setAmount}
              value={qrcData.amount || amount}
            />
          </Flex>
          :
          <>
            <Text align={"left"}>
              Cole no campo abaixo o BRCODE para realizar o pagamento do PIX.{" "}
            </Text>
            <Input
              autoFocus
              onChange={({ target }) => setInputValue(target.value)}
              placeholder="Cole aqui o BRCODE"
              required
            />
          </>
        }
        <Row gap>
          <Button
            type="reset"
            disabled={loading}
            label="Limpar"
            onClick={() => {
              setInputValue(undefined);
              setQrcData(undefined);
            }}
            variant="ghost"
            width="10rem"
          />
          <Button
            type="submit"
            disabled={!inputValue || (schedule && !validDate)}
            loading={loading}
            label={!!qrcData ? "Continuar" : "Buscar"}
            width="10rem"
          />
        </Row>
      </Form>
    </Card>
  );
};

export default PixCopyPastePage;

import React, { useEffect, useState } from "react";
import RadioOption from "./RadioOption";
import Input from "./Input";
import Flex, { Row } from "./layout/Flex";
import { isFuture, parse } from "date-fns";

type SchedulerPaymentProps = {
  onPaymentOptionChange?: (option: string) => void;
  onChange?: (schedule: boolean) => void;
  onDateChange: (date: string, is_future: boolean) => void;
  value?: string;
};

const SchedulerPayment: React.FC<SchedulerPaymentProps> = (props) => {
  const [date, setDate] = useState<string>();
  const [error, setError] = useState<string>();
  const [schedule, setSchedule] = useState(false);

  useEffect(() => {
    if (!schedule) {
      setDate(undefined);
      setError(undefined);
    }
    props.onChange?.(schedule);
  }, [schedule])

  function handleDate(selected_date: string) {
    if (!selected_date) {
      return setSchedule(false);
    }
    const is_future = isFuture(parse(selected_date, "yyyy-MM-dd", new Date()));
    setError(is_future ? '' : 'Data inválida');
    setDate(selected_date);
    props.onDateChange(selected_date, is_future);
  }

  return (
    <Row gap={20} height={48}>
      <RadioOption
        name="paymentOption"
        checked={!schedule}
        value="now"
        onChange={() => setSchedule(false)}
        label="Pagar hoje"
      />
      <RadioOption
        name="paymentOption"
        checked={schedule}
        value="scheduled"
        onChange={() => setSchedule(true)}
        label="Agendar"
      />
      {schedule && (
        <Flex align="flex-end" height={48}>
          <Input
            onChange={({ target }) => handleDate(target.value)}
            type="date"
            error={!!error}
            helperText={error}
            value={date}
          />
        </Flex>
      )}
    </Row>
  );
};

export default SchedulerPayment;

import React, { useState } from "react";
import {
  Flex,
  Icon,
  Input,
  Row,
  Text
} from "components";
import { FavoredKeyType } from "types/pix";
import { filter } from "helpers/text";
import { color } from "theme";
import { maskDocument, maskPhone } from "helpers/mask";

interface FavoredsProps {
  data?: FavoredKeyType[];
  onSelect?: (item: FavoredKeyType) => void;
  onDelete?: (item: FavoredKeyType) => void;
}

const Favoreds: React.FC<FavoredsProps> = (props) => {
  const [list, setList] = useState<FavoredKeyType[]>(props.data || []);

  function handleSearch(term: string) {
    const filtered = filter(term, props.data || [], 'name');
    setList(filtered);
  }
  function formatKey(key: string, type: string) {
    switch (type) {
      case 'CPF':
        return maskDocument(key)
        break;
      case 'CNPJ':
        return maskDocument(key)
        break;
      case 'PHONE':
        //remove +55 on string
        return maskPhone(key.slice(3))
        break;
      default:
        return key
    }
  }

  return (list.length > 0 ?
    <Flex gap={5}>
      <Text
        color={color.text_secondary}
        marginLeft={10}
        size="caption"
      >
        Meus Favoritos
      </Text>
      <Input
        autoFocus
        placeholder="Pesquisar"
        onChange={({ target }) => handleSearch(target.value)}
      />
      <Flex maxHeight={220} scrollY>
        {list?.map((item, i) =>
          <Row key={i} padding={10} borderBottom >
            <Flex
              flex
              cursor="pointer"
              onClick={() => props.onSelect?.(item)}
            >
              <Text>{item.name}</Text>
              <Row gap={5}>
                <Text color={color.text_secondary} size="caption">
                  {item.addressing_key_type}:
                </Text>
                <Text color={color.text_secondary} size="caption">
                  {formatKey(item.addressing_key, item.addressing_key_type)}
                </Text>
              </Row>
            </Flex>

            <Flex cursor="pointer" onClick={() => props.onDelete?.(item)}>
              <Icon name="delete" color={color.error} />
            </Flex>
          </Row>
        )}
      </Flex>
    </Flex>
    :
    <Flex flex center>
      <Text>Não há favoritos</Text>
    </Flex>
  )
}

export default Favoreds;
import {
  useEffect,
  useState
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  Flex,
  Icon,
  Row,
  Spinner,
  Text
} from "components";
import {
  Outlet,
  useNavigate,
  useOutletContext
} from "react-router-dom";
import { StoreState } from "store";
import { useAlert } from "hooks";
import { color } from "../theme";
import { format } from "date-fns";
import { accountActions } from "store/AccountReducer";
import NavBar from "./NavBar";
import Amounts from "./Amounts";
import SelectAccount from "./SelectAccount";
import styled from "styled-components";
import banking from "api";
import showError from "helpers/showError";

type NavPropsType = {
  padding?: number | string;
  showAmounts?: boolean;
  showHeader?: boolean;
  showNavBar?: boolean;
  overflow?: string;
}

const defaultNavProps: NavPropsType = {
  overflow: 'scroll',
  padding: 20,
  showAmounts: true,
  showHeader: true,
  showNavBar: true,
}

export function useNavigationProps() {
  return useOutletContext<{ setNavigationProps: (params: NavPropsType) => void }>();
}

function Navigation(props: { pathname: string, setParentLoading?: (value: boolean) => void }) {
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [navProps, setNavProps] = useState<NavPropsType>(defaultNavProps);
  const [loading, setLoading] = useState(true);
  const [amounts, setAmounts] = useState({ received: 0, sent: 0 });
  const [isNavbarVisible, setNavbarVisible] = useState(true);

  const {
    authenticated,
    account,
    account_number,
    accounts
  } = useSelector((state: StoreState) => state.account);

  useEffect(() => {
    setNavProps(defaultNavProps);
  }, [props.pathname])

  useEffect(() => {
    banking.onUnauthenticated = ({ data }) => {
      if (!authenticated) {
        showAlert({
          title: 'Unauthenticated',
          message: data.message ?? "Sua sessão expirou",
        });
      }
      dispatch(accountActions.reset());
      navigate("/login", { replace: true });
    };
  }, []);

  useEffect(() => {
    loadData(account_number);
  }, [account_number]);

  async function loadData(account?: number) {
    setLoading(true);
    props.setParentLoading?.(true);
    try {
      if (account) {
        banking.account_number = account;
      }
      const { preferred_2fa_channel, owner } = await banking.auth.user();

      dispatch(accountActions.setUser({
        ...owner,
        preferred_2fa_channel
      }));

      dispatch(accountActions.setAccounts(owner.accounts));

      const accountNumber = window.localStorage.getItem("account_number");

      const accountExists = owner.accounts.find((account) => Number(account.number) === Number(accountNumber));

      const selectedAccountNumber = accountExists ? Number(accountNumber) : owner.accounts[0].number;

      dispatch(accountActions.setAccountNumber(Number(selectedAccountNumber)));

      banking.account_number = selectedAccountNumber as number;

      const response = await banking.account.data();
      dispatch(accountActions.setAccount(response));

      await getCurrentAmounts();

      setLoading(false);
    } catch (error) {
      showError(showAlert, error);
    }
    props.setParentLoading?.(false);
  }

  async function getCurrentAmounts() {
    try {
      const today = format(new Date(), 'yyyy-MM-dd');
      const stats = await banking.account.stats();
      if (typeof stats.movements !== 'object') {
        return null;
      }
      const received = stats.movements?.[today]?.CASH_IN?.total_amount || 0;
      const sent = stats.movements?.[today]?.CASH_OUT?.total_amount || 0;
      setAmounts({ received, sent })
    } catch (error) { }
  }

  const toggleSidebar = () => {
    setNavbarVisible(!isNavbarVisible);
  };

  return (loading ?
    <Flex center height={"100vh"}>
      <Spinner color={color.brand} size="md" />
    </Flex>
    :
    <Flex direction="row" height={'100vh'} overflow="hidden">
      {isNavbarVisible &&
        <NavBar pathname={props.pathname} />
      }
      <Flex flex bgColor={color.surface} overflow="scroll-y">
        {navProps.showHeader &&
          <Flex borderBottom gap={20} padding={20}>
            <Row justify="space-between">
              <IconMenu onClick={toggleSidebar} margin={"0 20px 0 0"}>
                <Icon name="menu" size={24} />
              </IconMenu>
              <TextMenu size="headline">{document.title}</TextMenu>
              <Row>
                <SelectAccount
                  account={account}
                  account_number={account_number}
                  accounts={accounts}
                />
              </Row>
            </Row>
            {navProps.showAmounts &&
              <Amounts
                balance={account?.available_balance || 0}
                received={amounts?.received || 0}
                sent={amounts?.sent || 0}
              />
            }
          </Flex>
        }
        <Flex flex padding={navProps.padding} scrollY>
          <Outlet
            context={{
              setNavigationProps: (params: any) => setNavProps((current) => ({ ...current, ...params }))
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

const Notification = () => (
  <NotificationContainer>
    <CounterNotification />
    <Icon name="bell" size={14} />
  </NotificationContainer>
);

const NotificationContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color.text_primary + "20"};
  border-radius: 50%;
  color: ${color.text_secondary};
  height: 38px;
  width: 38px;
  margin-right: 12px;
`;

const CounterNotification = styled.div`
  position: absolute;
  border-radius: 50%;
  top: 4px;
  right: 2px;
  height: 6px;
  width: 6px;
  background-color: ${color.error};
`;

const MenuContainer = styled(Row)`
  padding: 20px;
  justify-content: space-between;
  border-bottom: 1px solid ${color.border};

  @media only screen and (max-width: 568px) {
    align-items: flex-start;
  }
`;

const IconMenu = styled(Flex)`
  @media only screen and (min-width: 568px) {
    display: none;
    padding-bottom: 20px;
  }
`;
const TextMenu = styled(Text)`
  @media only screen and (max-width: 568px) {
    display: none;
  }
`;

export default Navigation;

import React, { useEffect, useState } from "react";
import {
  Button,
  Icon,
  Row,
  Text,
  Spinner,
  Card,
  CardHeader
} from "components";
import {
  useCode,
  useAlert
} from "hooks";
import { color } from "theme";
import { Icons } from "components/Icon";
import { StoreState } from "store";
import { useSelector } from "react-redux";
import { WeekhookType } from "types/webhook";
import AddWeebhook from "./AddWeebhook";
import showError from "helpers/showError";
import banking from "api";
import DataTable from "react-data-table-component";

interface WebhooksProps {
  id?: number;
  url?: string;
  type?: string;
  secret?: string;
  actions?: any;
  revoked_at?: any;
}

const HeaderIcon = ({ name, text }: { name: Icons; text: string }) => {
  return (
    <Row>
      <Icon name={name} />
      <Text padding={"0px 10px"}>{text}</Text>
    </Row>
  );
};

const WebhooksPage: React.FC = () => {
  const alert = useAlert();
  const modalCode = useCode();
  const { user } = useSelector((state: StoreState) => state.account);

  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [webhooks, setWeekhooks] = useState<WeekhookType[]>();

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    setLoading(true);
    try {
      const response = await banking.webhooks.list();
      setWeekhooks(response);
    } catch (error) { }
    setLoading(false);
  }

  async function handleSave(verification_code: string, data: any) {
    setCreating(true);
    try {
      await banking.webhooks.create({
        ...data,
        verification_code
      })
      loadData();
      setVisible(false);
    } catch (error) {
      showError(alert, error);
    }
    setCreating(false);
  }

  async function handleDelete(verification_code: string, id: number) {
    setDeleting(true);
    try {
      await banking.webhooks.remove(id, verification_code);
      loadData();
    } catch (error) { }
    setDeleting(false);
  }

  return (
    <Card gap={20}>
      <CardHeader
        title="Lista de webhooks"
        rightButton={<Button
          size="lg"
          label="Adicionar novo webhook"
          onClick={() => setVisible(true)}
        />}
      />
      <DataTable
        columns={[
          {
            selector: (row: any) => row.url,
            name: <HeaderIcon name="link" text="URL" />,
            sortable: true,
          },
          {
            selector: (row: any) => row.type,
            name: <HeaderIcon name="check-circle-outline" text="Tipo" />,
            wrap: true,
          },
          {
            cell: (item: WebhooksProps) => (
              <Row>
                <Button
                  disabled={!!item.revoked_at}
                  icon={{ size: 15, position: "left", name: "close" }}
                  size="sm"
                  scheme="error"
                  label={"Remover"}
                  onClick={() =>
                    modalCode({
                      onConfirm: (code) => handleDelete(code, item.id as number),
                      operation: 'DELETE_WEBHOOK',
                      type: user?.preferred_2fa_channel
                    })
                  }
                />
              </Row>
            ),
            wrap: true,
            center: true,
          }
        ]}
        data={webhooks || []}
        progressPending={loading}
        progressComponent={<Spinner color={color.primary} />}
        highlightOnHover
        noDataComponent="Sem webhooks no momento!"
      />

      <AddWeebhook
        loading={creating}
        onConfirm={(data) =>
          modalCode({
            onConfirm: (code) => handleSave(code, data),
            operation: 'WEBHOOK_REGISTRATION',
            type: user?.preferred_2fa_channel
          })
        }
        onRequestClose={() => setVisible(false)}
        visible={visible}
      />
    </Card>
  );
};

const ActionList = ({ item }: { item: any }) => {
  return (
    <Row>
      {/* <Button
        disabled={!!item.revoked_at}
        size="sm"
        scheme="secondary"
        onClick={() => onEdit(item)}
        icon={{
          size: 15,
          position: "left",
          size: "file-edit-outline",
        }}
      /> */}
      <Button
        disabled={!!item.revoked_at}
        icon={{ size: 15, position: "left", name: "close" }}
        size="sm"
        scheme="error"
        label={"Remover"}
      />
    </Row>
  );
};

export default WebhooksPage;

import {
  useEffect,
  useState
} from "react";
import {
  Card,
  CardHeader,
  Flex,
  Row,
  Spinner,
  Text
} from "components";
import {
  PlanFeeType,
  PlanRuleType
} from "types/account";
import { translateFeeRegime, translateOwnerType } from "helpers/text";
import { currency } from "helpers/number";
import { color } from "theme";
import banking from "api";

const ListLimitsPage = () => {
  const [loading, setLoading] = useState(true);
  const [limits, setLimits] = useState<PlanRuleType[]>();
  const [fees, setFees] = useState<PlanFeeType[]>();

  useEffect(() => {
    loadLimits();
  }, []);

  async function loadLimits() {
    try {
      const { plan_rules, plan_fees } = await banking.account.plan();
      setLimits(plan_rules);
      if (plan_fees) {
        setFees(plan_fees);
      }
    } catch (error) { }
    setLoading(false);
  }

  return (
    <Flex gap={20}>
      <Card gap>
        <CardHeader
          justify="flex-start"
          title="Limites da conta"
        />

        <Flex gap>
          {loading ?
            <Flex center>
              <Spinner color={color.primary} />
            </Flex>
            :
            (!!limits && limits.length > 0) ?
              limits.map((item: any) => (
                <Flex key={item.id} borderBottom>
                  <Text size={"body"}>
                    {item.cash_entry_type_name}
                  </Text>

                  <Row justify="space-between" padding={10}>
                    <Text size={"caption"}>{item.regime_name}</Text>
                    <Text>{currency(item.amount, { prefix: "R$ " })}</Text>
                  </Row>
                </Flex>
              ))
              :
              <Text>Sem informações dos limites no momento</Text>
          }
        </Flex>
      </Card>

      <Card gap>
        <CardHeader
          justify="flex-start"
          title="Tarifas da conta"
        />

        <Flex gap>
          {loading ?
            <Flex center>
              <Spinner color={color.primary} />
            </Flex>
            :
            (!!fees && fees.length > 0) ?
              fees.map((item) => (
                <Flex key={item.id} borderBottom>
                  <Text size={"body"}>
                    {translateOwnerType(item.cash_entry_type)}
                  </Text>

                  <Row justify="space-between" padding={10}>
                    <Text size={"caption"}>{translateFeeRegime(item.regime)}</Text>
                    <Text>{currency(item.amount, { prefix: "R$ " })}</Text>
                  </Row>
                </Flex>
              ))
              :
              <Text>Sem informações das tarifas no momento</Text>
          }
        </Flex>
      </Card>
    </Flex>

  );
};

export default ListLimitsPage;

import { Button, Row } from "components"

export type KeyType = 'favoreds' | 'document' | 'phone' | 'mail' | 'evp';

const SelectKeyType: React.FC<{
  onChange: (type: KeyType) => void;
  value?: KeyType;
}> = (props) => {
  return (
    <Row gap={10}>
      <Button
        onClick={() => props.onChange('favoreds')}
        label="Favoritos"
        variant={props.value === 'favoreds' ? 'solid' : 'ghost'}
        size="md"
        width="8rem"
      />
      <Button
        onClick={() => props.onChange('document')}
        label="CPF/CNPJ"
        size="md"
        variant={props.value === 'document' ? 'solid' : 'ghost'}
        width="8rem"
      />
      <Button
        onClick={() => props.onChange('phone')}
        label="Telefone"
        size="md"
        variant={props.value === 'phone' ? 'solid' : 'ghost'}
        width="8rem"
      />
      <Button
        onClick={() => props.onChange('mail')}
        label="Email"
        size="md"
        variant={props.value === 'mail' ? 'solid' : 'ghost'}
        width="8rem"
      />
      <Button
        onClick={() => props.onChange('evp')}
        label="Aleatória"
        size="md"
        variant={props.value === 'evp' ? 'solid' : 'ghost'}
        width="8rem"
      />
    </Row>
  )
}

export default SelectKeyType;
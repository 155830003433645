import {
  useEffect,
  useState
} from "react";
import {
  Button,
  Card,
  Flex,
  Input,
  Row,
  Spinner
} from "components";
import {
  useAlert,
  useCode
} from "hooks";
import {
  FavoredKeyType,
  PixPaymentType,
  PixSearchKeyResponse
} from "types/pix";
import {
  validateCpfCnpj,
  validateEmail,
  validatePhone
} from "helpers/validators";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { color } from "theme";
import showError from "helpers/showError";
import PixKeyData from "./KeyData";
import SelectKeyType, { KeyType } from "./SelectKeyType";
import Favoreds from "./Favoreds";
import banking from "api";
import { removeMask } from "helpers/mask";
import { onlyNumbers } from "helpers/number";

const placeholder: Record<KeyType, string> = {
  favoreds: '',
  document: 'Digite o CPF ou CNPJ',
  phone: 'Digite o número do telefone',
  mail: 'Digite o e-mail',
  evp: 'Digite a chave'
}

function validateKey(type?: string, key?: string): boolean {
  if (!key) {
    return false;
  }
  if (type === 'document') {
    return validateCpfCnpj(key);
  }
  if (type === 'phone') {
    return validatePhone(key);
  }
  if (type === 'mail') {
    return validateEmail(key);
  }
  if (type === 'evp') {
    return key.length === 36;
  }
  return false;
}

const PixPayPage = () => {
  const alert = useAlert();
  const modalCode = useCode();
  const navigate = useNavigate();

  const { user } = useSelector((state: StoreState) => state.account);

  const [favoreds, setFavoreds] = useState<FavoredKeyType[]>();
  const [keyData, setKeyData] = useState<PixSearchKeyResponse>();
  const [fetchinfFavoreds, setFetchingFavoreds] = useState(true);
  const [fetchingKey, setFetchingKey] = useState(false);
  const [performing, setPerforming] = useState(false);

  const [keyType, setKeyType] = useState<KeyType>('favoreds');
  const [inputValue, setInputValue] = useState<string>();
  const [isValid, setIsValid] = useState(false);

  async function fetchFavoreds() {
    setFetchingFavoreds(true);
    try {
      const response = await banking.account.favoreds();
      if (response && response.length > 0) {
        setFetchingFavoreds(false);
        return setFavoreds(response);
      }
      setKeyType("document");
    } catch (error) { }
    setFetchingFavoreds(false);
  }

  function changeType(type: KeyType) {
    if (type !== keyType) {
      setInputValue('');
    }
    setKeyType(type);
    setKeyData(undefined)
  }
  useEffect(() => {
    fetchFavoreds()
  }, [])
  useEffect(() => {
    setIsValid(validateKey(keyType, inputValue));
  }, [keyType, inputValue]);

  async function handleSearch(value?: string) {
    setFetchingKey(true);
    try {
      if (value) {
        let key = value;

        if (keyType === "phone") {
          key = '55' + onlyNumbers(key);
        }

        if (keyType === "document") {
          key = onlyNumbers(key);
        }

        const response = await banking.pix.searchKey(key);
        setKeyData(response);
      }
    } catch (error) {
      showError(alert, error)
    }
    setFetchingKey(false);
  }

  function request2FA(data: any) {
    modalCode({
      onConfirm: (verification_code) => handleConfirm({ ...data, verification_code }),
      operation: 'PIX_PAYMENT',
      type: user?.preferred_2fa_channel || undefined
    })
  }

  async function handleConfirm(data: PixPaymentType) {
    setPerforming(true);
    try {
      const response = await banking.pix.performPayment(data);
      navigate(`/comprovante/${response.id}`, { replace: true });
    } catch (error) {
      showError(alert, error);
    }
    setPerforming(false);
  }

  function handleDelete(id: number) {
    alert({
      title: "Excluir favorito",
      message: `Tem certeza que deseja excluir o favorito?`,
      position: "center",
      onConfirmClick: () => onDelete(id),
      confirmText: "Excluir",
      onCancelClick: () => { },
      cancelText: "Cancelar",
    });
  }

  async function onDelete(id: number) {
    try {
      await banking.account.removeFavored(id);
      fetchFavoreds();
    } catch (error) {
      showError(alert, error);
    }
  };

  return (
    <Card
      minHeight={150}
      static={performing}
      title="Pagar com chave"
    >
      <SelectKeyType
        onChange={(type: KeyType) => changeType(type)}
        value={keyType}
      />

      {keyData ?
        <PixKeyData
          keyData={keyData}
          loading={performing}
          onCancel={() => setKeyData(undefined)}
          onConfirm={(data) => request2FA(data)}
        />
        :
        <>
          {(fetchinfFavoreds || (fetchingKey && !keyType)) ?
            <Flex flex center>
              <Spinner color={color.primary} size="sm" />
            </Flex>
            :
            keyType && keyType !== 'favoreds' ?
              <>
                <Input
                  {...(keyType === 'document' && { mask: 'document' })}
                  {...(keyType === 'phone' && { mask: 'phone' })}
                  onChange={({ target }) => setInputValue(target.value)}
                  placeholder={placeholder[keyType]}
                  value={inputValue}
                />
                <Row gap>
                  <Button
                    label="Limpar"
                    variant="ghost"
                    width="10rem"
                    onClick={() => setInputValue('')}
                  />
                  <Button
                    disabled={!isValid}
                    label="Buscar"
                    loading={fetchingKey}
                    onClick={() => handleSearch(inputValue)}
                    width="10rem"
                  />
                </Row>
              </>
              :
              <Favoreds
                data={favoreds}
                onDelete={(favored) => handleDelete(favored.id)}
                onSelect={(favored) => handleSearch(favored.addressing_key)}
              />
          }
        </>
      }
    </Card>
  )
}

export default PixPayPage;
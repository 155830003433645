import { RouteType } from "routes";
import { StoreState } from "store";

export function isRouteDisabled(
  route?: RouteType,
  user?: StoreState['account']['user']
): boolean {
  if (user && route?.restrict && !user[route.restrict]) {
    return true;
  }

  if (route?.hidden) {
    return true;
  }

  return false;
}

export type RouteItem = RouteType & { children?: RouteType[] }

export type RoutesObject = {
  auth: RouteItem[],
  modals: RouteItem[],
  navigation: RouteItem[]
}

export function filterRoutes(routes: RouteType[]): RoutesObject {
  const auth: RoutesObject['auth'] = []
  const modals: RoutesObject['modals'] = [];
  const navigation: RoutesObject['navigation'] = [];
  const children_routes: string[] = [];

  routes
    .filter(({ children_id }) => typeof children_id === 'object')
    .map(({ children_id = [] }) => children_routes.push(...children_id));

  routes.map((item) => {
    if (children_routes.find((id) => id === item.id)) {
      return null;
    }

    const route: RouteItem = { ...item, children: [] };

    if (typeof item.children_id === 'object') {
      item.children_id.forEach((id) => {
        const sub_route = routes.find(elm => elm.id === id)!;

        if (!sub_route) {
          return null;
        }

        route.children?.push(sub_route);
      })
    }

    if (item.modal_path) {
      return modals.push(route);
    }

    if (item.authenticated) {
      return navigation.push(route);
    }
    return auth.push(route);
  });

  return { auth, modals, navigation }
}
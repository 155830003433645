import React, { useEffect, useState } from "react";
import {
  Button,
  Icon,
  Row,
  Text,
  Spinner,
  Card,
  CardHeader
} from "components";
import {
  useCode,
  useAlert
} from "hooks";
import { color } from "theme";
import { Icons } from "components/Icon";
import { StoreState } from "store";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import AddSplit from "./AddSplit";
import showError from "helpers/showError";
import { currency } from "helpers/number";
import banking from "api";
import DataTable from "react-data-table-component";
import { SplitType } from "types/split";

interface SplitsProps {

  id?: any;
  recipient_account_location?:any;
  amount_type?: any;
  status?: any;
  recipient_account_number?: any;
}

const HeaderIcon = ({ name, text }: { name: Icons; text: string }) => {
  return (
    <Row>
      <Icon name={name} />
      <Text padding={"0px 10px"}>{text}</Text>
    </Row>
  );
};

const SplitPage: React.FC = () => {
  const alert = useAlert();
  const modalCode = useCode();
  const { user } = useSelector((state: StoreState) => state.account);

  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [splits, setSplits] = useState<SplitType[]>();

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    setLoading(true);
    try {
      const response = await banking.split.listSplits();
      setSplits(response);
      setLoading(true);
    } catch (error) { }
    setLoading(false);
  }

  async function handleSave(verification_code: string, data: any) {
    setCreating(true);
    try {
      await banking.split.createSplits({
        ...data,
        verification_code
      })
      loadData();
      setVisible(false);
    } catch (error) {
      showError(alert, error);
    }
    setCreating(false);
  }

  async function handleDelete(verification_code: string, id: number) {
    setDeleting(true);
    try {
      await banking.split.revokeSplits(id, verification_code);
      loadData();
    } catch (error) { }
    setDeleting(false);
  }

  return (
    <Card gap={20}>
      <CardHeader
        title="Lista de splits de pagamento"
        rightButton={<Button
          size="lg"
          label="Adicionar nova regra"
          onClick={() => setVisible(true)}
        />}
      />
      <DataTable defaultSortFieldId={5}
        columns={[
          {
            selector: (row: any) => row.recipient_account_number,
            name: <HeaderIcon name="bank" text="Conta" />,
            sortable: true,
            center: true,
          },
          {
            selector: (row: any) => row.amount_type,
            cell: (row: SplitsProps) =>
              (row?.amount_type == 'FIXED') ? (
                <Text color={color.info}>Valor Fixo</Text>
              ) : (
                <Text color={color.info_secondary}>Percentual</Text>
              ),
            name: <HeaderIcon name="cached" text="Tipo" />,
            sortable: true,
            center: true,
          },
          {
            selector: (row: any) => currency(row?.amount),
            name: <HeaderIcon name="currency" text="Valor" />,
            sortable: true,
            center: true,
          },
          {
            selector: (row: any) => currency(row?.max_amount_limit, { prefix: 'R$ ' }),
            name: <HeaderIcon name="currency" text="Valor Limite" />,
            sortable: true,
            center: true,
          },
          {
            selector: (row: any) => row.recipient_account_location,
            cell: (row: SplitsProps) =>
              (row?.recipient_account_location === 'INTERNAL') ? (
                <Text color={color.info}>INTERNA</Text>
              ) : (
                <Text color={color.info_secondary}>EXTERNA</Text>
              ),
            name: <HeaderIcon name="map-marker-account-outline" text="Local" />,
            sortable: true,
            center: true,
          },
          {
            selector: (row: any) => row.status,
            cell: (row: SplitsProps) =>
              (row?.status == 'INACTIVE') ? (
                <Text color={color.error}>TERMINADO</Text>
              ) : (
                <Text color={color.success}>ATIVO</Text>
              ),
            name: <HeaderIcon name="check-circle-outline" text="Situação" />,
            sortable: true,
            center: true,
          },
          {
            selector: (row: any) => (row?.expiration_date) ? format(new Date(row.expiration_date), "dd/MM/yyyy") : "-",
            name: <HeaderIcon name="calendar" text="Valido até" />,
            sortable: true,
            center: true,
          },
          {
            cell: (item: SplitsProps) => (
              <Row>
                {item.status == "ACTIVE" && (
                <Button
                  disabled={item.status == "INACTIVE"}
                  icon={{ size: 15, position: "left", name: "close" }}
                  size="sm"
                  scheme="error"
                  label={"Terminar"}
                  onClick={() =>
                    modalCode({
                      onConfirm: (code) => handleDelete(code, item.id as number),
                      operation: 'DELETE_SPLIT_RULE',
                      type: user?.preferred_2fa_channel
                    })
                  }
                />)}
              </Row>
            ),
            wrap: true,
            center: true,
          }
        ]}
        data={splits || []}
        progressPending={loading}
        progressComponent={<Spinner color={color.primary} />}
        highlightOnHover
        noDataComponent="Sem splits de pagamento no momento!"
      />

      <AddSplit
        loading={creating}
        onConfirm={(data) =>
          modalCode({
            onConfirm: (code) => handleSave(code, data),
            operation: 'SPLIT_RULE',
            type: user?.preferred_2fa_channel
          })
        }
        onRequestClose={() => setVisible(false)}
        visible={visible}
      />
    </Card>
  );
};

export default SplitPage;

import React, {
  useEffect,
  useState
} from "react";
import {
  Button,
  Card,
  Flex,
  Row,
  Spinner,
  Text
} from "components";
import {
  useNavigate,
  useParams
} from "react-router-dom";
import { color } from "theme";
import { currency } from "helpers/number";
import { logo_horizontal } from "../../assets";
import { OrderDetailedType } from "types/order";
import { format } from "date-fns";
import banking from "api";

const ReceiptPage: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState<OrderDetailedType>();
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    if (!order) {
      fetchReceipt();
    }
  }, []);

  const fetchReceipt = async () => {
    setLoading(true);
    try {
      if (!params.id) {
        navigate(-1);
      }
      const response = await banking.orders.details(params?.id!);

      setOrder(response);
    } catch (e) {
      navigate(-1);
    }
    setLoading(false);
  };

  async function handleSavePDF() {
    setDownloading(true)
    try {
      const response = await banking.orders.receiptPDF(params?.id!);
      const url = window.URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "comprovante.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {

    }
    setDownloading(false);
  }

  return (
    <Card modal onClose={() => navigate(-1)}>
      <Flex scrollY>
        <Flex width={140} marginBottom={10}>
          <img alt="logo" src={logo_horizontal} />
        </Flex>

        {(loading || !order) ?
          <Flex center>
            <Spinner
              color={color.primary}
            />
          </Flex>
          :
          <>
            <Flex borderBottom paddingBlock={20}>
              <Text size={"headline"}>
                {order.description}
              </Text>
              <RowItem
                label="Valor"
                value={currency((order.amount), { prefix: 'R$ ' })}
              />
              <RowItem
                label="Complemento"
                value={order.complement}
              />
              {order.performed_at &&
                <RowItem
                  label="Data da transação"
                  value={format(new Date(order.performed_at), 'dd/MM/yyyy - HH:mm ')}
                />
              }

              <RowItem
                label="Autenticação interna"
                value={order.authentication_code}
              />
              {order.owner?.end_to_end_id &&
                <RowItem
                  label="End_to_end ID"
                  value={String(order.owner.end_to_end_id)}
                />
              }
              {order.owner?.transaction_id &&
                <RowItem
                  label="Identificador"
                  value={String(order.owner.transaction_id)}
                />
              }
            </Flex>

            {order.owner?.recipient_name &&
              <Flex borderBottom padding={"20px 0"}>
                <Text size="subtitle">
                  Dados do Recebedor
                </Text>
                <AccountData
                  bank={order.owner.recipient_account_bank_name || order.owner.assignor}
                  name={order.owner.recipient_name}
                  document={order.owner.recipient_document_number}
                />
              </Flex>
            }

            {order.owner.sender_name &&
              <Flex borderBottom paddingBlock={20}>
                <Text size="subtitle">
                  Dados do Pagador
                </Text>
                <AccountData
                  bank={order.owner.sender_account_bank_name}
                  name={order.owner.sender_name}
                  document={order.owner.sender_document_number}
                />
              </Flex>
            }

            {order.owner.digitable &&
              <Flex paddingBlock={20}>
                <Text size="subtitle">
                  Dados do pagamento
                </Text>
                <RowItem
                  label="Valor pago"
                  value={`R$ ${currency(order.owner.amount)}`}
                />
                <RowItem
                  label="Valor original"
                  value={`R$ ${currency(order.owner.original_amount)}`}
                />
                <RowItem
                  label="Data de vencimento"
                  value={order.owner.due_date}
                />
                <RowItem
                  label="Linha digitável"
                  value={order.owner.digitable}
                />
              </Flex>
            }
          </>
        }
      </Flex>
      <Row center gap={20} paddingTop={20} borderTop>
        <Button
          disabled={loading}
          loading={downloading}
          label="Salvar PDF"
          onClick={handleSavePDF}
          size="md"
          width={100}
        />
        <Button
          disabled={loading}
          label="Fechar"
          onClick={() => navigate(-1)}
          size="md"
          variant="ghost"
          width={100}
        />
      </Row>
    </Card>
  )
};

const RowItem: React.FC<{
  label?: string;
  value?: string;
}> = (props) => (
  <Flex marginTop={10}>
    <Text color={color.text_secondary} size={"caption"}>
      {props.label}
    </Text>
    <Text color={color.text_primary}>
      {props.value}
    </Text>
  </Flex>
)

const AccountData: React.FC<{
  name: string;
  document: string;
  bank: string;
}> = (props) => (
  <>
    <RowItem
      label="Nome"
      value={props.name}
    />
    <RowItem
      label="CPF/CNPJ"
      value={props.document}
    />
    <RowItem
      label="Instituição"
      value={props.bank}
    />
  </>
)

export default ReceiptPage;

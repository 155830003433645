import BaseModule from "./BaseModule";
import { Account, AccountStatus } from "types/account";
import { User } from "types/user";

type UserResponse = {
  name: string;
  document_number: number;
  status: AccountStatus;
  preferred_2fa_channel: null;
  is_short_password_defined: boolean;
  owner: User & {
    accounts: Account[]
  };
  card_account: null
}

class Auth extends BaseModule {
  csrfToken() {
    return this.get("/sanctum/csrf-cookie");
  }

  login(document_number: number, password: string): Promise<{ result: string }> {
    return this.post("/ib/auth", {
      document_number,
      password
    });
  }

  user(): Promise<UserResponse> {
    return this.get("/ib/user");
  }

  logout() {
    return this.post("/ib/logout");
  }
}

export default Auth;
import styled, { keyframes } from "styled-components"
import { attributeCSS } from "../helpers/text"
import { size } from "../theme"

interface SpinnerProps {
  color?: string,
  size?: 'xxs' | 'xs' | 'sm' | 'md'
}

function Spinner(props: SpinnerProps) {
  return (
    <Container
      color={props.color || "currentcolor"}
      size={size[props.size as keyof typeof size || 'xs']}
      border={`3px solid ${props.color}`}
    />
  )
}

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Container = styled.div<{
  color: string,
  size: string | number,
  border: string
}>`
  animation: ${animation} linear .7s infinite;
  ${props => `
    border: ${props.border};
    border-color: ${props.color} ${props.color} transparent;
    border-radius: 50%;
    ${attributeCSS("height", props.size)}
    ${attributeCSS("width", props.size)}
  `}
`

export default Spinner;
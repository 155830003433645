import styled from "styled-components";
import { Card, Flex, Row, Text } from "components";
import Icon, { Icons } from "../components/Icon";
import { currency } from "../helpers/number";
import { color } from "../theme";

const Amounts = (props: { balance?: number; received?: number; sent?: number }) => {
  return (
    <Row wrap gap={20}>
      <AmountCard
        amount={props.balance ?? 0}
        color={color.secondary}
        icon={"currency"}
        label="Meu saldo:"
      />
      <AmountCard
        amount={props.received ?? 0}
        color={color.success}
        icon={"arrow-down"}
        label="Recebidos hoje:"
      />
      <AmountCard
        amount={props.sent ?? 0}
        color={color.error}
        icon={"arrow-up"}
        label="Enviados hoje:"
      />
    </Row>
  );
};

const AmountCard = (props: { amount: number; icon: Icons; label: string; color: string }) => (
  <Card center direction="row" grow minWidth={300} shadow>
    <IconContainer color={props.color}>
      <Icon size={35} name={props.icon} />
    </IconContainer>

    <Flex flex>
      <Text color={color.text_secondary} size="footnote">
        {props.label}
      </Text>
      <Text weight={"bold"} color={props.color} size="headline">
        R$ {currency(props.amount)}
      </Text>
    </Flex>

    {/* <Flex>
      <Icon
        color={props.color}
        name="plus-square"
      />
    </Flex> */}
  </Card>
);

const IconContainer = styled.div<{ color: string }>`
  ${(props) => `
    display: flex;
    background-color: ${props.color + 40};
    box-shadow: 2px 5px 15px ${props.color + 80};
    border-radius: 50%;
    color: ${props.color};
    align-items: center;
    justify-content: center;
    height: 55px;
    width: 55px;
  `}
`;

export default Amounts;

import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import Text from "./Text";
import Icon from "./Icon";
import {
  currency,
  decimal,
  onlyNumbers,
  validateNumber
} from "../helpers/number";
import {
  color,
  fontSize
} from "../theme";
import Flex, { FlexProps, Row } from "./layout/Flex";
import { Icons } from "./Icon";
import { BaseProps } from "./layout/base";
import { maskCEP, maskDate, maskDocument, maskPhone } from "helpers/mask";

interface AddonObj {
  addon?: boolean;
  addonPosition?: "left" | "right";
  addonOnClick?: () => void;
  addonIcon?: Icons;
}

interface InputComponent extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  label?: string;
  helperText?: string;
  mask?: "document" | "currency" | "date_br" | "date_db" | "phone" | "cep" | "number";
  onChange?: (event: React.ChangeEvent<HTMLInputElement & { rawValue: number }>) => void;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  addon?: AddonObj;
  ref?: React.Ref<HTMLInputElement>;
  name?: string;
  lang?: string;
  required?: boolean;
  containerStyle?: BaseProps & FlexProps;
  containerContentStyle?: BaseProps & FlexProps;
}

function Input({
  error,
  label,
  mask,
  helperText,
  labelProps,
  required,
  containerStyle,
  containerContentStyle,
  ...props
}: InputComponent, ref?: React.Ref<HTMLInputElement>): ReactElement {
  const [visible, setVisible] = useState(false);

  return (
    <Container width={'100%'} {...containerStyle}>
      {label && (
        <label {...labelProps} {...(props.id && { htmlFor: props.id })}>
          {label} {required && <Text padding={'0 5px'} color={color.error}>*</Text>}
        </label>
      )}
      <Row
        height={48}
        borderRadius
        bgColor={color.shadow}
        padding={'0 10px'}
        overflow="hidden"
        {...containerContentStyle}
      >
        {props.addon?.addonPosition === "left" && (
          <Flex
            {...(props.addon.addonOnClick && { cursor: 'pointer' })}
            onClick={props.addon.addonOnClick}
            padding={10}
          >
            <Icon
              size={25}
              color={color.primary}
              name={props.addon?.addonIcon}
            />
          </Flex>
        )}
        {mask === 'phone' &&
          <text style={{ marginRight: 5 }}>+55</text>
        }
        <input
          {...props}
          {...(visible && { type: "text" })}
          {...(mask && {
            onChange: (event) => format(event, mask, props.onChange, props.value as string)
          })}
          {...(props.id && { "data-testid": props.id })}
          name={props.name}
          placeholder={props.placeholder || " "}
          lang={props.lang || "pt-BR"}
        />
        {props.addon?.addon && props.type === "password" && (
          <Flex cursor="pointer" onClick={() => setVisible(!visible)} padding={10}>
            <Icon size={25} color={color.primary} name={visible ? "eye-off" : "eye"} />
          </Flex>
        )}
        {props.addon?.addonPosition === "right" && props.type !== "password" && (
          <Flex
            {...(props.addon.addonOnClick && { cursor: 'pointer' })}
            onClick={props.addon.addonOnClick}
            padding={10}
          >
            <Icon
              size={25}
              color={color.primary}
              name={props.addon?.addonIcon as Icons}
            />
          </Flex>
        )}
      </Row>
      {helperText &&
        <Text
          color={error ? color.error : color.text_secondary}
          size={"footnote"}
          padding="2px 8px"
        >
          {helperText}
        </Text>
      }
    </Container>
  );
}

function format(event: any, mask: string, onChange?: (event: any) => void, value?: string) {
  if (mask === 'document') {
    event.target.value = maskDocument(value !== event.target.value ? event.target.value : value);
    event.target.rawValue = onlyNumbers(value !== event.target.value ? event.target.value : value);
    return onChange?.(event);
  }

  if (mask === 'currency') {
    event.target.value = currency(decimal(event.target.value));
    event.target.rawValue = decimal(event.target.value);
    return onChange?.(event);
  }

  if (mask === 'date_br') {
    event.target.value = maskDate(event.target.value);
    event.target.rawValue = maskDate(event.target.value);
    return onChange?.(event);
  }

  if (mask === 'phone') {
    event.target.value = maskPhone(event.target.value);
    event.target.rawValue = maskPhone(event.target.value);
    return onChange?.(event);
  }

  if (mask === 'cep') {
    event.target.value = maskCEP(event.target.value);
    event.target.rawValue = maskCEP(event.target.value);
    return onChange?.(event);
  }

  if (mask === 'number') {
    event.target.value = validateNumber(event.target.value);
    event.target.rawValue = validateNumber(event.target.value);
    return onChange?.(event);
  }
  return onChange?.(event);
}

const Container = styled(Flex)`
  & label {
    color: ${color.text_secondary};
    font-size: ${fontSize.caption};
    padding: 2px 8px;
    display: flex;
  }
  & div:has(input:not(:placeholder-shown):invalid) {
    border-color: ${color.error};
  }
  & input {
    border: none;
    color: ${color.text_primary};
    background: transparent;
    font-size: ${fontSize.body};
    width: 100%;
  }
  & input:focus {
    outline: none;
  }
`;

export default Input;

import { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card, Flex, Form, Input, Text } from "components";
import { logo_horizontal } from "../../assets";
import { color } from "../../theme";
import { useAlert } from "../../hooks";
import { accountActions } from "store/AccountReducer";
import { validateCpfCnpj } from "helpers/validators";
import banking from "api";
import styled from "styled-components";

function LoginPage() {
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [document, setDocument] = useState<number>();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);

  async function login(e: FormEvent) {
    if (loading) return;
    e.preventDefault();
    setLoading(true);
    try {
      await banking.auth.csrfToken();
      await banking.auth.login(document!, password);
      dispatch(accountActions.setAuthenticated(true));
      navigate("/");
    } catch (error: any) {
      alert({
        title: "Erro",
        message: error.response?.data.message || "",
      });
    }
    setLoading(false);
  }

  return (
    <Flex center height={'100vh'} overflowY="scroll">
      <Container direction="row">
        <ContainerLogo center bgColor={color.primary}>
          <img alt="logo" src={logo_horizontal} />
        </ContainerLogo>

        <ContainerForm onSubmit={login}>
          <Text align="center" size={"headline_2"}>
            Bem Vindo!
          </Text>
          <Input
            id="document"
            mask="document"
            label="CPF/CNPJ"
            onChange={({ target }) => {
              if (target.value.length > 13) {
                setError(!validateCpfCnpj(target.value));
              }
              setDocument(target.rawValue);
            }}
            error={error}
            helperText={error ? "Documento inválido" : ""}
            pattern="^(.{14}|.{18})"
            required
          />
          <Input
            id="password"
            label="Password"
            type={"password"}
            onChange={({ target }) => setPassword(target.value)}
            minLength={6}
            required
            addon={{ addon: true }}
          />
          <Button
            disabled={error || !document || !password}
            label="Login"
            loading={loading}
            width={"100%"}
            css="margin-top: 20px;"
          />
        </ContainerForm>
      </Container>
    </Flex>
  );
}

const Container = styled(Card)`
  flex-direction: row;
  padding: 0px;
  height: 650px;
  background-color: ${color.surface};
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 5px;
    width: min(100% - 40px, 540px);
    min-width: 340px;
  }
`;
const ContainerLogo = styled(Flex)`
  height: 650px;
  width: 380px;
  @media (max-width: 768px) {
    height: 100px;
    width: 100%;
    padding: 20px 0px;
  }
`;
const ContainerForm = styled(Form)`
  display: flex;
  gap: 10px;
  flex-direction: collumn;
  align-items: center;
  justify-content: center;
  height: 570px;
  width: 420px;
  padding: 40px;
  @media (max-width: 768px) {
    margin-top: 5vh;
    padding: 0px 40px;
    justify-content: flex-start;
    width: calc(100% - 80px);
  }
`;

export default LoginPage;

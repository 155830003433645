import { Button, Card, CardHeader, Input, Modal, Row, Select } from "components"
import { splitTypes } from "helpers/text";
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { log } from "console";

interface AddSplitProps {
  loading?: boolean;
  onConfirm?: (data: {
    amount_type: string;
    amount: number;
    expiration_date: string;
    max_amount_limit: number;
    recipient_account_location: string;
    recipient_account_number: string;
  }) => void;
  onRequestClose?: () => void;
  visible?: boolean;
}

const AddSplit: React.FC<AddSplitProps> = (props) => {
  const [recipient_account_number, setRecipientNumber] = useState<string>();
  const [amount_type, setType] = useState<string>();
  const [expiration_date, setExpirationDate] = useState<string | null>();
  const [amount, setAmount] = useState<number>();
  const [max_amount_limit, setMaxAmount] = useState<number>();
  const today = format(new Date(), 'yyyy-MM-dd');

  useEffect(() => {
    if (amount_type === undefined){
      setMaxAmount(0);
    }
    if (amount_type === 'FIXED') {
      setMaxAmount(0);
    }
  }, [amount_type])

  return (
    <Modal
      onBackdropClick={props.onRequestClose}
      visible={props.visible}
    >
      <Card gap={20} padding={40} width={400}>
        <CardHeader
          title="Nova regra de Split"
          onClose={props.onRequestClose}
        />
        <Select
          label="Selecione um tipo"
          onSelect={(value) => setType(value)}
          options={splitTypes}
        />
        <Input
          type="number"
          label="Conta Destinatário"
          onChange={({ target }) => setRecipientNumber(target.value)}
          helperText="Apenas contas MKBank."
          required
        />
        <Input
          label="Valor"
          mask="currency"
          onChange={({ target }) => setAmount(target.rawValue)}
          helperText="Dependendo do tipo, será interpretado como porcentagem ou valor."
          required
        />
        {
          amount_type == "PERCENTAGE" && (
            <Input
              label="Valor de Limite (opcional)"
              mask="currency"
              onChange={({ target }) => setMaxAmount(target.rawValue)}
              helperText="Um valor máximo que o destinatário poderá receber."
            />)}
        <Input
          containerStyle={{ marginBottom: 0 }}
          onChange={({ target }) => setExpirationDate(target.value)}
          type="date"
          min={today}
          label="Data Expiração (opcional)"
          helperText="Data limite em que a regra terá validade."
        />
        <Row center>
          <Button
            disabled={!(amount && amount_type)}
            label="Confirmar"
            loading={props.loading}
            onClick={() => props.onConfirm?.(
              {
                amount_type: amount_type!,
                amount: amount!,
                expiration_date: expiration_date!,
                recipient_account_location: 'INTERNAL',
                max_amount_limit: max_amount_limit!,
                recipient_account_number: recipient_account_number!,
              })}
            width={"10rem"}
          />
        </Row>
      </Card>
    </Modal>
  )
}

export default AddSplit;

import React from "react";
import { AlertProvider } from "context/AlertContext";
import { CodeProvider } from "context/CodeContext";

const Provider = (props: { children?: React.ReactNode }) => {
  return (
    <CodeProvider>
      <AlertProvider>
        <div id="comp-portal">
          <div id="comp-manager-modal"></div>
        </div>
        {props.children}
      </AlertProvider>
    </CodeProvider>
  );
};

export default Provider;

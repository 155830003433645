import { attributeCSS } from "helpers/text";
import styled from "styled-components";
import base from "./base";

interface GridCss {
  alignSelf?: string;
  center?: boolean;
  gap?: string | number,
  templateColumns?: string,
  templateRows?: string,
  gridArea?: string,
  gridCol?: string,
  gridRowGap?:string,
  gridColumnGap?:string,
  gridRow?: string,
  autoFlow?: string;
  autoColumns?: string;
  margin?:string | number;
  marginTop?:string | number;
}

const gridAttributes = (props: GridCss) => `
  display: grid;
  ${attributeCSS('align-self', props.alignSelf)}
  ${attributeCSS('grid-area', props.gridArea)}
  ${attributeCSS('grid-auto-columns', props.autoColumns)}
  ${attributeCSS('grid-auto-flow', props.autoFlow)}
  ${attributeCSS('grid-column', props.gridCol)}
  ${attributeCSS('grid-gap', props.gap)}
  ${attributeCSS('grid-row', props.gridRow)}
  ${attributeCSS('grid-row-gap', props.gridRowGap)}
  ${attributeCSS('grid-column-gap', props.gridColumnGap)}
  ${attributeCSS('grid-template-columns', props.templateColumns)}
  ${attributeCSS('grid-template-rows', props.templateRows)}
  ${props.center ? attributeCSS(['align-items', 'justify-content'], 'center') : ''}
`

const Grid = styled(base) <GridCss>`
  ${(props) => `
    ${gridAttributes(props)}
  `}
`

export const GridItem = styled(base) <GridCss>`
  ${(props) => `
    ${gridAttributes(props)}
  `}
`

export default Grid;
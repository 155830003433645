import styled from "styled-components";
import { BaseProps } from "./base";
import { flexAttributes, FlexProps } from "./Flex";

const Form = styled.form<BaseProps & FlexProps>`
  ${(props) => `
    ${flexAttributes(props)}
  `}
`

export default Form;
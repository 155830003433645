import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Icon, Row, Text } from "components";
import { StoreState } from "store";
import { color } from "theme";
import styled from "styled-components";
import { accountActions } from "store/AccountReducer";
import { Stats } from "fs";
import StatsService from "services/StatsService";

const SelectAccount = ({
  account,
  account_number,
  accounts,
}: {
  account: any;
  account_number: number;
  accounts: any;
}) => {
  const dispatch = useDispatch();
  // const { account, account_number, accounts } = useSelector((state: StoreState) => state.account);

  const listRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);

  const selectAccount = async (number: number) => {
    dispatch(accountActions.setAccountNumber(number));
    window.localStorage.setItem("account_number", number.toString());
    const stats = await StatsService.fetchStats();
    dispatch(accountActions.setStats(stats));
    setVisible(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (listRef.current && !listRef.current.contains(event.target as Node)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <StyledFlex
      borderRadius
      bgColor={color.primary}
      marginRight={20}
      position="relative"
      ref={listRef}
    >
      <Row
        onClick={() => setVisible(!visible)}
        height={38}
        padding="0px 10px"
        css="cursor: pointer;"
      >
        {accounts.length > 1 && (
          <>
            <Icon color={color.surface} name={visible ? "chevron-up" : "chevron-down"} />
            <Flex bgColor="black" height={20} width={1} margin="0px 4px" />
          </>
        )}
        <Row>
          <Text size="footnote" color={color.text_primary_contrast} marginLeft={6}>
            Conta: {("0000" + (account?.number! ?? "00000000")).slice(2)}
          </Text>
          <TextFlex size="footnote" color={color.text_primary_contrast} marginLeft={6}>
            - Ag: {account?.branch ?? "0000"}
          </TextFlex>
        </Row>
      </Row>
      {accounts.length > 1 && (
        <List visible={visible}>
          {accounts.map(
            (item: any, i: number) =>
              account_number != item.number && (
                <Flex
                  key={i}
                  center
                  borderRadius
                  bgColor={color.primary}
                  height={38}
                  marginTop={4}
                  padding="0px 12px"
                  onClick={() => selectAccount(item.number)}
                >
                  <Text color={color.text_primary_contrast} size="footnote">
                    Conta: {("0000" + item.number).slice(2)}
                  </Text>
                </Flex>

              )
          )}
        </List>
      )}
    </StyledFlex>
  );
};

const StyledFlex = styled(Flex)`
  border-radius: 4px;
  background-color: ${color.primary};
  margin-right: 20px;
  position: relative;
  /* max-width: 300px; */
`;

const List = styled(Flex)<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  position: absolute;
  top: 38px;
  width: 100%;
  z-index:2;
  & > div {
    align-self: flex-end;
    cursor: pointer;
    top: ${({ visible }) => (visible ? "0px" : "-250px")};
    width: 70%;
  }
`;

const TextFlex = styled(Text)`
  @media only screen and (max-width: 568px) {
    display: none;
  }
`;

export default SelectAccount;

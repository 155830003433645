import { Button, Card, CardHeader, Input, Modal, Row, Select } from "components"
import { TypesWebhook } from "helpers/text";
import React, { useState } from "react";

interface AddWeebhookProps {
  loading?: boolean;
  onConfirm?: (data: {
    type: string;
    url: string;
    secret?: string
  }) => void;
  onRequestClose?: () => void;
  visible?: boolean;
}

const AddWeebhook: React.FC<AddWeebhookProps> = (props) => {
  const [secret, setSecret] = useState<string>();
  const [url, setUrl] = useState<string>();
  const [type, setType] = useState<string>();

  return (
    <Modal
      onBackdropClick={props.onRequestClose}
      visible={props.visible}
    >
      <Card gap={20} padding={40} width={400}>
        <CardHeader
          title="Novo Webhook"
          onClose={props.onRequestClose}
        />
        <Select
          label="Selecione um tipo"
          onSelect={(value) => setType(value)}
          options={TypesWebhook}
        />
        <Input
          placeholder="URL http://"
          onChange={({ target }) => setUrl(target.value)}
        />
        <Input
          placeholder="Secret"
          helperText="Utilizado para assinatura do webhook. Caso nao informada o sistema irá gerar uma senha provisória."
          onChange={({ target }) => setSecret(target.value)}
        />
        <Row center>
          <Button
            disabled={!(url && type)}
            label="Confirmar"
            loading={props.loading}
            onClick={() => props.onConfirm?.({
              secret,
              type: type!,
              url: url!
            })}
            width={"10rem"}
          />
        </Row>
      </Card>
    </Modal>
  )
}

export default AddWeebhook;
import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components"
import Modal from "./Modal"
import Flex from "components/layout/Flex"
import { attributeCSS } from "helpers/text";

interface Props {
  children?: ReactNode,
  onRequestClose: Function,
  visible: boolean,
  width?: number
}

const SideBar = ({
  children,
  onRequestClose,
  visible,
  width
}: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (visible) {
      setShow(true);
    }
  }, [visible])

  useEffect(() => {
    if (!show) {
      setTimeout(() => onRequestClose(), 600);
    }
  }, [show])

  return (
    <Modal
      onBackdropClick={() => setShow(false)}
      visible={visible}
    >
      <Container visible={show} bgColor="#fff" width={width}>
        {children}
      </Container>
    </Modal>
  )
}

const Container = styled(Flex) <{ visible: boolean }>`
  height: 100%;
  ${(props) => `
    ${attributeCSS('width', props.width ?? 250)};
    ${attributeCSS('right', props.visible ? 0 : -(props.width ?? 250))}
    right: ${props.visible ? '0px' : '-250px'};
  `}
  transition: all .5s ease-out;
  transform: ${({ visible }) => (visible ? 'translateX(0)' : 'translateX(100%)')};
  position: fixed;
  top: 0;
  right: 0;
  
`

export default SideBar;
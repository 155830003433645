import "react-notifications-component/dist/theme.css";
import * as Castle from "@castleio/castle-js";
import {
  useEffect,
  useState
} from "react";
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import {
  RouteItem,
  RoutesObject,
  filterRoutes,
  isRouteDisabled
} from "helpers/route";
import { ReactNotifications } from "react-notifications-component";
import { Provider } from "./components";
import { RouteType, routes } from "routes";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import Navigation from "./navigation";
import banking from "api";

function App() {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>('/');
  const [modalPath, setModalPath] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [routesObject, setRoutesObject] = useState<RoutesObject>();

  const { user } = useSelector((state: StoreState) => state.account);

  useEffect(() => {
    initialize();
  }, []);

  async function initialize() {
    Castle.configure({ pk: process.env.REACT_APP_CASTLE_SECRET })
      .createRequestToken()
      .then((token) => {
        banking.token = token;
      });

    setRoutesObject(filterRoutes(routes));
  }

  useEffect(() => {
    handlePath(location.pathname)
  }, [location]);

  function handlePath(pathname: string) {
    const route = routes.find(({ path }) => path === pathname);

    document.title = route?.title || route?.label || 'MK Bank';

    if (route?.modal_path) {
      return setModalPath(pathname);
    }
    setModalPath(undefined);
    setCurrentPath(pathname);
  }

  function renderRoute(route: RouteItem) {
    if (isRouteDisabled(route, user)) {
      return null;
    }

    return (
      <Route
        key={route.id}
        element={<route.component />}
        path={route.path}
      >
        {route?.children?.map((sub_route: RouteType, i: number) => {
          if (isRouteDisabled(route, user)) {
            return null;
          }

          return (
            <Route
              key={sub_route.id}
              element={<sub_route.component />}
              path={sub_route.path}
            />
          )
        }) || null}
      </Route>
    )
  }

  return (
    <Provider>
      <ReactNotifications />
      {!!routesObject ?
        <>
          <Routes location={currentPath}>
            {routesObject.auth.map((route) => renderRoute(route))}

            <Route
              element={
                <Navigation
                  pathname={modalPath || currentPath}
                  setParentLoading={setLoading}
                />
              }
            >
              {routesObject.navigation.map((route) => renderRoute(route))}
            </Route>
          </Routes>

          {(!loading && modalPath) &&
            <Routes location={modalPath}>
              {routesObject.modals.map((route) => renderRoute(route))}
            </Routes>
          }
        </>
        : null
      }
    </Provider>
  );
}

export default App;


import { Flex, Row, Text } from "components";
import { format, parse, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { color } from "theme";
import styled from "styled-components";

const DateSelection: React.FC<{
  data: string[];
  index: number;
  onSelect: (index: number) => void;
}> = (props) => {
  return (
    <Flex>
      <Text marginBottom={10}>Últimos 7 dias</Text>
      <Row flex gap={10}>
        {props.data.map((item, i) => {
          const _d = item.split('-');
          const _date = `${_d[1]}-${_d[2]}-${_d[0]}`

          return (
            <DateButton onClick={() => props.onSelect(i)} active={i === props.index} key={i} flex center border borderRadius>
              <Text align="center">
                {format(parseISO(item), "dd", { locale: ptBR })}
              </Text>
              <Text align="center">
                {format(parseISO(item), "MMM", { locale: ptBR })}
              </Text>
            </DateButton>
          )
        })}
      </Row>
    </Flex>
  )
}

const DateButton = styled(Flex) <{ active?: boolean }>`
  cursor: pointer;
  height: 60px;
  min-width: 40px;
  max-width: 60px;
  ${(props) => props.active ? `
    background-color: ${color.primary};
    color: ${color.text_primary_contrast};
  ` : `
    &:hover {
      background-color: ${color.primary + 25};
      color: ${color.text_primary};
    }
  `}
`;

export default DateSelection;
import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { fontSize } from "theme";

const StyledTextarea = styled.textarea<{ isError: boolean; height: number }>`
  width: 100%;
  height: ${({ height }) => `${height}em`};
  min-height: 6em;
  border: ${({ isError }) => (isError ? "1px solid red" : "1px solid transparent")};
  border-radius: 4px;
  resize: vertical;
  font-size: ${fontSize.body};
  background-color: #f8f8f8;
  box-sizing: border-box;
  padding: 10px;
  &:focus {
    outline: none;
  }
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 5px;
`;

interface TextareaProps {
  value: string;
  onValueChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  isValid?: boolean;
  errorMessage?: string;
  limit?: number;
  activateCounter?: boolean;
  height?: number;
}

const Textarea: React.FC<TextareaProps> = ({
  value = "",
  onValueChange,
  placeholder,
  disabled = false,
  autoFocus = false,
  isValid = true,
  errorMessage,
  limit,
  height = 6,
  activateCounter = false,
}) => {
  const [remainingChars, setRemainingChars] = useState(limit ? limit - value.length : -1);

  const handleValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    if (limit && value && value.length > limit) {
      onValueChange(value.substr(0, limit)); // Truncate the value to the limit
      setRemainingChars(0);
    } else {
      onValueChange(value);
      if (limit && activateCounter) {
        setRemainingChars(limit - value.length);
      }
    }
  };

  return (
    <div>
      <StyledTextarea
        value={value}
        onChange={handleValueChange}
        placeholder={placeholder}
        // disabled={disabled || (limit && value.length >= limit && activateCounter ? true : false)}
        autoFocus={autoFocus}
        isError={!isValid}
        height={height}
      />
      {limit && activateCounter && (
        <p>
          Characters remaining:{" "}
          {remainingChars >= 0 ? remainingChars : <strong>Limit reached</strong>}
        </p>
      )}
      {!isValid && errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </div>
  );
};

export default Textarea;

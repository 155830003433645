import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Input from "./Input";
import { FiSearch } from "react-icons/fi";
import { color, fontSize } from "theme";
import Flex from "./layout/Flex";

interface Option {
  [key: string]: any;
}

interface StyledSelectProps {
  isOpen: boolean;
}

export interface SelectProps {
  options: Option[];
  label?: string;
  selectLabel?: string;
  selectName?: string;
  selectedValue?: string | number;
  valueKey?: string;
  labelKey: string;
  onSelect: (option: Option) => void;
  showSearch?: boolean;
  maxHeight?: number;
  required?: boolean;
}

const StyledSelect = styled.div<StyledSelectProps>`
  color: ${color.primary};
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  background-color: rgb(248, 248, 248);
  transition: border-color 0.3s ease 0s;
  position: relative;
  height: 30px;
  margin-bottom: 10px;
  padding: 20px;

  select {
    padding: 12px;
    color: ${color.text_primary};
    background: #f8f8f8;
    font-size: 20px;
    min-height: 47px;
    width: 100%;
    border: none;
  }
`;

const InputContainer = styled.div`
  position: relative;
  margin-top: 10px;
  background-color: transparent;
`;

const SearchIcon = styled(FiSearch)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #46893d;
  pointer-events: none;
  font-size: 20px;
  color: ${color.primary};
`;

const InputStyled = styled(Input)`
  margin-left: 30px;
`;

const OptionContainer = styled.div<{ maxHeight?: number }>`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}px` : "initial")};
  overflow-y: auto;
  background-color: #e6e6e6;
`;

const OptionItemSelected = styled.div`
  max-width: fit-content;
  background-color: transparent;
  color: ${color.primary};
  border-radius: 4px;
  margin-top: 5px;
  cursor: pointer;
`;
const OptionItem = styled.div`
  max-width: inherit;
  background-color: transparent;
  color: ${color.primary};
  border-radius: 4px;
  padding: 20px;
  margin-top: 5px;
  border-bottom: 1px solid white;
  cursor: pointer;
  &:hover {
    background-color: #f8f8f8;
  }
`;

const Label = styled.label<{ textColor: string }>`
  color: ${(props) => props.textColor};
  padding: 5px 8px;
  display: flex;
  font-size: ${fontSize.caption};
`;

const Text = styled.span<{ textColor: string }>`
  padding: 0 5px;
  color: ${(props) => props.textColor};
`;

const Select: React.FC<SelectProps> = ({
  options,
  label,
  selectLabel,
  selectedValue,
  selectName,
  valueKey,
  labelKey,
  onSelect,
  showSearch = true,
  maxHeight = 200,
  required = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const option =
      selectedValue && options.find((opt) => opt[valueKey as string] === selectedValue);
    // setSelectedOption(option);
    console.log("OPTION => ", option);

    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [options, selectedValue]);

  const toggleSelect = () => {
    setIsOpen(!isOpen);
    setSearchValue("");
  };

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    onSelect(option);
    toggleSelect();
  };

  const filteredOptions =
    options &&
    options.filter((option) =>
      option[labelKey]
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(searchValue.toLowerCase())
    );

  return (
    <Flex style={{ width: "100%" }}>
      {selectLabel && (
        <Label textColor={color.text_secondary}>
          {selectLabel} {required && <Text textColor={color.error}>*</Text>}
        </Label>
      )}
      <StyledSelect ref={selectRef} isOpen={isOpen} onClick={toggleSelect}>
        {selectedOption ? (
          selectedOption[labelKey]
        ) : (
          <OptionItemSelected id="0">{label}</OptionItemSelected>
        )}
        {isOpen && (
          <OptionContainer maxHeight={maxHeight}>
            {showSearch && (
              <InputContainer>
                <SearchIcon />
                <InputStyled
                  type="text"
                  placeholder="Pesquisar..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
              </InputContainer>
            )}
            <Flex>
              {filteredOptions.map((option, idx) => (
                <OptionItem key={idx} onClick={() => handleOptionClick(option)}>
                  {option[labelKey]}
                </OptionItem>
              ))}
            </Flex>
          </OptionContainer>
        )}
      </StyledSelect>
    </Flex>
  );
};

export default Select;

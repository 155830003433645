import React, { useState } from "react";
import {
  Button,
  Card,
  Row
} from "components";
import { useNavigate } from "react-router-dom";
import { useAlert, useCode } from "hooks";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import RadioOption from "components/RadioOption";
import banking from "api";
import showError from "helpers/showError";

const PixAddKeyPage: React.FC = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const modalCode = useCode();
  const { user } = useSelector((state: StoreState) => state.account);

  const [loading, setLoading] = useState(false);

  function request2FA() {
    modalCode({
      onConfirm: (verification_code) => handleConfirm(verification_code),
      operation: 'PIX_KEY_REGISTRATION',
      type: user?.preferred_2fa_channel || undefined
    })
  }

  async function handleConfirm(code: string) {
    setLoading(true);
    try {
      await banking.pix.addKey({
        type: 'EVP',
        code,
        verification_code: code
      });
      alert({
        title: "Cadastro de chave",
        message: "A chave foi cadastrada com sucesso!",
      });
      navigate(-1);
    } catch (error) {
      showError(alert, error)
    }
    setLoading(false);
  }

  return (
    <Card
      
      title="Tipo de chave"
      actionLoading={loading}

    >
      <RadioOption
        name={"EVP"}
        checked={true}
        label="Chave aleatória"
      />

      <Row >
        <Button
          label="Criar chave"
          onClick={() => request2FA()}
          width="10rem"
        />
      </Row>
    </Card>
  );
};

export default PixAddKeyPage;

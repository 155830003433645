import { ReportResponse, ReportOperationType } from "types/report";
import BaseModule from "./BaseModule";

class Reports extends BaseModule {
  requestReport(
    type: 'ACCOUNT_STATEMENT' | 'ACCOUNT_USAGE',
    params: {
      from: string;
      until: string;
      operation?: ReportOperationType;
    },
  ): Promise<ReportResponse> {
    return this.post(`/ib/reports`, {
      type,
      params
    });
  }

  requestReportCSV(id: number, account_number: number | string) {
    return this.get(`/ib/reports/${id}/download?selected_account_number=${account_number}`, {
      headers: {
        Accept: 'application/csv',
      },
      responseType: 'blob',
    });
  }
  requestUrlReportCSV(id: number, account_number: number | string) {
    return process.env.REACT_APP_BASE_URL + `/ib/reports/${id}/download?selected_account_number=${account_number}`
  }

  listReports(type: 'ACCOUNT_STATEMENT' | 'ACCOUNT_USAGE') {
    return this.get("/ib/reports", { params: { type } });
  }

  downloadReport(id: number) {
    return this.get(`/ib/reports/${id}/download`);
  }

  downloadReportHtml(id: number) {
    return this.get(`/ib/reports/${id}/html`);
  }
}

export default Reports;

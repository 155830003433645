import { RequestWithToken } from "types/request";
import BaseModule from "./BaseModule";
import { CredentialCreateType, CredentialResponseType } from "types/credential";

class Credentials extends BaseModule {
  list(): Promise<CredentialResponseType[]> {
    return this.get("/ib/api_credentials");
  }

  create(data: RequestWithToken<CredentialCreateType>) {
    return this.post("/ib/api_credentials", data);
  }

  update(id: number, data: CredentialCreateType) {
    return this.put(`/ib/api_credentials/${id}`, data);
  }

  revoke(id: number) {
    return this.post(`/ib/api_credentials/${id}/revoke`);
  }
}

export default Credentials;

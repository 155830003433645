import React from "react";
import {
  Text,
  Button
} from "components";

import { logoMkPay } from "assets";

const Configure: React.FC<any> = ({
  onClick,
}) => {

  return (
    <div>
      <img id="mkpay_logo" src={logoMkPay} alt="logo" width={130} />
      <Text size={'subtitle'} padding="20px 0px 20px 0px" weight={600}>A revolução no recebimento via PIX para o seu negócio! Simplifique as transações financeiras em seu estabelecimento com nosso inovador e fácil de usar</Text>
      <Text size={'body'} color="#6A6D71">
        Com o MK Pay, você oferece aos seus clientes a conveniência de pagar com PIX de forma rápida e segura, tornando as compras mais simples do que nunca. Descubra um novo jeito de receber e gerenciar seus pagamentos com o MK Pay.
      </Text>
      <Button
        variant="outline"
        margin={'35px 0px 0px 0px'}
        label="Configurar dispositivo"
        size="lg"
        onClick={onClick}
        width={200}
      />
    </div>
  )
}

export default Configure;
import styled from "styled-components";
import { fontSize } from "../theme";
import { attributes, BaseProps } from "./layout/base";

export interface TextProps extends BaseProps, React.HTMLAttributes<HTMLParagraphElement> {
  align?: 'center' | 'left' | 'right',
  size?: 'headline' | 'headline_2' | 'headline_3' | 'body' | 'subtitle' | 'caption' | 'footnote' | number,
  css?: string,
  weight?: string | number,
}

const Text = styled.p<TextProps>`
  ${props => `
    margin: 0;
    color: ${props.color ?? 'currentcolor'};
    font-size: ${typeof props.size === 'number' ? props.size + 'px' : fontSize[props.size as keyof typeof fontSize ?? 'body']};
    font-weight: ${props.weight ?? 400};
    ${props.align ? `text-align: ${props.align};` : ''}
    ${attributes(props)}
  `}
`;

export default Text;
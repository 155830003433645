export const color = {
  background: "#FBFBFB",
  surface: "#FFFFFF",
  primary: "#263F63",
  secondary: "#5375FA",
  brand: "#E0994B",
  border: "#E8E8E8",
  error: "#F34D4D",
  error_secondary: "#C70202",
  error_terciary: "#FF7676",
  info: "#5375FA",
  info_secondary: "#97ABED",
  info_terciary: "#97ABED",
  shadow: '#f6f6f6',
  success: "#16C872",
  success_secondary: "#5DE2A3",
  success_terciary: "#5DE2A3",
  text_primary: "#202122",
  text_primary_contrast: "#FFFFFF",
  text_secondary: "#6A6D71",
  warning: "#FFAE34",
  white: "#ffffff"
}

export const fontSize = {
  /**
   * 22px
   */
  headline: "22px",
  /**
   * 28px
   */
  headline_2: "28px",
  /**
   * 34px
   */
  headline_3: "34px",
  /**
   * 18px
   */
  subtitle: "18px",
  /**
   * 16px
   */
  body: "16px",
  /**
   * 14px
   */
  caption: "14px",
  /**
   * 12px
   */
  footnote: "12px",
}

export const size = {
  /**
   * 5px
   */
  border_radius: "5px",
  /**
   * 5
   */
  border_radius_number: 5,
  /**
   * 50
   */
  rounded: 50,
  /**
   * 12px
   */
  xxs: '12px',
  /**
   * 12
   */
  xxs_number: 12,
  /**
   * 16px
   */
  xs: '16px',
  /**
   * 16
   */
  xs_number: 16,
  /**
   * 26px
   */
  sm: '26px',
  /**
   * 26
   */
  sm_number: 26,
  /**
   * 40px
   */
  md: '40px',
  /**
   * 40
   */
  md_number: 40,
  /**
   * 48px
   */
  lg: '48px',
  /**
   * 48
   */
  lg_number: 48,
  /**
   * 320px
   */
  mobile: "320px",
  /**
   * 768px
   */
  tablet: "768px",
  /**
   * 1024px
   */
  laptop: "1024px", //1440px
  /**
   * 2560px
   */
  desktop: "2560px",
}